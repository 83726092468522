import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
    useColorModeValue,
    Text,
    Flex,
    Button,
    Icon,
    Center,
    Textarea,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Input,
    Wrap,
    Image,
    Divider,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import { IoTrashSharp, IoPencil, IoAddCircle } from "react-icons/io5";
import { CloseIcon } from '@chakra-ui/icons';
import AcompanhamentosSangriasDiarias from "views/admin/default/components/AcompanhamentosSangriasDiarias";
import AcompanhamentoProducao from "views/admin/default/components/AcompanhamentoProducao";
import QualidadeSangriaPersonalizado from "views/admin/relatorioPersonalizadoAndEsporadico/personalizado/QualidadeSangriaPersonalizado";
import * as ActionTypes from '../../../../constants/ActionTypes';

const arrAn = [
    { idRelatorioPersonalizado: 1, tipoAssunto: '1', idAssunto: 1, nomeAssunto: 'Controle de Insetos, Pulgas e Carrapatos', sugestoesTecnicas: 'Sugestão Técnica 01 Sugestão Técnica 01 Sugestão Técnica 01 Sugestão Técnica 01 Sugestão Técnica 01 Sugestão Técnica 01', local: 'Talhão 01', prazo: 'Anual', responsavel: 'Renato Santos 01' },
    { idRelatorioPersonalizado: 2, tipoAssunto: '1', idAssunto: 2, nomeAssunto: 'Desinfecção de Ambientes', sugestoesTecnicas: 'Sugestão Técnica 02', local: 'Talhão 02', prazo: 'Mensal', responsavel: 'Renato Santos 02' },
    { idRelatorioPersonalizado: 3, tipoAssunto: '2', idAssunto: null, nomeAssunto: 'Desratizaçao 33', sugestoesTecnicas: 'Sugestão Técnica 03', local: 'Talhão 03', prazo: 'Semanal', responsavel: 'Renato Santos 03' },
]

const jsonPers = {
    opcaoPendenciasAcoesDeCampo: true,
    opcaoObservacoesDiversasComentario: true,
    opcaoSangrias: true,
    opcaoQualidade: true,
    opcaoProducao: true,
}

const comentarioSangriaX = 'Sangria, desafiador cenário globalizado ainda não demonstrou convincentemente que vai participar na mudança das diretrizes de desenvolvimento para o futuro.';
const comentarioQualidadeX = 'Qualidade, desafiador cenário globalizado ainda não demonstrou convincentemente que vai participar na mudança das diretrizes de desenvolvimento para o futuro.';
const comentarioProducaoX = 'Producao, desafiador cenário globalizado ainda não demonstrou convincentemente que vai participar na mudança das diretrizes de desenvolvimento para o futuro.';

export default function RelatorioPersonalizado() {

    const history = useHistory();
    const [arrayAnunssios, setArrayAnunssios] = useState([]);
    const [comentarioUm, setComentarioUm] = useState('');

    const [openAssunto, setOpenAssunto] = useState(false);
    const [openObservacoesDiversas, setOpenObservacoesDiversas] = useState(false);
    const [isExpandedAssunto, setIsExpandedAssunto] = useState(false);
    const [isExpandedObservacoesDiversas, setIsExpandedObservacoesDiversas] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandedQuarlidadeSangriaPersonalizado, setIsExpandedQuarlidadeSangriaPersonalizado] = useState(false);
    const [isExpandedProd, setIsExpandedProd] = useState(false);

    const [openQuarlidadeSangriaPersonalizado, setOpenQuarlidadeSangriaPersonalizado] = useState(false);
    const [openSangria, setOpenSangria] = useState(false);
    const [openProducao, setOpenProducao] = useState(false);
    const [objPermissoes, setObjPermissoes] = useState({});
    const [arrayFilesProducao, setArrayFilesProducao] = useState([]);
    const [messageFileProducao, setMessageFileProducao] = useState('');
    const [isCheckProducao, setIsCheckProducao] = useState(false);
    const [comentarioProducao, setComentarioProducao] = useState('');

    const [arrayFilesSangria, setArrayFilesSangria] = useState([]);
    const [messageFileSangria, setMessageFileSangria] = useState('');
    const [isCheckSangria, setIsCheckSangria] = useState(false);
    const [comentarioSangria, setComentarioSangria] = useState('');

    const [arrayFilesQualidade, setArrayFilesQualidade] = useState([]);
    const [messageFileQualidade, setMessageFileQualidade] = useState('');
    const [isCheckQualidade, setIsCheckQualidade] = useState(false);
    const [comentarioQualidade, setComentarioQualidade] = useState('');
    const [objOpcoesPersonalizado, setObjOpcoesPersonalizado] = useState(jsonPers);

    const [isValidPendenciasAcoesDeCampo, setIsValidPendenciasAcoesDeCampo] = useState(false);
    const [isValidObservacoesDiversasComentario, setIsValidObservacoesDiversasComentario] = useState(false);
    const [isValidSangrias, setIsValidSangrias] = useState(false);
    const [isValidQualidade, setIsValidQualidade] = useState(false);
    const [isValidProducao, setIsValidProducao] = useState(false);
    const [isShowCamposObrigatorios, setShowCamposObrigatorios] = useState(false);

    const boxBg = useColorModeValue("white", "whiteAlpha.100");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorInfo = useColorModeValue("gray.400", "white");
    const sizeButtonCard = '25px';
    const gradientColorBlack = "linear-gradient(0.07deg, gray.400 -10.41%, gray.100 28.04%, gray.100 71.85%, gray.200 90.49%, gray.400 112.49%)";

    useEffect(() => {
        async function loaderScreen() {
            try {
                const objPer = localStorage.getItem('@HE-opcoes-relatorio-personalizado');
                const objOpcoesPersonalizadoP = JSON.parse(objPer);
                setObjOpcoesPersonalizado(objOpcoesPersonalizadoP);

                const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
                const obj = JSON.parse(val);
                setObjPermissoes(obj);

                if (!ActionTypes.PRODUCAO) {
                    localStorage.setItem('@HE-array-assunto', JSON.stringify(arrAn));                    
                    localStorage.setItem('@HE-comentario-tela-sangria', comentarioSangriaX);
                    localStorage.setItem('@HE-comentario-tela-qualidade', comentarioQualidadeX);
                    localStorage.setItem('@HE-comentario-tela-producao', comentarioProducaoX);
                    setComentarioUm('Comentário 1 - tela Observações diversas e Comentários');
                }
                const arr = localStorage.getItem('@HE-array-assunto');
                if (arr != null) {
                    const xx = JSON.parse(arr);
                    xx.sort(function (a, b) { return a.idAssunto - b.idAssunto });
                    setArrayAnunssios(xx);
                }
                const com = localStorage.getItem('@HE-comentario-tela-observacoes');
                if (com != null) {
                    setComentarioUm(com);
                }

                const arraySang = localStorage.getItem('@HE-array-imagens-sangria-personalizado');
                const zzS = arraySang != null ? JSON.parse(arraySang) : [];
                setArrayFilesSangria(zzS);

                const arrayQuali = localStorage.getItem('@HE-array-imagens-qualidade-personalizado');
                const zzQ = arrayQuali != null ? JSON.parse(arrayQuali) : [];
                setArrayFilesQualidade(zzQ);

                const arrayProd = localStorage.getItem('@HE-array-imagens-producao-personalizado');
                const zzk = arrayProd != null ? JSON.parse(arrayProd) : [];
                setArrayFilesProducao(zzk);

                const comSa = localStorage.getItem('@HE-comentario-tela-sangria');
                const comentarioSangriaP = comSa != null && comSa != '' ? comSa : '';
                setComentarioSangria(comentarioSangriaP);

                const comQuali = localStorage.getItem('@HE-comentario-tela-qualidade');
                const comentarioQualidadeP = comQuali != null && comQuali != '' ? comQuali : '';
                setComentarioQualidade(comentarioQualidadeP);

                const comPro = localStorage.getItem('@HE-comentario-tela-producao');
                const comentarioProducaoP = comPro != null && comPro != '' ? comPro : '';
                setComentarioProducao(comentarioProducaoP);

            } catch (error) {
                console.log('Erro na classe RelatorioPersonalizado metodo useEffect', error);
            }
        }
        loaderScreen();
    }, [])

    const adicionarAssunto = () => {
        const json = {
            idRelatorioPersonalizado: 0,
            idAssunto: 0,
            sugestoesTecnicas: '',
            nomeAssunto: '',
            local: '',
            prazo: '',
            responsavel: '',
            tipoAssunto: ''
        }
        localStorage.setItem('@HE-dados-tela-assunto', JSON.stringify(json));
        history.push("/admin/assunto");
    }

    const deletarAssunto = async (item) => {
        const fil = arrayAnunssios.filter(x => x.idAssunto != item.idAssunto);
        setArrayAnunssios(fil);
        if (fil.length > 0) {
            localStorage.setItem('@HE-array-assunto', JSON.stringify(fil));
        } else {
            localStorage.removeItem('@HE-array-assunto');
        }
    }

    const editarAssunto = (item) => {
        localStorage.setItem('@HE-dados-tela-assunto', JSON.stringify(item));
        history.push("/admin/assunto");
    }

    const handleExpandAssunto = () => {
        setOpenAssunto(true);
        setIsExpandedAssunto(true);
    };
    const handleCollapseAssunto = () => {
        setOpenAssunto(false);
        setIsExpandedAssunto(false);
    };
    const handleExpandObservacoesDiversas = () => {
        setOpenObservacoesDiversas(true);
        setIsExpandedObservacoesDiversas(true);
    };
    const handleCollapseObservacoesDiversas = () => {
        setOpenObservacoesDiversas(false);
        setIsExpandedObservacoesDiversas(false);
    };
    const handleExpandQuarlidadeSangriaPersonalizado = () => {
        setOpenQuarlidadeSangriaPersonalizado(true);
        setIsExpandedQuarlidadeSangriaPersonalizado(true);
    };
    const handleCollapseQuarlidadeSangriaPersonalizado = () => {
        setOpenQuarlidadeSangriaPersonalizado(false);
        setIsExpandedQuarlidadeSangriaPersonalizado(false);
    };
    const handleExpand = () => {
        setOpenSangria(true);
        setIsExpanded(true);
    };
    const handleCollapse = () => {
        setOpenSangria(false);
        setIsExpanded(false);
    };
    const handleExpandProd = () => {
        setOpenProducao(true);
        setIsExpandedProd(true);
    };
    const handleCollapseProd = () => {
        setOpenProducao(false);
        setIsExpandedProd(false);
    };

    const gerarRelatorio = () => {
        setShowCamposObrigatorios(false);
        let valid = false;

        if (comentarioUm != '') {
            localStorage.setItem('@HE-comentario-tela-observacoes', comentarioUm);
        }
        if (comentarioSangria != '') {
            localStorage.setItem('@HE-comentario-tela-sangria', comentarioSangria);
        }
        if (comentarioQualidade != '') {
            localStorage.setItem('@HE-comentario-tela-qualidade', comentarioQualidade);
        }
        if (comentarioProducao != '') {
            localStorage.setItem('@HE-comentario-tela-producao', comentarioProducao);
        }

        if (objOpcoesPersonalizado.opcaoPendenciasAcoesDeCampo) {
            const arr = localStorage.getItem('@HE-array-assunto');
            const is = arr != null ? false : true;
            setIsValidPendenciasAcoesDeCampo(is);
            if(is) valid = true;
        }
        if (objOpcoesPersonalizado.opcaoObservacoesDiversasComentario) {
            const arr = localStorage.getItem('@HE-comentario-tela-observacoes');
            const is = arr != null ? false : true;
            setIsValidObservacoesDiversasComentario(is);
            if(is) valid = true;
        }
        if (objOpcoesPersonalizado.opcaoSangrias) {
            const arr = localStorage.getItem('HE@-sangriasDiarias-personalizado');
            const is = arr != null ? false : true;
            setIsValidSangrias(is);
            if(is) valid = true;
        }
        if (objOpcoesPersonalizado.opcaoQualidade) {
            const arr = localStorage.getItem('@HE-qualidadeSangria-personalizado');
            const is = arr != null ? false : true;
            setIsValidQualidade(is);
            if(is) valid = true;
        }
        if (objOpcoesPersonalizado.opcaoProducao) {
            const arr = localStorage.getItem('HE@-dadosRelatorioAcompanhamentoProducao');
            const is = arr != null ? false : true;
            setIsValidProducao(is);
            if(is) valid = true;
        }

        if (valid) {
            setShowCamposObrigatorios(true);
            window.scrollTo(0, 0);
            return;
        }else{
            history.push("/admin/gerar-relatorio-personalizado");
        }
    }

    // PRODUCAO
    const anexarImageProducao = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const nomeFile = file.name;
            const verifyExist = arrayFilesProducao.filter(x => x.name == file.name);     // CHECK IF THEN FILE EXISTS
            if (verifyExist.length > 0) {
                setMessageFileProducao('O arquivo já foi adicionado');
                setIsCheckProducao(true);
                return;
            }
            const readerProduca = new FileReader();
            readerProduca.onloadend = () => {
                const base64String = readerProduca.result;
                const objFile = {
                    nomeFile: nomeFile,
                    fileBase64: base64String
                }
                if (arrayFilesProducao.length > 0) {
                    const newArray = [objFile];
                    const arr = [...arrayFilesProducao, ...newArray];
                    setArrayFilesProducao(arr);
                    localStorage.setItem('@HE-array-imagens-producao-personalizado', JSON.stringify(arr));
                } else {
                    const newArray = [objFile];
                    setArrayFilesProducao(newArray);
                    localStorage.setItem('@HE-array-imagens-producao-personalizado', JSON.stringify(newArray));
                }
            };
            readerProduca.readAsDataURL(file);
        }
    };
    const deleteImagemProducao = (item) => {
        const array = arrayFilesProducao.filter(x => x.name != item.name);
        setArrayFilesProducao(array);
        localStorage.setItem('@HE-array-imagens-producao-personalizado', JSON.stringify(array));
    }

    // QUALIDADE
    const anexarImageQualidade = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const nomeFile = file.name;
            const verifyExist = arrayFilesQualidade.filter(x => x.name == file.name);     // CHECK IF THEN FILE EXISTS
            if (verifyExist.length > 0) {
                setMessageFileQualidade('O arquivo já foi adicionado');
                setIsCheckQualidade(true);
                return;
            }
            const readerQualidade = new FileReader();
            readerQualidade.onloadend = () => {
                const base64String = readerQualidade.result;
                const objFile = {
                    nomeFile: nomeFile,
                    fileBase64: base64String
                }
                if (arrayFilesQualidade.length > 0) {
                    const newArray = [objFile];
                    const arr = [...arrayFilesQualidade, ...newArray];
                    setArrayFilesQualidade(arr);
                    localStorage.setItem('@HE-array-imagens-qualidade-personalizado', JSON.stringify(arr));
                } else {
                    const newArray = [objFile];
                    setArrayFilesQualidade(newArray);
                    localStorage.setItem('@HE-array-imagens-qualidade-personalizado', JSON.stringify(newArray));
                }
            };
            readerQualidade.readAsDataURL(file);
        }
    };
    const deleteImagemQualidade = (item) => {
        const array = arrayFilesQualidade.filter(x => x.name != item.name);
        setArrayFilesQualidade(array);
        localStorage.setItem('@HE-array-imagens-qualidade-personalizado', JSON.stringify(array));
    }

    // SANGRIA
    const anexarImageSangria = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const nomeFile = file.name;
            const verifyExist = arrayFilesSangria.filter(x => x.name == file.name);     // CHECK IF THEN FILE EXISTS
            if (verifyExist.length > 0) {
                setMessageFileSangria('O arquivo já foi adicionado');
                setIsCheckSangria(true);
                return;
            }
            const readerSangria = new FileReader();
            readerSangria.onloadend = () => {
                const base64String = readerSangria.result;
                const objFile = {
                    nomeFile: nomeFile,
                    fileBase64: base64String
                }
                if (arrayFilesSangria.length > 0) {
                    const newArray = [objFile];
                    const arr = [...arrayFilesSangria, ...newArray];
                    setArrayFilesSangria(arr);
                    localStorage.setItem('@HE-array-imagens-sangria-personalizado', JSON.stringify(arr));
                } else {
                    const newArray = [objFile];
                    setArrayFilesSangria(newArray);
                    localStorage.setItem('@HE-array-imagens-sangria-personalizado', JSON.stringify(newArray));
                }
            };
            readerSangria.readAsDataURL(file);
        }
    };
    const deleteImagemSangria = (item) => {
        const array = arrayFilesSangria.filter(x => x.name != item.name);
        setArrayFilesSangria(array);
        localStorage.setItem('@HE-array-imagens-sangria-personalizado', JSON.stringify(array));
    }

    function renderCardAssunto() {
        const sizeTitle = '15px';
        return (
            <Card align='center' direction='column' w='100%'>
                <Flex px={'5'} mt={'10px'} align={'center'} justify={'space-between'}>
                    <Text color={textColor} fontSize='18px' fontWeight='700' lineHeight='100%'>Assunto</Text>
                    <Button h="35px" w={'120px'} bg="transparent" borderWidth={'3px'} borderColor={'blackAlpha.600'} onClick={adicionarAssunto}>
                        <Flex justify={'center'} alignItems={'center'} px={'10px'} py={'5px'}>
                            <Text color={textColor} fontSize='14px' fontWeight={'bold'} mr={'5px'}>Adicionar</Text>
                            <Icon as={IoAddCircle} width={sizeButtonCard} height={sizeButtonCard} color='green.500' />
                        </Flex>
                    </Button>
                </Flex>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'}>
                    {arrayAnunssios.length > 0 ?
                        arrayAnunssios.map((item, index) => {
                            return (
                                <Card key={index} py='10px' align='center' justify={'start'}  bgGradient={gradientColorBlack}>
                                    <Box w={'100%'} py={'5'}>

                                        <Box w={'100%'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Assunto</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.nomeAssunto}</Text>
                                        </Box>
                                        <Divider w={'100%'} h={'1px'} bg={textColor} orientation='horizontal' mt={'1'}/>
                                        <Box w={'100%'} mt={'10px'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Sugestões Técnicas</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.sugestoesTecnicas}</Text>
                                        </Box>
                                        <Divider w={'100%'} h={'1px'} bg={textColor} orientation='horizontal' mt={'1'}/>
                                        <Box w={'100%'} mt={'10px'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Local</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.local}</Text>
                                        </Box>
                                        <Divider w={'100%'} h={'1px'} bg={textColor} orientation='horizontal' mt={'1'}/>
                                        <Box w={'100%'} mt={'10px'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Responsável</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.responsavel}</Text>
                                        </Box>
                                        <Divider w={'100%'} h={'1px'} bg={textColor} orientation='horizontal' mt={'1'}/>
                                        <Box w={'100%'} mt={'10px'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Prazo</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.prazo}</Text>
                                        </Box>
                                    </Box>
                                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                                        <Box w={'100%'}>
                                            <Flex justify={'space-between'}>
                                                <Button
                                                    rightIcon={<IoTrashSharp size={'15px'} />}
                                                    color={'white'}
                                                    bg={'blue.900'}
                                                    h={'30px'} 
                                                    w={'100px'}
                                                    onClick={() => { deletarAssunto(item) }}
                                                >
                                                    Excluir
                                                </Button>
                                                <Button
                                                    rightIcon={<IoPencil size={'15px'} />}
                                                    color={'white'}
                                                    bg={'blue.900'}
                                                    h={'30px'} 
                                                    w={'100px'}
                                                    onClick={() => { editarAssunto(item) }}
                                                >
                                                    Editar
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Card>
                            )
                        })
                        :
                        <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Adicione um novo assunto'}</Text>
                    }
                </SimpleGrid>
            </Card>
        )
    }

    function renderProducao() {
        return (
            <Box>
                {openProducao &&
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} >
                        <AcompanhamentoProducao />
                        <Box w={'100%'}>
                            <Text color={textColor} fontSize='18px' fontWeight={'bold'} align={'center'}>Comentário</Text>
                            <Box px={'20px'}>
                                <Center justifyContent={'center'}>
                                    <Textarea
                                        value={comentarioProducao}
                                        onChange={(e) => setComentarioProducao(e.target.value)}
                                        placeholder='Digite seu comentário'
                                        size='sm'
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                    />
                                </Center>
                            </Box>
                        </Box>
                        <Box w={'100%'} px={'20px'} my={'20px'}>
                            {isCheckProducao &&
                                <Text color={'red.500'} fontSize='14px' fontWeight='700' ml={'10px'} mb={'10px'} >{messageFileProducao}</Text>
                            }
                            <Flex px='0px' justify='space-between' mb='20px' align='center' >
                                <label htmlFor="fileUpP">
                                    <Flex justify={'center'} alignItems={'center'} px={'10px'} py={'5px'} cursor={'pointer'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.400'}>
                                        <Text color={textColor} fontSize='15px' fontWeight={'bold'} mr={'10px'}>Inserir imagem</Text>
                                        <Icon as={IoAddCircle} width={sizeButtonCard} height={sizeButtonCard} color='green.500' />
                                    </Flex>
                                </label>
                            </Flex>
                            <Input
                                id="fileUpP"
                                type="file"
                                onChange={anexarImageProducao}
                                display={'none'}
                                accept=",image/*"
                            />
                            <Box ml={'20px'} >
                                {arrayFilesProducao.length > 0 &&
                                    arrayFilesProducao.map((item, index) => {
                                        return (
                                            <Box key={index}>
                                                <Flex justify='start' mb={'3'} py={'1'} align='end' borderBottom={'1px'} borderColor={'blackAlpha.400'}>
                                                    <Image boxSize='40px' ml={'0'} objectFit='contain' src={item.fileBase64} alt={'alt'} />
                                                    <Text color={textColor} fontSize='15px' fontWeight='100' mx={'4'}>{item.nomeFile}</Text>
                                                    <Button
                                                        rightIcon={<CloseIcon fontSize={'10px'} fontWeight={'bold'} mr={'8px'} />}
                                                        bg={'orange.600'}
                                                        color={'white'}
                                                        size='xs'
                                                        w={'25px'}
                                                        onClick={() => { deleteImagemProducao(item) }}
                                                        variant='outline'>
                                                    </Button>
                                                </Flex>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </SimpleGrid>
                }
            </Box>
        )
    }

    function renderQualidadeSangriaPersonalizado() {
        return (
            <Box>
                {openQuarlidadeSangriaPersonalizado &&
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }}>
                        <QualidadeSangriaPersonalizado />
                        <Box w={'100%'}>
                            <Text color={textColor} fontSize='18px' fontWeight={'bold'} align={'center'}>Comentário</Text>
                            <Box px={'20px'}>
                                <Center justifyContent={'center'}>
                                    <Textarea
                                        value={comentarioQualidade}
                                        onChange={(e) => setComentarioQualidade(e.target.value)}
                                        placeholder='Digite seu comentário'
                                        size='sm'
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                    />
                                </Center>
                            </Box>
                        </Box>
                        <Box w={'100%'} px={'20px'} my={'20px'}>
                            {isCheckQualidade &&
                                <Text color={'red.500'} fontSize='14px' fontWeight='700' ml={'10px'} mb={'10px'} >{messageFileQualidade}</Text>
                            }
                            <Flex px='0px' justify='space-between' mb='20px' align='center' >
                                <label htmlFor="fileUpQ">
                                    <Flex justify={'center'} alignItems={'center'} px={'10px'} py={'5px'} cursor={'pointer'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.400'}>
                                        <Text color={textColor} fontSize='15px' fontWeight={'bold'} mr={'10px'}>Inserir imagem</Text>
                                        <Icon as={IoAddCircle} width={sizeButtonCard} height={sizeButtonCard} color='green.500' />
                                    </Flex>
                                </label>
                            </Flex>
                            <Input
                                id="fileUpQ"
                                type="file"
                                onChange={anexarImageQualidade}
                                display={'none'}
                                accept=",image/*"
                            />
                            <Box ml={'20px'} >
                                {arrayFilesQualidade.length > 0 &&
                                    arrayFilesQualidade.map((item, index) => {
                                        return (
                                            <Box key={index}>
                                                <Flex justify='start' mb={'3'} py={'1'} align='end' borderBottom={'1px'} borderColor={'blackAlpha.400'}>
                                                    <Image boxSize='40px' ml={'0'} objectFit='contain' src={item.fileBase64} alt={'alt'} />
                                                    <Text color={textColor} fontSize='15px' fontWeight='100' mx={'4'}>{item.nomeFile}</Text>
                                                    <Button
                                                        rightIcon={<CloseIcon fontSize={'10px'} fontWeight={'bold'} mr={'8px'} />}
                                                        bg={'orange.600'}
                                                        color={'white'}
                                                        size='xs'
                                                        w={'25px'}
                                                        onClick={() => { deleteImagemQualidade(item) }}
                                                        variant='outline'>
                                                    </Button>
                                                </Flex>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </SimpleGrid>
                }
            </Box>
        )
    }

    function renderSangria() {
        return (
            <Box>
                {openSangria &&
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} >
                        <AcompanhamentosSangriasDiarias />
                        <Box w={'100%'}>
                            <Text color={textColor} fontSize='18px' fontWeight={'bold'} align={'center'}>Comentário</Text>
                            <Box px={'20px'}>
                                <Center justifyContent={'center'}>
                                    <Textarea
                                        value={comentarioSangria}
                                        onChange={(e) => setComentarioSangria(e.target.value)}
                                        placeholder='Digite seu comentário'
                                        size='sm'
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                    />
                                </Center>
                            </Box>
                        </Box>
                        <Box w={'100%'} px={'20px'} my={'20px'}>
                            {isCheckSangria &&
                                <Text color={'red.500'} fontSize='14px' fontWeight='700' ml={'10px'} mb={'10px'} >{messageFileSangria}</Text>
                            }
                            <Flex px='0px' justify='space-between' mb='20px' align='center' >
                                <label htmlFor="fileUpS">
                                    <Flex justify={'center'} alignItems={'center'} px={'10px'} py={'5px'} cursor={'pointer'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.400'}>
                                        <Text color={textColor} fontSize='15px' fontWeight={'bold'} mr={'10px'}>Inserir imagem</Text>
                                        <Icon as={IoAddCircle} width={sizeButtonCard} height={sizeButtonCard} color='green.500' />
                                    </Flex>
                                </label>
                            </Flex>
                            <Input
                                id="fileUpS"
                                type="file"
                                onChange={anexarImageSangria}
                                display={'none'}
                                accept=",image/*"
                            />
                            <Box ml={'20px'} >
                                {arrayFilesSangria.length > 0 &&
                                    arrayFilesSangria.map((item, index) => {
                                        return (
                                            <Box key={index}>
                                                <Flex justify='start' mb={'3'} py={'1'} align='end' borderBottom={'1px'} borderColor={'blackAlpha.400'}>
                                                    <Image boxSize='40px' ml={'0'} objectFit='contain' src={item.fileBase64} alt={'alt'} />
                                                    <Text color={textColor} fontSize='15px' fontWeight='100' mx={'4'}>{item.nomeFile}</Text>
                                                    <Button
                                                        rightIcon={<CloseIcon fontSize={'10px'} fontWeight={'bold'} mr={'8px'} />}
                                                        bg={'orange.600'}
                                                        color={'white'}
                                                        size='xs'
                                                        w={'25px'}
                                                        onClick={() => { deleteImagemSangria(item) }}
                                                        variant='outline'>
                                                    </Button>
                                                </Flex>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </SimpleGrid>
                }
            </Box>
        )
    }

    function renderObservacoesDiversasComentarios() {
        return (
            <Card align='center' direction='column' w='100%'>
                <Box px={'20px'}>
                    <Center justifyContent={'center'}>
                        <Textarea
                            value={comentarioUm}
                            onChange={(e) => setComentarioUm(e.target.value)}
                            placeholder='Digite o assunto'
                            size='sm'
                            errorBorderColor='crimson'
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                        />
                    </Center>
                </Box>
            </Card>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>

            <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%' align={'center'} mb={'5px'} >Relatório Personalizado</Text>

            {isShowCamposObrigatorios &&
                <Box px={'5'}>
                    <Box px={'5'} py={'2'} bg={'orange.50'} border={'1px'} borderRadius={'10px'}>
                        <Text ml={'5px'} color={'red.500'} fontSize={'18px'} fontWeight={'bold'} align={'center'}>Verifique alguns campos obrigatórios</Text>
                        {isValidPendenciasAcoesDeCampo && <Text color={'red.500'} fontSize='15px' fontWeight={'bold'}>Assunto</Text>}
                        {isValidObservacoesDiversasComentario && <Text color={'red.500'} fontSize='15px' fontWeight={'bold'}>Comentário</Text>}
                        {isValidSangrias && <Text color={'red.500'} fontSize='15px' fontWeight={'bold'}>Sangria</Text>}
                        {isValidQualidade && <Text color={'red.500'} fontSize='15px' fontWeight={'bold'}>Qualidade</Text>}
                        {isValidProducao && <Text color={'red.500'} fontSize='15px' fontWeight={'bold'}>Produção</Text>}
                    </Box>
                </Box>
            }

            <Accordion allowMultiple mt={'15px'} borderColor={'transparent'}>

                {objOpcoesPersonalizado.opcaoPendenciasAcoesDeCampo &&
                    <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapseAssunto : handleExpandAssunto}  >
                                    <Box as="span" flex='1' textAlign='left'>
                                        <Text ml={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'}>1 - Pendências e Ações de Campo</Text>
                                    </Box>
                                    <AccordionIcon boxSize={'10'} />
                                </AccordionButton>
                                <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                    {renderCardAssunto()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                }
                {objOpcoesPersonalizado.opcaoObservacoesDiversasComentario &&
                    <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapseObservacoesDiversas : handleExpandObservacoesDiversas}  >
                                    <Box as="span" flex='1' textAlign='left'>
                                        <Text ml={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'}>2 - Observações diversas e Comentários</Text>
                                    </Box>
                                    <AccordionIcon boxSize={'10'} />
                                </AccordionButton>
                                <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                    {renderObservacoesDiversasComentarios()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                }
                {objOpcoesPersonalizado.opcaoSangrias &&
                    <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapse : handleExpand}  >
                                    <Box as="span" flex='1' textAlign='left'>
                                        <Text ml={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'}>3 - Sangrias</Text>
                                    </Box>
                                    <AccordionIcon boxSize={'10'} />
                                </AccordionButton>
                                <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                    {renderSangria()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                }
                {objOpcoesPersonalizado.opcaoQualidade &&
                    <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapseQuarlidadeSangriaPersonalizado : handleExpandQuarlidadeSangriaPersonalizado}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        <Text ml={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'}>4 - Qualidade Sangria</Text>
                                    </Box>
                                    <AccordionIcon boxSize={'10'} />
                                </AccordionButton>
                                <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                    {renderQualidadeSangriaPersonalizado()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                }
                {objOpcoesPersonalizado.opcaoProducao &&
                    <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapseProd : handleExpandProd}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        <Text ml={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'}>5 - Produção</Text>
                                    </Box>
                                    <AccordionIcon boxSize={'10'} />
                                </AccordionButton>
                                <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                    {renderProducao()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                }
            </Accordion>

            <Wrap spacing='30px' mx={'30px'} my={'30px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button variant="brand" onClick={gerarRelatorio}>Próximo</Button>
            </Wrap>

        </Box>
    );
}
