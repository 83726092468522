import { Portal, Box, useDisclosure, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState, useEffect, useContext } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import routes from "routes.js";
import { UtilContext } from 'contexts/UtilContext';

export default function Dashboard(props) {

    const history = useHistory();
    const { isAuthenticator } = useContext(UtilContext);
    const { ...rest } = props;
    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [validarAutenticacao, setValidarAutenticacao] = useState(false);
    const authBg = useColorModeValue("whitesmoke", "navy.900");

    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            if (!isAuthenticator) {
                history.push("/auth/sign-in");
            } else {
                setValidarAutenticacao(true);
            }
        }
        loaderScreen();
    }, []);

    const getRoute = () => {
        return window.location.pathname !== "/admin/full-screen-maps";
    };

    const getActiveRoute = (routes) => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].items);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].items);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].items);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].secondary;
                }
            }
        }
        return activeNavbar;
    };
    const getActiveNavbarText = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].messageNavbar;
                }
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            if (prop.collapse) {
                return getRoutes(prop.items);
            }
            if (prop.category) {
                return getRoutes(prop.items);
            } else {
                return null;
            }
        });
    };

    document.documentElement.dir = "ltr";

    const { onOpen } = useDisclosure();

    if (!validarAutenticacao) {
        return (
            <Flex px='1px' justify='center' align='center' py={'1'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box>
            <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
                <Sidebar routes={routes} display='none' {...rest} />
                {/* TODO TEMPLATE CENTRO */}
                <Box
                    float='right'
                    minHeight='100vh'
                    bg={authBg}
                    //bgGradient={colorBgMenu === 'white' ? gradientColorBlack : gradientColorOrange}
                    height='100%'
                    //overflow='auto'       // SE ESSE CARA ESTIVER auto, scroll ou hidden OS ITENS FIXOS NA VERTICAL NAO FUNCIONA, JA O visible PODE SER UTILIZADO
                    position='relative'
                    maxHeight='100%'
                    w={{ base: "100%", xl: "calc( 100% - 240px )" }}
                    maxWidth={{ base: "100%", xl: "calc( 100% - 250px )" }}
                    transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                    transitionDuration='.2s, .2s, .35s'
                    transitionProperty='top, bottom, width'
                    transitionTimingFunction='linear, linear, ease'>
                    <Portal>
                        <Box>
                            <Navbar
                                onOpen={onOpen}
                                logoText={"HEVEA"}
                                brandText={getActiveRoute(routes)}
                                secondary={getActiveNavbar(routes)}
                                message={getActiveNavbarText(routes)}
                                fixed={fixed}
                                {...rest}
                            />
                        </Box>
                    </Portal>
                    {getRoute() ? (
                        <Box
                            mx='auto'
                            p={{ base: "20px", md: "15px" }}
                            pe='20px'
                            minH='100vh'
                            pt='50px'
                            >
                            <Switch>
                                {getRoutes(routes)}
                                <Redirect from='/' to='/admin/default' />
                            </Switch>
                        </Box>
                    ) : null}
                    <Box>
                        <Footer />
                    </Box>
                </Box>
            </SidebarContext.Provider>
        </Box>
    );
}
