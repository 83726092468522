let LINK = '';
let AMBIENTE = '';
    //LINK = 'http://34.171.186.240:8080/hevea';
if (process.env.NODE_ENV === 'development') {
    LINK = 'http://localhost:8080';
    AMBIENTE = 'Des';
  } else if (process.env.NODE_ENV === 'production') {
    LINK = 'https://www.heveapp.com.br/hevea';
    AMBIENTE = 'Prod';
  } else {
    LINK = 'https://www.heveapp.com.br/hevea';
    AMBIENTE = '';
  }

//export const LINK_API_SPRING = 'http://localhost:8080';
//export const LINK_API_SPRING = LINK;
export const LINK_API_SPRING = 'https://www.heveapp.com.br/hevea';
export const ENVIRONMTE = AMBIENTE;
export const LINK_GOOGLE_STORAGE = 'https://storage.googleapis.com/hevea_bucket/user_uploads';
export const PRODUCAO = true;
export const VERSAO = '10.2';
export const SUCESSO_NA_REQUISICAO = '02'; 
export const PARAMETROS_DE_ENVIO_INCORRETOS = '04';
export const SUCESSO_NA_AUTENTICACAO_CODIGO = '05';
export const SEM_CONEXAO_COM_INTERNET = '08';
export const EMAIL_NAO_LOCALIZADO_NA_BASE = '09';
export const TIME_OUT_BACK_END = '41';
export const REFRESH_TOKEN_EXPIRADO = '16';
export const FALHA_NA_AUTENTICACAO = '42';
export const PRODUTO_CADASTRADO_COM_SUCESSO = '17';
export const CODIGO_INFORMADO_INCORRETO = '18'; 
export const CODIGO_EMAIL_INFORMADO_NAO_EXISTE = '20'; 
export const CODIGO_MENSAGEM_PERSONALIZADA = '21'; 
export const CODIGO_VERIFICAR_CAMPOS_OBRIGATORIOS = '23';
export const CODIGO_VERIFICAR_QTD_MINIMO_REQUERIDO = '26'; 
export const CODIGO_VERIFICAR_QUANTIDADE_ANEXOS_OBRIGATORIOS = '29'; 
export const ARVORE_CHECADA = '30'; 
export const FINALIZE_ARVORE_ANTERIOR = '31'; 
export const VALIDE_PELO_MENOS_UMA_ARVORE = '32'; 
export const QUALIDADE_SANGRIA_FINALIZADA = '33'; 
export const DATA_INVALIDA_PARA_LANCAR_TAREFA = '34'; 
export const DATA_INICIO_MAIOR_QUE_DATA_FIM = '35'; 
export const CPF_JA_EXISTE = '36'; 
export const CELULAR_JA_EXISTE = '37'; 
export const NAO_EXISTE_PROGRAMACAO_PARA_ESSA_DATA = '38'; 
export const DATA_SELECIONADA_IGUAL_DATA_PROGRAMADA = '39'; 
export const INFORME_DATA_POSTERIOR = '40'; 
export const ERRO_INESPERADO = '43'; 
export const SELECIONE_UMA_ARVORE = '44'; 
export const CAMPOS_OBRIGATORIOS_QUALIDADE_SANGRIA = '45';   

export const MESSAGE_ERRO_NAO_ESPERADO = 'Erro não esperado'; 
export const MESSAGE_DETALHE_ERRO_NAO_ESPERADO = 'Tente novamente mais tarde'; 
export const MESSAGE_EMAIL_NAO_EXISTE = 'E-mail não localizado'; 
export const MESSAGE_DETALHE_EMAIL_NAO_EXISTE = 'O e-mail informado não foi localizado na base de dados'; 
export const MESSAGE_SEM_CONEXAO_COM_INTERNET = 'Sem Conexão com a Internet'; 
export const MESSAGE_DETALHE_SEM_CONEXAO_COM_INTERNET = 'Favor Verificar sua Conexão'; 
export const MESSAGE_PARAMETROS_DE_ENVIO_INCORRETOS = 'Informações incorretas'; 
export const MESSAGE_DETALHE_PARAMETROS_DE_ENVIO_INCORRETOS = 'Verifique os dados enviados e tente novamente'; 
export const MESSAGE_TIME_OUT_BACK_END = 'Sem comunicação com servidor'; 
export const MESSAGE_DETALHE_TIME_OUT_BACK_END = 'Tente novamente mais tarde'; 
export const MESSAGE_SUCESSO_NA_REQUISICAO = 'Sucesso'; 
export const MESSAGE_DETALHE_SUCESSO_NA_REQUISICAO = 'Operação Realizada com Sucesso'; 

export const ID_TIPO_ANEXO_SISTEMA = '1'; 
export const ID_TIPO_ANEXO_IMAGEM = '2'; 
export const ID_TIPO_ANEXO_VIDEO = '3'; 
export const ID_TIPO_ANEXO_TECNICO_SANGRIA = '4'; 
export const ID_TIPO_ANEXO_QUALIDADE_SANGRIA = '5'; 
export const ID_TIPO_ANEXO_PRODUCAO = '6'; 
export const ID_TIPO_ANEXO_PERSONALIZADO_PDF = '7'; 
export const ID_TIPO_ANEXO_HISTORICO_SERINGUEIRO = '8'; 
export const ID_TIPO_ANEXO_ESPORADICO_PDF = '9'; 

export const ID_TIPO_REPROGRAMACAO_MANTER_HISTORICO = '1'; 
export const ID_TIPO_REPROGRAMACAO_APAGAR_HISTORICO = '2'; 
export const ID_TIPO_PESSOA_SANGRADOR = '3'; 

export const ID_STATUS_TAREFA_REALIZADA = '1'; 
export const ID_STATUS_TAREFA_PENDENTE = '2'; 
export const ID_STATUS_TAREFA_ATRASADA = '3'; 
export const ID_STATUS_TAREFA_NAO_REALIZADA = '4'; 

export const SEXO_MASCULINO = 'MASCULINO'; 
export const SEXO_FEMININO = 'FEMININO'; 

export const CONSUMO_BAIXO = '0.5'; 
export const CONSUMO_MEDIO = '0.5'; 
export const CONSUMO_ALTO = '0.5'; 
export const PROFUNDIDADE_BAIXO = '0.5'; 
export const PROFUNDIDADE_MEDIO = '0.5'; 
export const PROFUNDIDADE_ALTO = '0.5'; 
export const FERIMENTO_DESVIADO = '1.0'; 
export const FERIMENTO_NAO_DESVIADO = '4.0'; 
export const ATAQUE_GERATRIZES = '0.4'; 
export const DECLIVIDADE_CORTE = '0.3'; 
export const CONFORMIDADE_EQUIPAMENTOS = '0.2'; 
export const IMPUREZAS_COAGULO = '0.2'; 
export const FACA_NAO_AMOLADA = '0.2'; 
export const ARVORE_SANGRADA = '0.2'; 
export const CONTROLE_FITOSSANITARIO = '0.2'; 
export const LATEX_PERDA_PRODUCAO = '0.2'; 
export const VALOR_FERIMENTO_DESVIADO = '1.0'; 
export const VALOR_FERIMENTO_NAO_DESVIADO = '4.0'; 

export const PERFIL_CADASTRO_PRODUTOR = 'cadastroProdutor'; 
export const PERFIL_GESTAO_ACESSOS = 'gestaoDeAcessos'; 

export const TIPO_USUARIO_PRODUTOR = '1'; 
export const TIPO_USUARIO_GERENTE = '2'; 
export const TIPO_USUARIO_TECNICO = '3'; 
export const TIPO_USUARIO_SERINGUEIRO = '5'; 

export const ID_PERMISSAO_GERENTE = '2';
export const ID_PERMISSAO_SERINGUEIRO = '3';
export const ID_PERMISSAO_PRODUTOR = '4';
export const ID_PERMISSAO_ADMIN = '5';
export const ID_PERMISSAO_TECNICO = '6';

export const OPERACAO_INSERT = 'insert'; 
export const OPERACAO_UPDATE = 'update'; 

export const MENSAGEM_SANGRIA_VIGENTE = 'Esta safra está finalizada.'; 

export const URL_PLAY = 'https://play.google.com/store/apps/details?id=com.heveapp'; 
export const URL_APPLE = 'https://apps.apple.com/br/app/hevea/id6471019618'; 

export const PERFIL_TODOS = 'todos';
export const PERFIL_PESQUISAR = 'pesquisar';
export const PERFIL_SERINGUEIRO = 'seringueiro';
export const PERFIL_GERENTE = 'gerente';
export const PERFIL_PRODUTOR = 'produtor';
export const PERFIL_TECNICO = 'tecnico';
export const PERFIL_ADMIN = 'admin';
export const PERFIL_DESENV = 'desenv';

const cor1 = 'rgba(204, 102, 0, 0.5)';
const cor2 = 'rgba(255, 242, 230, 0.2)';
export const LINEAR_GRADIENT_ORANGE = [`linear-gradient(to top, ${cor1} 1%, ${cor2}, ${cor2}, ${cor2}, ${cor2}, ${cor2}, ${cor2}, ${cor2}, ${cor2}, ${cor2}, ${cor1})`];
const cor3 = 'rgba(0, 102, 0, 0.5)';
const cor4 = 'rgba(236, 249, 236, 0.2)';
export const LINEAR_GRADIENT_GREEN  = [`linear-gradient(to top, ${cor3} 1%, ${cor4}, ${cor4}, ${cor4}, ${cor4}, ${cor4}, ${cor4}, ${cor4}, ${cor4}, ${cor4}, ${cor3})`];

export const COR_REALIZADA = '#00b300';
export const COR_REALIZADA_2 = '#31df21';
export const COR_PENDENTE = '#DAA520';
export const COR_ATRASADA = '#FF0000';
export const COR_NAO_REALIZADA = '#FF0000';
export const COR_MARRON = '#993600';
export const COR_CHUVA = 'blue.700';

export const COR_ARVORE_CHECADA = '#059d70';
export const COR_ARVORE_NAO_CHECADA = '#f9e5be';

export const TIPO_DELETE_UM_SERINGUEIRO = '1';
export const TIPO_DELETE_UMA_SAFRA = '2';
export const TIPO_DELETE_UMA_PROPRIEDADE = '3';
export const TIPO_DELETE_UM_GERENTE = '4';


// TROCAR URL
// OCULTAR BOTAO DE EDICAO DE CORES
// APAGAR UA COMPLETA
// PROGRAMACAO
// LANCAR TAREFA