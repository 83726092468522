import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Box,
    Select,
    TableContainer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    SimpleGrid,
    Center,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import ModalDialog from 'components/modal/ModalDialog';
import MessageSreen from 'components/message/MessageSreen';
//import { VALIDAR_AUTENTICACAO_USUARIO } from '../../../../util/ValidarAutenticacao';

const qtdPage = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
];

export default function PropriedadesPaginado() {

    const history = useHistory();
    const [listaLogProgramacao, setListaLogProgramacao] = useState([]);
    const [listaPropriedadesCompleto, setListaPropriedadesCompleto] = useState([]);
    const [listaPropriedades, setListaPropriedades] = useState([]);
    const [propriedadeSelecionado, setPropriedadeSelecionado] = useState('');
    const [listaProdutores, setListaProdutores] = useState([]);
    const [produtorSelecionado, setProdutorSelecionado] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [arrayTemp, setArrayTemp] = useState([]);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [mesageDialog, setMesageDialog] = useState('');
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);


    const textColor = useColorModeValue("secondaryGray.900", "white");
    const shadowBlack = ('0px 2px 5px 2px rgba(0, 0, 0, 0.30)');            // 1-posicao horizontal  2-possicao vertical  3-desfoque 4-largura
    const gradientHeader = 'linear-gradient(white, white, white)';

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            //await VALIDAR_AUTENTICACAO_USUARIO();

            setListaLogProgramacao([]);
            setCurrentPage(1);

            const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');
            const url = `api/usuario/retornaProdutoresComPermissaoDeAcesso?idUsuario=${idUsuarioLogado}`;

            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = await response.respostaRequisicao.data;
                setListaLogProgramacao(res);
                setListaPropriedadesCompleto(res);
                await carregaPropriedades(res);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        }
        loaderScreen();
    }, [])

    const totalPages = Math.ceil(listaLogProgramacao.length / itemsPerPage);
    const paginatedData = listaLogProgramacao.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        window.scrollTo(0, 0);
    };

    const changePage = (e) => {
        const val = e.target.value;
        setItemsPerPage(val);
    }

    async function carregaPropriedades(res) {
        if (res.length > 0) {

            let arrayUa = [];
            let arrayProdutor = [];
            for (let i = 0; i < res.length; i++) {
                const item = res[i];

                const obj22 = {
                    value: `${item.idUsuario}`,
                    label: item.nome
                }
                arrayProdutor.push(obj22);

                const listUa = item.listUa;
                if (listUa.length > 0) {

                    for (let j = 0; j < listUa.length; j++) {
                        const ua = listUa[j];

                        const obj = {
                            value: `${ua.idUa}`,
                            label: ua.nomeFazenda
                        }
                        arrayUa.push(obj);
                    }
                }
            }
            /*
            ╔═══════════════════════════════════════════════════════════════════════════╗
                ORDENAR ARRAY POR STRING ACENTUADA
                O localeCompare ORDENA COM REGRAS DE UMA LOCALIDADE ESPECÍFICA
                O pt E PARA PORTUGUES E { sensitivity: base } 
                E PARA IGNORAR DIFERENCAS DE ACENTUACAO
            ╚═══════════════════════════════════════════════════════════════════════════╝*/
            const sortedList = arrayUa.sort((a, b) =>
                a.label.localeCompare(b.label, 'pt', { sensitivity: 'base' })
            );

            setListaPropriedades(sortedList);
            setListaProdutores(arrayProdutor);
        }
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const editarFazenda = async (item, ua) => {

        const idUsuarioLogado = localStorage.getItem('@HE-idUsuarioLogado');
        const url = `api/ua/listaProdutoresAndPropriedades?idTipoUsuario=1&idUsuario=${idUsuarioLogado}&idUsuarioTabelaUa=${item.idUsuario}&idUa=${ua.idUa}`;
        const response = await Api.getRequest(url);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = await response.respostaRequisicao.data;

            let arrayValidado = [];
            for (let i = 0; i < res.length; i++) {
                const element = res[i];
                const nomeUsuarioP = element.nomeUsuario;
                const idUsuarioProdutorP = element.idUsuario;

                if (element.listaUaSafraPessoaDto.length > 0) {
                    const listaDeUa = element.listaUaSafraPessoaDto;
                    await new Promise(resolve => {
                        resolve(
                            listaDeUa.map(function (uaItem) {
                                const xx = {
                                    ...uaItem,
                                    nomeUsuario: nomeUsuarioP,
                                    idUsuario: idUsuarioProdutorP,
                                    descricao: uaItem.descricao != null ? uaItem.descricao : '',
                                    nomeFazenda: uaItem.nomeFazenda != null ? uaItem.nomeFazenda : '',
                                    endereco: uaItem.endereco != null ? uaItem.endereco : '',
                                    cep: uaItem.cep != null ? uaItem.cep : '',
                                    localizacao: uaItem.localizacao != null ? uaItem.localizacao : '',
                                    idMunicipio: uaItem.idMunicipio != null ? uaItem.idMunicipio : '',
                                    nomeMunicipio: uaItem.nomeMunicipio != null ? uaItem.nomeMunicipio : '',
                                    areaTotal: uaItem.areaTotal != null ? uaItem.areaTotal : 0,
                                }
                                arrayValidado.push(xx);
                            })
                        )
                    });
                }
            }
            const xx = arrayValidado[0];
            const json = {
                idUa: ua.idUa,
                dadosUa: xx,
                idUsuarioProdutor: item.idUsuario,
                nomeProdutor: item.nome,
            }
            localStorage.setItem('@HE-propriedadeParaEdicao', JSON.stringify(json));
            localStorage.setItem('@HE-idUsuarioSelecionadoTelaListaPropridade', `${item.idUsuario}`);
            history.push("/admin/propriedades-form");
        }
    }

    const openAlertDialog = (item) => {
        const nome = item.nome;
        setMesageDialog(`A nova propriedade será registrada para o produtor ${nome.toUpperCase()}, deseja continuar?`);
        setArrayTemp(item);
        setOpenModalDialog(true);
    }

    const novarPropriedade = (item) => {
        setOpenModalDialog(false);

        const json = {
            idUa: null,
            dadosUa: null,
            idUsuarioProdutor: item.idUsuario,
            nomeProdutor: item.nome,
        }
        localStorage.setItem('@HE-propriedadeParaEdicao', JSON.stringify(json));
        history.push("/admin/propriedades-form");
    }

    const changeProdutor = (val) => {
        setProdutorSelecionado(val);

        if (val != '') {
            const zz3 = listaPropriedadesCompleto.filter(item => item.idUsuario == val);
            setListaLogProgramacao(zz3);
        } else {
            setListaLogProgramacao(listaPropriedadesCompleto);
        }
    }

    const changeUa = (val) => {
        setPropriedadeSelecionado(val);

        if (val != '') {
            const zz3 = listaPropriedadesCompleto.filter(item =>
                item.listUa.some(subItem => subItem.idUa == val)
            );
            setListaLogProgramacao(zz3);
        } else {
            setListaLogProgramacao(listaPropriedadesCompleto);
        }
    }

    function renderPesquisa() {
        return (
            <Box mt={'10px'} py={'10px'} mx={'20px'} borderWidth={'3px'} borderRadius={'10px'} borderColor={'blackAlpha.500'} boxShadow={shadowBlack}>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                    <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                        <Box>
                            <Text align={'start'} color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Produtor</Text>
                            <Select
                                value={produtorSelecionado}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'300px'}
                                onChange={(e) => changeProdutor(e.target.value)}
                            >
                                {listaProdutores.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex flexDirection={'column'} justify={'center'} align={'center'}>
                        <Box>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Propriedades</Text>
                            <Select
                                value={propriedadeSelecionado}
                                errorBorderColor='crimson'
                                placeholder="selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'300px'}
                                onChange={(e) => changeUa(e.target.value)}
                            >
                                {listaPropriedades.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                </SimpleGrid>
            </Box>
        )
    }

    function renderPropriedades() {
        let qtdFim = currentPage * itemsPerPage;
        let qtdIni = (qtdFim - itemsPerPage) + 1;
        return (
            <Flex px={'10px'} direction="column" align="center" >
                <TableContainer w={'100%'}>
                    <Table whiteSpace={'break-spaces'} size="sm">
                        <Thead>
                            <Tr>
                                <Th w={'100%'} color={'white'} bg={'white'}>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {paginatedData.map((item, index) => {
                                const qrdUa = item.listUa.length;
                                return (
                                    <Tr key={index} >
                                        <Td>
                                            <Accordion allowToggle mb={'5px'}>
                                                <AccordionItem
                                                    borderWidth={'0px'}
                                                    boxShadow={shadowBlack}
                                                    borderRadius={'10px'} >
                                                    <AccordionButton _focus={{ boxShadow: 'none' }} bgGradient={gradientHeader} borderRadius={'10px'} onClick={() => { }}>
                                                        <Box flex='1' textAlign='left'>
                                                            <Flex justify='space-between' mb='5px' align='center'>
                                                                <Box w={'75%'}>
                                                                    <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight='100%'>{item.nome}</Text>
                                                                </Box>
                                                                <Box w={'20%'}>
                                                                    <Flex justify='space-around' align='center'>
                                                                        <Box borderRadius={'8px'} borderWidth={'0px'} borderColor={'green.500'} bg={'gray.200'}>
                                                                            <Text mx={'10px'} my={'4px'} color={'green.900'} fontSize='15px' fontWeight={'bold'} lineHeight='100%'>{`${qrdUa}`}</Text>
                                                                        </Box>
                                                                    </Flex>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                        <AccordionIcon w={'25px'} h={'25px'} />
                                                    </AccordionButton>
                                                    <AccordionPanel pb={4}>
                                                        <Box>
                                                            <Flex px={'10px'} w={'100%'} mb={'10px'} justify='end' align='center'>
                                                                <Button variant="miniBrand" onClick={() => { openAlertDialog(item) }}>Nova Propriedade</Button>
                                                            </Flex>
                                                            {item.listUa.map((rr, ww) => {
                                                                return (
                                                                    <Box key={ww} mb={'15px'}>
                                                                        <Flex flexDirection={'column'} px={'10px'} py={'5px'} justify='start' align='center' borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.200'} bg={'blackAlpha.100'}>
                                                                            <Flex w={'100%'} mb={'10px'} justify='space-between' align='center'>
                                                                                <Flex w={'100%'} mb={'5px'} justify='start' align='center'>
                                                                                    <Text color={'black'} fontSize='18px' fontWeight={'bold'}>{'Propriedade:'}</Text>
                                                                                    <Text ml={'10px'} color={'gray.500'} fontSize='17px' fontWeight={'bold'}>{rr.nomeFazenda}</Text>
                                                                                </Flex>
                                                                                <Flex justify='end' align='center' >
                                                                                    <Button variant="action" onClick={() => { editarFazenda(item, rr) }}>Acessar</Button>
                                                                                </Flex>
                                                                            </Flex>
                                                                            {rr.listaSafra.length > 0 &&
                                                                                rr.listaSafra.map((sa, hh) => {
                                                                                    const disabledSafra = sa.sangriaAtiva == true ? false : true;
                                                                                    return (
                                                                                        <Flex key={hh} w={'100%'} mb={'3px'} justify='start' align='center'>
                                                                                            <Flex minW={'200px'} justify='start' align='center'>
                                                                                                <Text color={'black'} fontSize='15px' fontWeight={'medium'}>{'Safra:'}</Text>
                                                                                                <Text ml={'10px'} color={'gray.500'} fontSize='14px' fontWeight={'medium'}>{sa.nomeSafra}</Text>
                                                                                            </Flex>
                                                                                            {disabledSafra ? (
                                                                                                <Text ml={'10px'} color={'red'} fontSize='14px' fontWeight={'bold'}>{'Finalizada'}</Text>
                                                                                            ) : (
                                                                                                <Text ml={'10px'} color={'green.500'} fontSize='14px' fontWeight={'bold'}>{'Vigente'}</Text>
                                                                                            )}
                                                                                        </Flex>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Flex>
                                                                    </Box>
                                                                )
                                                            })}
                                                        </Box>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Flex px={'10px'} w={'100%'} mt={5} justify='space-between' align='center'>
                    <Flex justify='center' align='center'>
                        <Text color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>Qtd Linhas</Text>
                        <Box ml={'10px'}>
                            <Select
                                value={itemsPerPage}
                                placeholder=""
                                variant="filled"
                                borderRadius="10px"
                                borderColor={'gray.200'}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                size="sm"
                                h={'35px'}
                                onChange={changePage}>
                                {qtdPage.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex justify='center' align='center'>
                        <Text mr={'20px'} color={textColor} fontSize='15px' fontWeight={'semibold'} lineHeight='100%'>{`${qtdIni}-${qtdFim} de ${listaLogProgramacao.length}`}</Text>
                        <Button variant="outline" leftIcon={<RiArrowLeftSLine />} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 ? true : false} />
                        <Button variant="outline" rightIcon={<RiArrowRightSLine />} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages ? true : false} ml={2} />
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'1px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    return (
        <Card direction='column' w='100%' px='0px' py='0px'>
            <Box pt={{ base: "100px", md: "60px", xl: "60px" }}>
                {isVisibleAlert && renderAlert()}
                <Text color={'orange.600'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Lista de produtores e propriedades'}</Text>
                {renderPesquisa()}
                <Flex px={'100px'} justify='space-between' align='center' mt={'20px'}>
                    <Flex w={'50%'} justify='start' align='center'>
                        <Text color={'orange.600'} fontSize='18px' fontWeight={'bold'} lineHeight='100%'>Produtores</Text>
                        <Center ml={'10px'} >
                            <TooltipIcon
                                label={
                                    <Box py={'5px'} >
                                        <Text align={'center'}>{'Novo Cadastro'}</Text>
                                        <Text align={'start'} lineHeight='100%' mt={'10px'}>{'- Os novos cadastros terá como proprietário o produtor selecionado na lista'}</Text>
                                        <Text align={'start'} lineHeight='100%' mt={'10px'}>{'- Para cadastrar uma propriedadde no seu nome, pesquise seu nome na lista de produtores e clique em novo'}</Text>
                                    </Box>
                                }
                                height={'18px'} />
                        </Center>
                    </Flex>
                    <Flex w={'50%'} justify='end' align='center' >
                        <Text color={'orange.600'} fontSize='18px' fontWeight={'bold'} lineHeight='100%'>Qtd Propriedades</Text>
                    </Flex>
                </Flex>
                {renderPropriedades()}
            </Box>
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { novarPropriedade(arrayTemp) }} onPressCancel={() => { setOpenModalDialog(false) }} descricao={mesageDialog} />}
        </Card>
    );
};
