import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Text,
    Box,
    Flex,
} from "@chakra-ui/react";

function ModalDialogFinalizarSangria({ isOpen, onPressOk, onPressCancel }) {

    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE} >
                <ModalHeader justifyContent={'center'} alignItems={'center'} py={'10px'}>
                    <Text color={'red'} fontSize={'20px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Atenção'}</Text>
                </ModalHeader>
                <ModalBody>
                    <Text color={'black'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Finalizando a sangria ocorrerá as seguintes ações:'}</Text>
                    <Box pl={'10px'} mt={'20px'}>
                        <Flex justify='start' align='start'>
                            <Text color={'gray.500'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{'-'}</Text>
                            <Text color={'gray.500'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{'O processo de programação automático será encerrado.'}</Text>
                        </Flex>
                        <Flex mt={'5px'} justify='start' align='start'>
                            <Text color={'gray.500'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{'-'}</Text>
                            <Text color={'gray.500'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{'Os dados estarão disponíveis no sistema, porém não será possível altera-los.'}</Text>
                        </Flex>
                        <Flex mt={'5px'} justify='start' align='start'>
                            <Text color={'gray.500'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{'-'}</Text>
                            <Text color={'gray.500'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{'Após a finalização, os seringueiros registrados nessa safra continuarão cadastrados no sistema podendo ser incluídos em nova safra.'}</Text>
                        </Flex>
                    </Box>
                    <Text mt={'20px'} color={'black'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>{'Deseja realmente finalizar?'}</Text>
                </ModalBody>
                <ModalFooter mt={'20px'} borderTopWidth={'1px'} borderColor={'blackAlpha.700'} justifyContent={'space-around'}>
                    <Button w={'100px'} variant="action" mr={3} onClick={onPressCancel}>Cancelar</Button>
                    <Button w={'100px'} variant="miniBrand" onClick={onPressOk}>Sim</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalDialogFinalizarSangria;