export const ARRAY_DIAS_UTEIS_DA_SEMANA = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', null, null]


export const CLONES = [
    { value: '2', label: "CDC312" },
    { value: '3', label: "FDR5788" },
    { value: '4', label: "FX3864" },
    { value: '5', label: "GT1" },
    { value: '6', label: "IAC" },
    { value: '7', label: "IACsérie500" },
    { value: '8', label: "IAN717" },
    { value: '9', label: "IAN873" },
    { value: '10', label: "PB" },
    { value: '11', label: "PB217" },
    { value: '12', label: "PB235" },
    { value: '13', label: "PB350" },
    { value: '14', label: "PMB1" },
    { value: '15', label: "PR" },
    { value: '16', label: "PR255" },
    { value: '17', label: "RRIM" },
    { value: '18', label: "RRIM600" },
    { value: '19', label: "RRIM937" },
    { value: '20', label: "RRIM938" },
]


export const IDADE_SANGRIA = [
    { value: '1', label: "1" },
    { value: '2', label: "2" },
    { value: '3', label: "3" },
    { value: '4', label: "4" },
    { value: '5', label: "5" },
    { value: '6', label: "6" },
    { value: '7', label: "7" },
    { value: '8', label: "8" },
    { value: '9', label: "9" },
    { value: '10', label: "10" },
    { value: '11', label: "11" },
    { value: '12', label: "12" },
    { value: '13', label: "13" },
    { value: '14', label: "14" },
    { value: '15', label: "15" },
    { value: '16', label: "16" },
    { value: '17', label: "17" },
    { value: '18', label: "18" },
    { value: '19', label: "19" },
    { value: '20', label: "20" },
    { value: '21', label: "21" },
    { value: '22', label: "22" },
    { value: '23', label: "23" },
    { value: '24', label: "24" },
    { value: '25', label: "25" },
    { value: '26', label: "26" },
    { value: '27', label: "27" },
    { value: '28', label: "28" },
    { value: '29', label: "29" },
    { value: '30', label: "30" },
    { value: '31', label: "31" },
    { value: '32', label: "32" },
    { value: '33', label: "33" },
    { value: '34', label: "34" },
    { value: '35', label: "35" },
    { value: '36', label: "36" },
    { value: '37', label: "37" },
    { value: '38', label: "38" },
    { value: '39', label: "39" },
    { value: '40', label: "40" },
]


export const PAINEL = [
    { value: '1', label: '1/2S↓' },
    { value: '2', label: '1/2S↑' },
    { value: '3', label: '1/2S↓↑' },
    { value: '4', label: '1/3S↓' },
    { value: '5', label: '1/3S↑' },
    { value: '6', label: '1/3S↓↑' },
    { value: '7', label: '1/4S↓' },
    { value: '8', label: '1/4S↑' },
    { value: '9', label: '1/4S↓↑' }
]

export const FREQUENCIA = [
    { value: '3', label: 'D3' },
    { value: '4', label: 'D4' },
    { value: '5', label: 'D5' },
    { value: '6', label: 'D6' },
    { value: '7', label: 'D7' },
]

export const ARRAY_TAREFA = [
    { value: '1', label: 'A' },
    { value: '2', label: 'B' },
    { value: '3', label: 'C' },
    { value: '4', label: 'D' },
    { value: '5', label: 'E' },
    { value: '6', label: 'F' },
    { value: '7', label: 'G' },
]

// export const TAREFAS = [
//     { value: '1', label: 'A' },
//     { value: '2', label: 'B' },
//     { value: '3', label: 'C' },
//     { value: '4', label: 'D' },
//     { value: '5', label: 'E' },
//     { value: '6', label: 'F' },
//     { value: '7', label: 'G' },
// ]

export const TAREFAS22 = [
    {bgColor: 'white', value: '1', label: 'A' },
    {bgColor: 'white', value: '2', label: 'B' },
    {bgColor: 'white', value: '3', label: 'C' },
    {bgColor: 'white', value: '4', label: 'D' },
    {bgColor: 'white', value: '5', label: 'E' },
    {bgColor: 'white', value: '6', label: 'F' },
    {bgColor: 'white', value: '7', label: 'G' },
    {bgColor: '#ffcccc', value: '8', label: 'N Realizada' },
]

export const CASCA = [
    { value: '1', label: 'Virgem' },
    { value: '2', label: 'Regenerada' },
]

export const SENTIDO = [
    { value: '1', label: '▼ Descendente' },
    { value: '2', label: '▲ Ascendente' },
    { value: '3', label: '▲▼ Descendente + Ascendente' },
]

export const AVALIACAO_SANGRIA = [
    { value: "1", label: "Branco" },
    { value: "2", label: "Boa" },
    { value: "3", label: "Média" },
    { value: "4", label: "Ruim" },
]

export const NOMENCLATURA_PAINEL = [
    { value: '1', label: 'A1' },
    { value: '2', label: 'A2' },
	{ value: '3', label: 'A3' },
	{ value: '4', label: 'A4' },
	{ value: '5', label: 'A5' },
	{ value: '6', label: 'A6' },
	{ value: '7', label: 'A7' },
	{ value: '8', label: 'A8' },
	{ value: '9', label: 'A9' },
	{ value: '10', label: 'A10' },
	{ value: '11', label: 'B1' },
    { value: '12', label: 'B2' },
	{ value: '13', label: 'B3' },
	{ value: '14', label: 'B4' },
	{ value: '15', label: 'B5' },
	{ value: '16', label: 'B6' },
	{ value: '17', label: 'B7' },
	{ value: '18', label: 'B8' },
	{ value: '19', label: 'B9' },
	{ value: '20', label: 'B10' },
    { value: '21', label: 'C1' },
    { value: '22', label: 'C2' },
	{ value: '23', label: 'C3' },
	{ value: '24', label: 'C4' },
	{ value: '25', label: 'C5' },
	{ value: '26', label: 'C6' },
	{ value: '27', label: 'C7' },
	{ value: '28', label: 'C8' },
	{ value: '29', label: 'C9' },
	{ value: '30', label: 'C10' },
	{ value: '31', label: 'C11' },
	{ value: '32', label: 'C12' },
	{ value: '33', label: 'C13' },
	{ value: '34', label: 'C14' },
    { value: '35', label: 'D1' },
    { value: '36', label: 'D2' },
	{ value: '37', label: 'D3' },
	{ value: '38', label: 'D4' },
	{ value: '39', label: 'D5' },
	{ value: '40', label: 'D6' },
	{ value: '41', label: 'D7' },
	{ value: '42', label: 'D8' },
	{ value: '43', label: 'D9' },
	{ value: '44', label: 'D10' },
	{ value: '45', label: 'D11' },
	{ value: '46', label: 'D12' },
	{ value: '47', label: 'D13' },
	{ value: '48', label: 'D14' },
    { value: '49', label: 'E1' },
    { value: '50', label: 'E2' },
	{ value: '51', label: 'E3' },
	{ value: '52', label: 'E4' },
	{ value: '53', label: 'E5' },
	{ value: '54', label: 'E6' },
	{ value: '55', label: 'E7' },
	{ value: '56', label: 'E8' },
	{ value: '57', label: 'E9' },
	{ value: '58', label: 'E10' },
    { value: '59', label: 'F1' },
    { value: '60', label: 'F2' },
	{ value: '61', label: 'F3' },
	{ value: '62', label: 'F4' },
	{ value: '63', label: 'F5' },
	{ value: '64', label: 'F6' },
	{ value: '65', label: 'F7' },
	{ value: '66', label: 'F8' },
	{ value: '67', label: 'F9' },
	{ value: '68', label: 'F10' },
]

export const PREVISTO_REALIZADO = [
    { value: '1', label: 'Previsto' },
    { value: '2', label: 'Realizado' },
]

export const QTD_PAGE = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
];

export const MOTIVO_TAREFA_NAO_RELIZADA = [
    { label: "Falta", value: '1' },
    { label: "Chuva", value: '2' },
    { label: "Licença Médica", value: '3' },
    { label: "Coagulação", value: '4' },
    { label: "Coleta", value: '5' },
    { label: "Estimulação", value: '6' },
    { label: "Painel Molhado", value: '7' },
]


export const LIST_UFS = [
    { value: "AC", label: "Acre", style: { color: 'red' } },
    { value: "AL", label: "Alagoas", style: { color: 'blue' } },
    { value: "AM", label: "Amazonas" },
    { value: "AP", label: "Amapá" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MG", label: "Minas Gerais" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MT", label: "Mato Grosso" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "PR", label: "Paraná" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SE", label: "Sergipe" },
    { value: "SP", label: "São Paulo" },
    { value: "TO", label: "Tocantins" },
]

export const ARRAY_PERMISSOES_MOCK = [
    { nome: 'ROLE_PESQUISAR', status: false },
    { nome: 'ROLE_GERENTE', status: false },
    { nome: 'ROLE_SERINGUEIRO', status: false },
    { nome: 'ROLE_PRODUTOR', status: false },
    { nome: 'ROLE_ADMIN', status: false },
    { nome: 'ROLE_TECNICO', status: false },
    { nome: 'ROLE_DESENV_ARQUITETO', status: false },
]

export const LISTA_MESES2 = [
    { label: 'JAN 2023', value: '2023-01-01' },
    { label: 'FEV 2023', value: '2023-02-01' },
    { label: 'MAR 2023', value: '2023-03-01' },
    { label: 'ABR 2023', value: '2023-04-01' },
    { label: 'MAI 2023', value: '2023-05-01' },
    { label: 'JUN 2023', value: '2023-06-01' },
    { label: 'JUL 2023', value: '2023-07-01' },
    { label: 'AGO 2023', value: '2023-08-01' },
    { label: 'SET 2023', value: '2023-09-01' },
    { label: 'OUT 2023', value: '2023-10-01' },
    { label: 'NOV 2023', value: '2023-11-01' },
    { label: 'DEZ 2023', value: '2023-12-01' },
    { label: 'JAN 2024', value: '2024-01-01' },
    { label: 'FEV 2024', value: '2024-02-01' },
    { label: 'MAR 2024', value: '2024-03-01' },
    { label: 'ABR 2024', value: '2024-04-01' },
    { label: 'MAI 2024', value: '2024-05-01' },
    { label: 'JUN 2024', value: '2024-06-01' },
    { label: 'JUL 2024', value: '2024-07-01' },
    { label: 'AGO 2024', value: '2024-08-01' },
    { label: 'SET 2024', value: '2024-09-01' },
    { label: 'OUT 2024', value: '2024-10-01' },
    { label: 'NOV 2024', value: '2024-11-01' },
    { label: 'DEZ 2024', value: '2024-12-01' },
    { label: 'JAN 2025', value: '2025-01-01' },
    { label: 'FEV 2025', value: '2025-02-01' },
    { label: 'MAR 2025', value: '2025-03-01' },
    { label: 'ABR 2025', value: '2025-04-01' },
    { label: 'MAI 2025', value: '2025-05-01' },
    { label: 'JUN 2025', value: '2025-06-01' },
    { label: 'JUL 2025', value: '2025-07-01' },
    { label: 'AGO 2025', value: '2025-08-01' },
    { label: 'SET 2025', value: '2025-09-01' },
    { label: 'OUT 2025', value: '2025-10-01' },
    { label: 'NOV 2025', value: '2025-11-01' },
    { label: 'DEZ 2025', value: '2025-12-01' },
]


export const ARRAY_MESES_OLD = [
    { label: 'Janeiro - 2023', value: '2023-01-01' },
    { label: 'Fevereiro - 2023', value: '2023-02-01' },
    { label: 'Março - 2023', value: '2023-03-01' },
    { label: 'Abril - 2023', value: '2023-04-01' },
    { label: 'Maio - 2023', value: '2023-05-01' },
    { label: 'Junho - 2023', value: '2023-06-01' },
    { label: 'Julho - 2023', value: '2023-07-01' },
    { label: 'Agosto - 2023', value: '2023-08-01' },
    { label: 'Setembro - 2023', value: '2023-09-01' },
    { label: 'Outubro - 2023', value: '2023-10-01' },
    { label: 'Novembro - 2023', value: '2023-11-01' },
    { label: 'Dezembro - 2023', value: '2023-12-01' },
    { label: 'Janeiro - 2024', value: '2024-01-01' },
    { label: 'Fevereiro - 2024', value: '2024-02-01' },
    { label: 'Março - 2024', value: '2024-03-01' },
    { label: 'Abril - 2024', value: '2024-04-01' },
    { label: 'Maio - 2024', value: '2024-05-01' },
    { label: 'Junho - 2024', value: '2024-06-01' },
    { label: 'Julho - 2024', value: '2024-07-01' },
    { label: 'Agosto - 2024', value: '2024-08-01' },
    { label: 'Setembro - 2024', value: '2024-09-01' },
    { label: 'Outubro - 2024', value: '2024-10-01' },
    { label: 'Novembro - 2024', value: '2024-11-01' },
    { label: 'Dezembro - 2024', value: '2024-12-01' },
]

export const ARRAY_MESES = [
    { label: 'JAN - 2023', value: '2023-01-01' },
    { label: 'FEV - 2023', value: '2023-02-01' },
    { label: 'MAR - 2023', value: '2023-03-01' },
    { label: 'ABR - 2023', value: '2023-04-01' },
    { label: 'MAI - 2023', value: '2023-05-01' },
    { label: 'JUN - 2023', value: '2023-06-01' },
    { label: 'JUL - 2023', value: '2023-07-01' },
    { label: 'AGO - 2023', value: '2023-08-01' },
    { label: 'SET - 2023', value: '2023-09-01' },
    { label: 'OUT - 2023', value: '2023-10-01' },
    { label: 'NOV - 2023', value: '2023-11-01' },
    { label: 'DEZ - 2023', value: '2023-12-01' },
    { label: 'JAN - 2024', value: '2024-01-01' },
    { label: 'FEV - 2024', value: '2024-02-01' },
    { label: 'MAR - 2024', value: '2024-03-01' },
    { label: 'ABR - 2024', value: '2024-04-01' },
    { label: 'MAI - 2024', value: '2024-05-01' },
    { label: 'JUN - 2024', value: '2024-06-01' },
    { label: 'JUL - 2024', value: '2024-07-01' },
    { label: 'AGO - 2024', value: '2024-08-01' },
    { label: 'SET - 2024', value: '2024-09-01' },
    { label: 'OUT - 2024', value: '2024-10-01' },
    { label: 'NOV - 2024', value: '2024-11-01' },
    { label: 'DEZ - 2024', value: '2024-12-01' },
    { label: 'JAN - 2025', value: '2025-01-01' },
    { label: 'FEV - 2025', value: '2025-02-01' },
    { label: 'MAR - 2025', value: '2025-03-01' },
    { label: 'ABR - 2025', value: '2025-04-01' },
    { label: 'MAI - 2025', value: '2025-05-01' },
    { label: 'JUN - 2025', value: '2025-06-01' },
    { label: 'JUL - 2025', value: '2025-07-01' },
    { label: 'AGO - 2025', value: '2025-08-01' },
    { label: 'SET - 2025', value: '2025-09-01' },
    { label: 'OUT - 2025', value: '2025-10-01' },
    { label: 'NOV - 2025', value: '2025-11-01' },
    { label: 'DEZ - 2025', value: '2025-12-01' },
]
export const ARRAY_MESES_COLOR = [
    { label: 'JAN - 2023', value: '2023-01-01', colorOp: 'white' },
    { label: 'FEV - 2023', value: '2023-02-01', colorOp: 'white' },
    { label: 'MAR - 2023', value: '2023-03-01', colorOp: 'white' },
    { label: 'ABR - 2023', value: '2023-04-01', colorOp: 'white' },
    { label: 'MAI - 2023', value: '2023-05-01', colorOp: 'white' },
    { label: 'JUN - 2023', value: '2023-06-01', colorOp: 'white' },
    { label: 'JUL - 2023', value: '2023-07-01', colorOp: 'white' },
    { label: 'AGO - 2023', value: '2023-08-01', colorOp: 'white' },
    { label: 'SET - 2023', value: '2023-09-01', colorOp: 'white' },
    { label: 'OUT - 2023', value: '2023-10-01', colorOp: 'white' },
    { label: 'NOV - 2023', value: '2023-11-01', colorOp: 'white' },
    { label: 'DEZ - 2023', value: '2023-12-01', colorOp: 'white' },
    { label: 'JAN - 2024', value: '2024-01-01', colorOp: 'white' },
    { label: 'FEV - 2024', value: '2024-02-01', colorOp: 'white' },
    { label: 'MAR - 2024', value: '2024-03-01', colorOp: 'white' },
    { label: 'ABR - 2024', value: '2024-04-01', colorOp: 'white' },
    { label: 'MAI - 2024', value: '2024-05-01', colorOp: 'white' },
    { label: 'JUN - 2024', value: '2024-06-01', colorOp: 'white' },
    { label: 'JUL - 2024', value: '2024-07-01', colorOp: 'white' },
    { label: 'AGO - 2024', value: '2024-08-01', colorOp: 'white' },
    { label: 'SET - 2024', value: '2024-09-01', colorOp: 'white' },
    { label: 'OUT - 2024', value: '2024-10-01', colorOp: 'white' },
    { label: 'NOV - 2024', value: '2024-11-01', colorOp: 'white' },
    { label: 'DEZ - 2024', value: '2024-12-01', colorOp: 'white' },
    { label: 'JAN - 2025', value: '2025-01-01', colorOp: 'white' },
    { label: 'FEV - 2025', value: '2025-02-01', colorOp: 'white' },
    { label: 'MAR - 2025', value: '2025-03-01', colorOp: 'white' },
    { label: 'ABR - 2025', value: '2025-04-01', colorOp: 'white' },
    { label: 'MAI - 2025', value: '2025-05-01', colorOp: 'white' },
    { label: 'JUN - 2025', value: '2025-06-01', colorOp: 'white' },
    { label: 'JUL - 2025', value: '2025-07-01', colorOp: 'white' },
    { label: 'AGO - 2025', value: '2025-08-01', colorOp: 'white' },
    { label: 'SET - 2025', value: '2025-09-01', colorOp: 'white' },
    { label: 'OUT - 2025', value: '2025-10-01', colorOp: 'white' },
    { label: 'NOV - 2025', value: '2025-11-01', colorOp: 'white' },
    { label: 'DEZ - 2025', value: '2025-12-01', colorOp: 'white' },
]


export const ARRAY_CONSUMO = [
    { coluna: 'Consumo baixo', valor: 0 },
    { coluna: 'Consumo médio', valor: 0 },
    { coluna: 'Consumo alto', valor: 0 },
]

export const ARRAY_PROFUNDIDADE = [
    { coluna: 'Profundidade baixo', valor: 0 },
    { coluna: 'Profundidade médio', valor: 0 },
    { coluna: 'Profundidade alto', valor: 0 }
]

export const ARRAY_FERIMENTO = [
    { coluna: 'Ferimento desviado', valor: 0 },
    { coluna: 'Ferimento nao desviado', valor: 0 },
]

export const ARRAY_ASPECOTOS = [
    { coluna: 'Ataque geratrizes', valor: 0 },
    { coluna: 'Declividade corte', valor: 0 },
    { coluna: 'Conformidade equipamentos', valor: 0 },
    { coluna: 'Impurezas coagulo', valor: 0 },
    { coluna: 'Faca nao amolada', valor: 0 },
    { coluna: 'Arvore sangrada', valor: 0 },
    { coluna: 'Controle fitossanitário', valor: 0 },
    { coluna: 'Latex Perda Producao', valor: 0 },
]


export const ARRAY_MESES_SAFRA_PRODUCAO = [
    {
        idUa: 22,
        idSafra: 20,
        idPessoa: null,
        nomePessoa: null,
        totalArvoresSangradasString: "0",
        totalDistribuicaoString: "100",
        totalPrevistoString: "0",
        totalRealizadoString: "0",
        totalPercentualString: "0",
        coaguloPrevisto: "0",
        coaguloRealizado: "0",
        objetivoAlcancado: "0",
        saldoAcumulado: "0",
        listMeses: [
            {
                mes: "SETEMBRO",
                distribuicao: "0",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "OUTUBRO",
                distribuicao: "3,8",
                arvoresSangradasString: "0",
                previstoString: "",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "NOVEMBRO",
                distribuicao: "5,8",
                arvoresSangradasString: "0",
                previstoString: "",
                realizadoString: "",
                percentualString: ""
            },
            {
                mes: "DEZEMBRO",
                distribuicao: "5,8",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "JANEIRO",
                distribuicao: "8,3",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "FEVEREIRO",
                distribuicao: "10",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "MARCO",
                distribuicao: "11,7",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "ABRIL",
                distribuicao: "14,6",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "MAIO",
                distribuicao: "14,6",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "JUNHO",
                distribuicao: "13,4",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "JULHO",
                distribuicao: "10,9",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            },
            {
                mes: "AGOSTO",
                distribuicao: "1",
                arvoresSangradasString: "0",
                previstoString: "0",
                realizadoString: "0",
                percentualString: "0"
            }
        ]
    }
]


export const CALENDARIO_TAREFA222 = {
    idUa: 22,
    idSafra: 20,
    nomeFazenda: 'Vó Palma',
    listaDadosSeringueiros: [
        {
            idPessoa: 75,
            nomeSeringueiro: 'Fulano',
            clone: 'PRIM600',
            qtdArvores: '7485',
            frequencia: 'D4',
            painel: '1/S2',
            listTarefas: [
                {
                    letra: 'A',
                    listSemanasAno: [
                        { idSemanaAno: 1, nrSemana: 1, statusPeriodo: '1', dtSelecionada: '2023-01-02', },
                        { idSemanaAno: 2, nrSemana: 2, statusPeriodo: '2', dtSelecionada: '2023-01-11', },
                        { idSemanaAno: 3, nrSemana: 3, statusPeriodo: '0', dtSelecionada: '2023-01-16', },
                    ],
                },
                {
                    letra: 'B',
                    listSemanasAno: [
                        { idSemanaAno: 4, nrSemana: 1, statusPeriodo: '1', dtSelecionada: '2023-01-03', },
                        { idSemanaAno: 5, nrSemana: 2, statusPeriodo: '2', dtSelecionada: '2023-01-12', },
                        { idSemanaAno: 6, nrSemana: 3, statusPeriodo: '0', dtSelecionada: '2023-01-17', },
                    ],
                },
                {
                    letra: 'C',
                    listSemanasAno: [
                        { idSemanaAno: 7, nrSemana: 1, statusPeriodo: '1', dtSelecionada: '2023-01-04', },
                        { idSemanaAno: 8, nrSemana: 2, statusPeriodo: '2', dtSelecionada: '2023-01-13', },
                        { idSemanaAno: 9, nrSemana: 3, statusPeriodo: '2', dtSelecionada: '2023-01-18', },
                    ],
                },
                {
                    letra: 'D',
                    listSemanasAno: [
                        { idSemanaAno: 10, nrSemana: 1, statusPeriodo: '1', dtSelecionada: '2023-01-05', },
                        { idSemanaAno: 11, nrSemana: 2, statusPeriodo: '2', dtSelecionada: '2023-01-14', },
                        { idSemanaAno: 12, nrSemana: 4, statusPeriodo: '1', dtSelecionada: '2023-01-24', },
                    ],
                },
            ],
        },
        {
            idPessoa: 76,
            nomeSeringueiro: 'BELTRANO',
            clone: 'PRIM800',
            qtdArvores: '9000',
            frequencia: 'D4',
            painel: '1/S2',
            listTarefas: [
                {
                    letra: 'A',
                    listSemanasAno: [
                        { idSemanaAno: 1, nrSemana: 1, statusPeriodo: '1', dtSelecionada: '2023-01-02', },
                        { idSemanaAno: 2, nrSemana: 2, statusPeriodo: '2', dtSelecionada: '2023-01-11', },
                        { idSemanaAno: 3, nrSemana: 3, statusPeriodo: '0', dtSelecionada: '2023-01-16', },
                    ],
                },
                {
                    letra: 'B',
                    listSemanasAno: [
                        { idSemanaAno: 4, nrSemana: 1, statusPeriodo: '1', dtSelecionada: '2023-01-03', },
                        { idSemanaAno: 5, nrSemana: 2, statusPeriodo: '2', dtSelecionada: '2023-01-12', },
                        { idSemanaAno: 6, nrSemana: 3, statusPeriodo: '0', dtSelecionada: '2023-01-17', },
                    ],
                },
                {
                    letra: 'C',
                    listSemanasAno: [
                        { idSemanaAno: 7, nrSemana: 1, statusPeriodo: '1', dtSelecionada: '2023-01-04', },
                        { idSemanaAno: 8, nrSemana: 2, statusPeriodo: '2', dtSelecionada: '2023-01-13', },
                        { idSemanaAno: 9, nrSemana: 3, statusPeriodo: '2', dtSelecionada: '2023-01-18', },
                        { idSemanaAno: 10, nrSemana: 4, statusPeriodo: '2', dtSelecionada: '2023-01-25', },
                        { idSemanaAno: 11, nrSemana: 5, statusPeriodo: '2', dtSelecionada: '2023-02-02', },
                    ],
                },
                {
                    letra: 'D',
                    listSemanasAno: [
                        { idSemanaAno: 12, nrSemana: 1, statusPeriodo: '1', dtSelecionada: '2023-01-05', },
                        { idSemanaAno: 13, nrSemana: 2, statusPeriodo: '2', dtSelecionada: '2023-01-14', },
                        { idSemanaAno: 14, nrSemana: 4, statusPeriodo: '1', dtSelecionada: '2023-01-24', },
                    ],
                },
            ],
        },
        {
            idPessoa: 77,
            nomeSeringueiro: 'ALECANDRO',
            clone: 'PRIM800',
            qtdArvores: '9000',
            frequencia: 'D4',
            painel: '1/S2',
            listTarefas: [
                {
                    letra: 'A',
                    listSemanasAno: [],
                },
                {
                    letra: 'B',
                    listSemanasAno: [],
                },
                {
                    letra: 'C',
                    listSemanasAno: [],
                },
                {
                    letra: 'D',
                    listSemanasAno: [],
                },
            ],
        },
    ]
}

// export const SEMANAS_NO_ANO = [
//     { nrSemana: 35, dtInicioSemana: '2023-08-28', dtFimSemana: '2023-09-02', statusPeriodo: '0' },
//     { nrSemana: 36, dtInicioSemana: '2023-09-04', dtFimSemana: '2023-09-09', statusPeriodo: '0' },
//     { nrSemana: 37, dtInicioSemana: '2023-09-11', dtFimSemana: '2023-09-16', statusPeriodo: '0' },
//     { nrSemana: 38, dtInicioSemana: '2023-09-18', dtFimSemana: '2023-09-23', statusPeriodo: '0' },
//     { nrSemana: 39, dtInicioSemana: '2023-09-25', dtFimSemana: '2023-09-30', statusPeriodo: '0' },
//     { nrSemana: 40, dtInicioSemana: '2023-10-02', dtFimSemana: '2023-10-07', statusPeriodo: '0' },
//     { nrSemana: 41, dtInicioSemana: '2023-10-09', dtFimSemana: '2023-10-14', statusPeriodo: '0' },
//     { nrSemana: 42, dtInicioSemana: '2023-10-16', dtFimSemana: '2023-10-21', statusPeriodo: '0' },
//     { nrSemana: 43, dtInicioSemana: '2023-10-23', dtFimSemana: '2023-10-28', statusPeriodo: '0' },
//     { nrSemana: 44, dtInicioSemana: '2023-10-30', dtFimSemana: '2023-11-04', statusPeriodo: '0' },
//     { nrSemana: 45, dtInicioSemana: '2023-11-06', dtFimSemana: '2023-11-11', statusPeriodo: '0' },
//     { nrSemana: 46, dtInicioSemana: '2023-11-13', dtFimSemana: '2023-11-18', statusPeriodo: '0' },
//     { nrSemana: 47, dtInicioSemana: '2023-11-20', dtFimSemana: '2023-11-25', statusPeriodo: '0' },
//     { nrSemana: 48, dtInicioSemana: '2023-11-27', dtFimSemana: '2023-12-02', statusPeriodo: '0' },
//     { nrSemana: 49, dtInicioSemana: '2023-12-04', dtFimSemana: '2023-12-09', statusPeriodo: '0' },
//     { nrSemana: 50, dtInicioSemana: '2023-12-11', dtFimSemana: '2023-12-16', statusPeriodo: '0' },
//     { nrSemana: 51, dtInicioSemana: '2023-12-18', dtFimSemana: '2023-12-23', statusPeriodo: '0' },
//     { nrSemana: 52, dtInicioSemana: '2023-12-25', dtFimSemana: '2023-12-30', statusPeriodo: '0' },
//     { nrSemana: 1, dtInicioSemana: '2024-01-01', dtFimSemana: '2024-01-06', statusPeriodo: '1' },
//     { nrSemana: 2, dtInicioSemana: '2024-01-08', dtFimSemana: '2024-01-13', statusPeriodo: '1' },
//     { nrSemana: 3, dtInicioSemana: '2024-01-15', dtFimSemana: '2024-01-20', statusPeriodo: '0' },
//     { nrSemana: 4, dtInicioSemana: '2024-01-22', dtFimSemana: '2024-01-27', statusPeriodo: '2' },
//     { nrSemana: 5, dtInicioSemana: '2024-01-29', dtFimSemana: '2024-02-03', statusPeriodo: '0' },
//     { nrSemana: 6, dtInicioSemana: '2024-02-05', dtFimSemana: '2024-02-10', statusPeriodo: '0' },
//     { nrSemana: 7, dtInicioSemana: '2024-02-12', dtFimSemana: '2024-02-17', statusPeriodo: '0' },
//     { nrSemana: 8, dtInicioSemana: '2024-02-19', dtFimSemana: '2024-02-24', statusPeriodo: '0' },
//     { nrSemana: 9, dtInicioSemana: '2024-02-26', dtFimSemana: '2024-03-03', statusPeriodo: '0' },
//     { nrSemana: 10, dtInicioSemana: '2024-03-04', dtFimSemana: '2024-03-09', statusPeriodo: '0' },
//     { nrSemana: 11, dtInicioSemana: '2024-03-11', dtFimSemana: '2024-03-16', statusPeriodo: '0' },
//     { nrSemana: 12, dtInicioSemana: '2024-03-18', dtFimSemana: '2024-03-23', statusPeriodo: '0' },
//     { nrSemana: 13, dtInicioSemana: '2024-03-25', dtFimSemana: '2024-03-30', statusPeriodo: '0' },
//     { nrSemana: 14, dtInicioSemana: '2024-04-01', dtFimSemana: '2024-04-06', statusPeriodo: '0' },
//     { nrSemana: 15, dtInicioSemana: '2024-04-08', dtFimSemana: '2024-04-13', statusPeriodo: '0' },
//     { nrSemana: 16, dtInicioSemana: '2024-04-15', dtFimSemana: '2024-04-20', statusPeriodo: '0' },
//     { nrSemana: 17, dtInicioSemana: '2024-04-22', dtFimSemana: '2024-04-27', statusPeriodo: '0' },
//     { nrSemana: 18, dtInicioSemana: '2024-04-29', dtFimSemana: '2024-05-04', statusPeriodo: '0' },
//     { nrSemana: 19, dtInicioSemana: '2024-05-06', dtFimSemana: '2024-05-11', statusPeriodo: '0' },
//     { nrSemana: 20, dtInicioSemana: '2024-05-13', dtFimSemana: '2024-05-18', statusPeriodo: '0' },
//     { nrSemana: 21, dtInicioSemana: '2024-05-20', dtFimSemana: '2024-05-25', statusPeriodo: '0' },
//     { nrSemana: 22, dtInicioSemana: '2024-05-27', dtFimSemana: '2024-06-01', statusPeriodo: '0' },
//     { nrSemana: 23, dtInicioSemana: '2024-06-03', dtFimSemana: '2024-06-08', statusPeriodo: '0' },
//     { nrSemana: 24, dtInicioSemana: '2024-06-10', dtFimSemana: '2024-06-15', statusPeriodo: '0' },
//     { nrSemana: 25, dtInicioSemana: '2024-06-17', dtFimSemana: '2024-06-22', statusPeriodo: '0' },
//     { nrSemana: 26, dtInicioSemana: '2024-06-24', dtFimSemana: '2024-06-29', statusPeriodo: '0' },
//     { nrSemana: 27, dtInicioSemana: '2024-07-01', dtFimSemana: '2024-07-06', statusPeriodo: '0' },
//     { nrSemana: 28, dtInicioSemana: '2024-07-08', dtFimSemana: '2024-07-13', statusPeriodo: '0' },
//     { nrSemana: 29, dtInicioSemana: '2024-07-15', dtFimSemana: '2024-07-20', statusPeriodo: '0' },
//     { nrSemana: 30, dtInicioSemana: '2024-07-22', dtFimSemana: '2024-07-27', statusPeriodo: '0' },
//     { nrSemana: 31, dtInicioSemana: '2024-07-29', dtFimSemana: '2024-08-03', statusPeriodo: '0' },
//     { nrSemana: 32, dtInicioSemana: '2024-08-05', dtFimSemana: '2024-08-10', statusPeriodo: '0' },
//     { nrSemana: 33, dtInicioSemana: '2024-08-12', dtFimSemana: '2024-08-17', statusPeriodo: '0' },
//     { nrSemana: 34, dtInicioSemana: '2024-08-19', dtFimSemana: '2024-08-24', statusPeriodo: '0' },
// 	{ nrSemana: 34, dtInicioSemana: '2024-08-26', dtFimSemana: '2024-08-31', statusPeriodo: '0' },
// ]

export const LIST_ACUMULADO_SAFRA = [
    { id: 1, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Claudecir e Luciene' },
    { id: 2, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'José Eduardo e Evania' },
    { id: 3, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Jailton e Patricia' },
    { id: 4, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Felipe e Michele' },
    { id: 5, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Benedito e Ana Paula' },
    // {id: 6, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Luiz' },
    // {id: 7, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Jessica' },
    // {id: 8, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Raylan' },
    // {id: 9, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Elizabet' },
    // {id: 10, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Esmeralda' },
    // {id: 11, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Regiane' },
    // {id: 12, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Ivone' },
    // {id: 13, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Cinthia' },
    // {id: 14, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Eliedna' },
    // {id: 15, bloco: '1', clone: 'PRIM600', quantidadeArvores: '7485', painel: '1/S2', previsto: '22498', realizado: '25762', realizadoPercent: '115', seringueiro: 'Valeria' },
]

export const CHUVA_EM_MM = [
    { id: 1, mm: 5, data: '2022-08-01', diaSemana: 'T' },
    { id: 2, mm: 10, data: '2022-08-02', diaSemana: 'Q' },
    { id: 3, mm: 15, data: '2022-08-03', diaSemana: 'Q' },
    { id: 4, mm: 20, data: '2022-08-04', diaSemana: 'S' },
    { id: 5, mm: 25, data: '2022-08-05', diaSemana: 'S' },
    { id: 6, mm: 30, data: '2022-08-06', diaSemana: 'D' },
    { id: 7, mm: 35, data: '2022-08-07', diaSemana: 'S' },
    { id: 8, mm: 40, data: '2022-08-08', diaSemana: 'T' },
    { id: 9, mm: 45, data: '2022-08-09', diaSemana: 'Q' },
    { id: 10, mm: 50, data: '2022-08-10', diaSemana: 'Q' },
    { id: 11, mm: 5, data: '2022-08-11', diaSemana: 'S' },
    { id: 12, mm: 10, data: '2022-08-12', diaSemana: 'S' },
    { id: 13, mm: 15, data: '2022-08-13', diaSemana: 'D' },
    { id: 14, mm: 20, data: '2022-08-14', diaSemana: 'S' },
    { id: 15, mm: 25, data: '2022-08-15', diaSemana: 'T' },
    { id: 16, mm: 30, data: '2022-08-16', diaSemana: 'Q' },
    { id: 17, mm: 35, data: '2022-08-17', diaSemana: 'Q' },
    { id: 18, mm: 40, data: '2022-08-18', diaSemana: 'S' },
    { id: 19, mm: 45, data: '2022-08-19', diaSemana: 'S' },
    { id: 20, mm: 50, data: '2022-08-20', diaSemana: 'D' },
    { id: 21, mm: 5, data: '2022-08-21', diaSemana: 'S' },
    { id: 22, mm: 10, data: '2022-08-22', diaSemana: 'T' },
    { id: 23, mm: 15, data: '2022-08-23', diaSemana: 'Q' },
    { id: 24, mm: 20, data: '2022-08-24', diaSemana: 'Q' },
    { id: 25, mm: 25, data: '2022-08-25', diaSemana: 'S' },
    { id: 26, mm: 30, data: '2022-08-26', diaSemana: 'S' },
    { id: 27, mm: 35, data: '2022-08-27', diaSemana: 'D' },
    { id: 28, mm: 40, data: '2022-08-28', diaSemana: 'S' },
    { id: 29, mm: 45, data: '2022-08-29', diaSemana: 'T' },
    { id: 30, mm: 50, data: '2022-08-30', diaSemana: 'Q' },
    { id: 31, mm: 25, data: '2022-08-31', diaSemana: 'Q' },
]


export const ARRAY_CHUVA_MM = [
    { value: 10, dia: '01', descDia: 'SEG' },
    { value: 1, dia: '02', descDia: 'TER' },
    { value: 5, dia: '03', descDia: 'QUA' },
    { value: 25, dia: '04', descDia: 'QUI' },
    { value: 30, dia: '05', descDia: 'SEX' },
    { value: 5, dia: '06', descDia: 'SAB' },
    { value: 10, dia: '07', descDia: 'DOM' },
]



export const HISTORICO_ANUAL = [
    { mes: 'Janeiro', minima: '21', maxima: '29', chuvaMn: '292' },
    { mes: 'Fevereiro', minima: '21', maxima: '30', chuvaMn: '226' },
    { mes: 'Março', minima: '21', maxima: '29', chuvaMn: '214' },
    { mes: 'Abril', minima: '20', maxima: '29', chuvaMn: '95' },
    { mes: 'Maio', minima: '17', maxima: '27', chuvaMn: '45' },
    { mes: 'Junho', minima: '16', maxima: '27', chuvaMn: '18' },
    { mes: 'Julho', minima: '16', maxima: '28', chuvaMn: '14' },
    { mes: 'Agosto', minima: '18', maxima: '30', chuvaMn: '21' },
    { mes: 'Setembro', minima: '19', maxima: '31', chuvaMn: '69' },
    { mes: 'Outubro', minima: '20', maxima: '31', chuvaMn: '143' },
    { mes: 'Novembro', minima: '21', maxima: '30', chuvaMn: '196' },
    { mes: 'Dezembro', minima: '21', maxima: '29', chuvaMn: '282' },
]

export const NOTIFICACAO = [
    {
        idEstimulacao: 1,
        idNotificacao: 1,
        idUa: 22,
        idSafra: 20,
        idUsuario: 15,
        idTipoNotificacao: 1,
        statusNotificacao: false,
        dtCriacao: '2023-09-03',
        descricao: 'Notificação Teste',
    },
]

export const ASSUNTO = [
    { value: '1', label: 'Controle de Plantas Daninhas' },
    { value: '2', label: 'Controle de Qualidade' },
    { value: '3', label: 'Sangria' },
    { value: '4', label: 'Controle de Pragas' },
    { value: '5', label: 'Controle de Doenças' },
    { value: '6', label: 'Estimulação' },
    { value: '7', label: 'Adubação' },
    { value: '8', label: 'Replantio' },
    { value: '9', label: 'Desbrota' },
    { value: '10', label: 'Analise de Solo' },
    { value: '11', label: 'Fungicida de Painel' },
    { value: '12', label: 'Doenças de Painel' },
    { value: '13', label: 'Correção do Solo' },
]

export const ARRAY_INICIAL_CALENDARIO_ESTIMULACAO = {         // ESSE CARA TEM QUE PASSAR PRIMEIRO NO FlashList PARA ATUALIZAR O NUMERO DE LINHAS
    idUa: 31,
    idSafra: 29,
    idPessoa: 111,
    nomeSeringueiro: 'Seringueiro D5',
    clone: 'RRIM600',
    qtdArvores: 5000,
    tamanhoLote: 0,
    frequencia: 'D5',
    painel: '1/4S↓',
    listSemana: [
        {
            numeroSemana: 35,
            dtInicioSemana: '2023-08-28',
            dtFimSemana: '2023-09-02',
            listTarefas: [
                {
                    letra: 'A',
                    statusPeriodo: '',
                    descStatus: '',
                    idEstimulacao: 0,
                    descDtSelecionada: '',
                    dtSelecionada: null
                },
                {
                    letra: 'B',
                    statusPeriodo: '',
                    descStatus: '',
                    idEstimulacao: 0,
                    descDtSelecionada: '',
                    dtSelecionada: null
                },
                {
                    letra: 'C',
                    statusPeriodo: '',
                    descStatus: '',
                    idEstimulacao: 0,
                    descDtSelecionada: '',
                    dtSelecionada: null
                },
                {
                    letra: 'D',
                    statusPeriodo: '',
                    descStatus: '',
                    idEstimulacao: 0,
                    descDtSelecionada: '',
                    dtSelecionada: null
                },
                {
                    letra: 'E',
                    statusPeriodo: '',
                    descStatus: '',
                    idEstimulacao: 0,
                    descDtSelecionada: '',
                    dtSelecionada: null
                },
                {
                    letra: 'F',
                    statusPeriodo: '',
                    descStatus: '',
                    idEstimulacao: 0,
                    descDtSelecionada: '',
                    dtSelecionada: null
                },
                {
                    letra: 'G',
                    statusPeriodo: '',
                    descStatus: '',
                    idEstimulacao: 0,
                    descDtSelecionada: '',
                    dtSelecionada: null
                },
            ]
        },
    ],
}
