import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Text,
    Box,
    Flex,
} from "@chakra-ui/react";
import * as ActionTypes from '../../constants/ActionTypes';

function ModalDialogDeleteUa({ isOpen, onPressOk, onPressCancel, tipoDelete = '1' }) {

    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';

    let texto1 = '';
    let texto2 = '';
    let texto3 = '';
    let texto4 = '';

    if (tipoDelete == ActionTypes.TIPO_DELETE_UM_SERINGUEIRO) {
        texto1 = 'Ao excluir o seringueiro ocorrerá as seguintes ações:';
        texto2 = 'As atividades do seringueiro serão excluídos para essa safra.';
        texto3 = 'Após esse processo o seringueiro estará disponível no sistema podendo ser incluídos em nova safra.';
        texto4 = 'As atividades dos seringueiro em outras safras não serão afetadas.';

    } else if (tipoDelete == ActionTypes.TIPO_DELETE_UMA_SAFRA) {
        texto1 = 'Ao excluir a safra ocorrerá as seguintes ações:';
        texto2 = 'Com a exclusão da safra as atividades de todos os seringueiros serão excluídas para essa safra.';
        texto3 = 'Após esse processo os seringueiros continuarão disponíveis no sistema podendo ser incluídos em novas safras.';
        texto4 = 'As atividades dos seringueiros em outras propriedades não serão afetadas.';

    } else if (tipoDelete == ActionTypes.TIPO_DELETE_UMA_PROPRIEDADE) {
        texto1 = 'Ao excluir a propriedade ocorrerá as seguintes ações:';
        texto2 = 'Todos os seringueiros e safras dessa propriedade serão excluídas.';
        texto3 = 'Após esse processo os seringueiros estarão disponíveis no sistema podendo ser incluídos em novas safras.';
        texto4 = 'As atividades dos seringueiros em outras propriedades não serão afetadas.';
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE} >
                <ModalHeader justifyContent={'center'} alignItems={'center'} py={'10px'}>
                    <Text color={'red'} fontSize={'20px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Atenção'}</Text>
                </ModalHeader>
                <ModalBody>
                    <Text color={'black'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{texto1}</Text>
                    <Box pl={'10px'} mt={'20px'}>
                        <Flex justify='start' align='start'>
                            <Text color={'gray.500'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{'-'}</Text>
                            <Text color={'gray.500'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{texto2}</Text>
                        </Flex>
                        <Flex mt={'10px'} justify='start' align='start'>
                            <Text color={'gray.500'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{'-'}</Text>
                            <Text color={'gray.500'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{texto3}</Text>
                        </Flex>
                        {texto4 != '' &&
                            <Flex mt={'10px'} justify='start' align='start'>
                                <Text color={'gray.500'} fontSize={'18px'} fontWeight={'bold'} lineHeight={'100%'}>{'-'}</Text>
                                <Text color={'gray.500'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'} ml={'5px'}>{texto4}</Text>
                            </Flex>
                        }
                    </Box>
                    <Text mt={'20px'} color={'black'} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>{'Deseja realmente excluir?'}</Text>
                </ModalBody>
                <ModalFooter mt={'20px'} borderTopWidth={'1px'} borderColor={'blackAlpha.700'} justifyContent={'space-around'}>
                    <Button w={'100px'} variant="action" onClick={onPressCancel}>Cancelar</Button>
                    <Button w={'100px'} variant="redBrand" onClick={onPressOk}>excluir</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalDialogDeleteUa;