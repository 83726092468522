import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, useColorModeValue, Accordion, AccordionItem, AccordionIcon, AccordionButton, AccordionPanel } from "@chakra-ui/react";
import * as ActionTypes from '../../../constants/ActionTypes';

export function SidebarLinks(props) {

    let location = useLocation();
    let inactiveColor = useColorModeValue("navy.100", "navy.100");
    let activeIcon = useColorModeValue("white", "white");
    let activeColor = useColorModeValue("white", "white");
    let textColor = useColorModeValue("gray.200", "white");
    const activeBackgroundColor = "rgba(128, 43, 0, 0.9)";

    const { routes } = props;

    const activeRoute = (routeName) => {
        return location.pathname.includes(routeName);
    };

    const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
    const objPermissoes = JSON.parse(val);

    // ESTA FUNCAO CRIA OS LINKS DOS ACORDEOES SECUNDARIOS (POR EXEMPLO AUTH -> SIGN-IN -> DEFAULT)
    // TODO MENU LATERAL 
    const createLinks = (routes) => {

        const count = routes.length;
        const xx = routes.filter(x => x.visible !== false)
        const alturaItens = '5px';
        
        let arrRoutes = null;

        if (objPermissoes.admin) {
            arrRoutes = xx;
        } else if (objPermissoes.tecnico) {
            arrRoutes = xx.filter(x => 
                x.perfilAcesso != ActionTypes.PERFIL_ADMIN);
        } else if (objPermissoes.produtor) {
            arrRoutes = xx.filter(x => 
                x.perfilAcesso != ActionTypes.PERFIL_ADMIN && 
                x.perfilAcesso != ActionTypes.PERFIL_TECNICO);
        } else if (objPermissoes.gerente) {
            arrRoutes = xx.filter(x => 
                x.perfilAcesso != ActionTypes.PERFIL_PRODUTOR && 
                x.perfilAcesso != ActionTypes.PERFIL_ADMIN && 
                x.perfilAcesso != ActionTypes.PERFIL_TECNICO);
        } else if (objPermissoes.seringueiro) {
            arrRoutes = xx.filter(x => 
                x.perfilAcesso != ActionTypes.PERFIL_GERENTE && 
                x.perfilAcesso != ActionTypes.PERFIL_PRODUTOR && 
                x.perfilAcesso != ActionTypes.PERFIL_ADMIN && 
                x.perfilAcesso != ActionTypes.PERFIL_TECNICO);
        } else {
            arrRoutes = xx;
        }

        return arrRoutes.map((route, index) => {
            if (route.category) {
                return (
                    <>
                        <Text
                            fontSize={"md"}
                            color={activeColor}
                            fontWeight='bold'
                            mx='auto'
                            ps={{ sm: "10px", xl: "16px", }}
                            pt='18px'
                            pb='12px'
                            key={index}>
                            {route.name}
                        </Text>
                        {createLinks(route.items)}
                    </>
                );
            } else if (
                route.layout === "/admin" ||
                route.layout === "/auth" ||
                route.layout === "/report"
            ) {
                return (
                    <Box key={index}>
                        {route.subItens.length > 0 ? (
                            <Accordion allowToggle borderColor={'transparent'}>
                                <AccordionItem borderWidth={'0px'}>
                                    <h2>
                                        <AccordionButton
                                            _focus={{ boxShadow: 'none' }}
                                            _hover={{ bg: 'transparent' }}
                                            ml={'0'}
                                            justifyContent={'space-between'}
                                        >
                                            <Box color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor} ml={'-2'}>
                                                <Box as="span" pt={'8px'} w={'25px'} h={'30px'}>{route.icon}</Box>
                                                <Box as="span" flex='1' ml={'10px'} >{route.name}</Box>
                                            </Box>
                                            <AccordionIcon color={activeColor} />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={0}>
                                        {route.subItens.map((item, key) => {
                                            return (
                                                <NavLink key={index + count + key} to={item.layout + item.path} >
                                                    {item.icon ? (
                                                        <Box mt={'-5px'}> 
                                                            <HStack
                                                                spacing={activeRoute(item.path.toLowerCase()) ? "22px" : "26px"}
                                                                py='2px'
                                                                ps='10px'    // MARGEM DOS ICONES DE NAVEGACAO MENU LATERAL
                                                                mb={'4px'}>
                                                                <Flex w='100%' alignItems='start' justifyContent='start' bg={activeRoute(item.path.toLowerCase()) ? activeBackgroundColor : 'transparent'} borderRadius={'5px'}>
                                                                    <Text
                                                                        ml={'10px'}
                                                                        py={'3px'}
                                                                        fontSize={15}
                                                                        as='i'
                                                                        opacity={'0.7'}
                                                                        color={activeRoute(item.path.toLowerCase()) ? "#FFFFFF" : '#FFFFFF'}
                                                                        fontWeight={activeRoute(item.path.toLowerCase()) ? "bold" : "normal"}>
                                                                        {item.name}
                                                                    </Text>
                                                                </Flex>
                                                            </HStack>
                                                        </Box>
                                                    ) : (
                                                        <Box>
                                                            <HStack
                                                                spacing={activeRoute(item.path.toLowerCase()) ? "22px" : "26px"} 
                                                                //py={alturaItens}
                                                                py={'5px'}
                                                                ps='10px'>
                                                                <Text
                                                                    me='auto'
                                                                    color={activeRoute(item.path.toLowerCase()) ? activeColor : inactiveColor}
                                                                    fontWeight={activeRoute(item.path.toLowerCase()) ? "bold" : "normal"}>
                                                                    {item.name}
                                                                </Text>
                                                                <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                                                            </HStack>
                                                        </Box>
                                                    )}
                                                </NavLink>
                                            )
                                        })}
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        ) : (
                            <NavLink key={index} to={route.layout + route.path}>
                                {route.icon ? (
                                    <Box>
                                        <HStack
                                            spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
                                            ps='3px'    // MARGEM DOS ICONES DE NAVEGACAO MENU LATERAL
                                            py='2px'>
                                            <Flex w='100%' alignItems='center' justifyContent='center' bg={activeRoute(route.path.toLowerCase()) ? activeBackgroundColor : 'transparent'} borderRadius={'5px'}>
                                                <Box color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor} 
                                                me='8px' 
                                                pt={'5px'} 
                                                ml={'2'}>
                                                    {route.icon}
                                                </Box>
                                                <Text
                                                    py={'5px'}
                                                    me='auto'
                                                    color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
                                                    fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                                                    {route.name}
                                                </Text>
                                            </Flex>
                                        </HStack>
                                    </Box>
                                ) : (
                                    <Box>
                                        <HStack
                                            spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"} 
                                            py='5px' 
                                            ps='10px'>
                                            <Text
                                                me='auto'
                                                color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
                                                fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                                                {route.name}
                                            </Text>
                                            <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                                        </HStack>
                                    </Box>
                                )}
                            </NavLink>
                        )}
                    </Box>
                );
            }
        });
    };
    //  BRAND
    return createLinks(routes);
}

export default SidebarLinks;
