import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    TableContainer,
    Select,
    Box,
    Center,
    SimpleGrid,
    Icon,
    Wrap,
    WrapItem,
    Button,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import moment from 'moment';
import ReactApexChart from "react-apexcharts";
import MiniStatistics from "components/card/MiniStatistics";
import { ARRAY_MESES_SAFRA_PRODUCAO } from "model/MockData";
import { CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO, RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA } from '../../../util/CalculoRelatorioSangriaAndProducao';
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import { AiOutlineMinusSquare, AiFillPlusSquare } from "react-icons/ai";
import Loader from 'components/loading/Loader';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import MessageSreen from 'components/message/MessageSreen';
import { ARRAY_MESES } from 'model/MockData';

export default function GraficoProducaoDeCoagulos() {

    const [loading, setLoading] = useState(false);

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [extratoProducao, setExtratoProducao] = useState([]);
    const [arrayProducaoCompleto, setarrayProducaoCompleto] = useState([]);
    const [totalPrevisto, setTotalPrevisto] = useState(0);
    const [totalRealizado, setTotalRealizado] = useState(0);
    const [totalPercentual, setTotalPercentual] = useState(0);
    const [coaguloPrevisto, setCoaguloPrevisto] = useState(0);
    const [coaguloRealizado, setCoaguloRealizado] = useState(0);
    const [objetivoAlcancado, setObjetivoAlcancado] = useState(0);
    const [saldoAcumulado, setSaldoAcumulado] = useState(0);

    const [porcentagemRealizada, setPorcentagemRealizada] = useState(0);
    const [producaoCoagulosPorArvorePrevista, setProducaoCoagulosPorArvorePrevista] = useState(0);
    const [producaoCoagulosPorArvoreRealizada, setProducaoCoagulosPorArvoreRealizada] = useState(0);
    const [listGraficoRealizado, setListGraficoRealizado] = useState([]);
    const [listGraficoPrevisto, setListGraficoPrevisto] = useState([]);
    const [listGraficoEficiencia, setListGraficoEficiencia] = useState([]);
    const [anoInit, setAnoInit] = useState('');
    const [anoFim, setAnoFim] = useState('');
    const [arrayMeses, setArrayMeses] = useState([]);
    const [valorMinimoGrafico, setValorMinimoGrafico] = useState(200);

    const [arrayMesInicio, setArrayMesInicio] = useState([]);
    const [arrayMesFim, setArrayMesFim] = useState([]);
    const [mesInicioSelecionado, setMesInicioSelecionado] = useState('');
    const [mesFimSelecionado, setMesFimSelecionado] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loadScreen() {
            try {
                window.scrollTo(0, 0);

                setArrayMesInicio(ARRAY_MESES);
                setArrayMesFim(ARRAY_MESES);

                const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
                const fim = localStorage.getItem('@HE-dtFimSafraAsync');
                const aInit = moment(ini).format('YYYY');
                const aFim = moment(fim).format('YYYY');
                setAnoInit(aInit);
                setAnoFim(aFim);

                const initMes = moment(ini).startOf('month').format('YYYY-MM-DD');
                const endDate = moment(fim).startOf('month').format('YYYY-MM-DD');      // NESSE CASO OS DOIS SAO startOf

                setMesInicioSelecionado(initMes);
                setMesFimSelecionado(endDate);

                const ARRAY_MES = await RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(ini, fim);
                setArrayMeses(ARRAY_MES);

                const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
                const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');

                await pesquisar(dtInicioSafra, dtFimSafra, ARRAY_MES);
                setTelaCarregada(true);

            } catch (error) {
                setTelaCarregada(true);
            }
        }
        loadScreen();
    }, []);

    const pesquisar = async (dtInicioP, dtFimP, ARRAY_MES) => {
        try {
            const dtI = moment(dtInicioP).format('YYYY-MM-DD');
            const dtF = moment(dtFimP).format('YYYY-MM-DD');
            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const json = {
                idUa: ua,
                idSafra: idSafra,
                dtInicio: dtI,
                dtFim: dtF,
                arrayMesDataFilter: ARRAY_MES
            }

            const response = await Api.postRequest(`producao/relatorioAcompanhamentoProducao`, json);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;
                const listSeringueiros = res.listSeringueiros;
                const responseProducao = res.responseProducao;

                await carregaDadosSeringueiro(responseProducao.listRelatorioFazenda[0], false);
                setarrayProducaoCompleto(responseProducao);

                if (listSeringueiros.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            listSeringueiros.map(function (item) {
                                const obj = {
                                    label: item.nome,
                                    value: `${item.idPessoa}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSeringueiro(arrayPess);
                }
            } else {
                return;
            }
        } catch (error) {
            console.log('Erro na classe GraficoProducaoDeCoagulos metodo pesquisar', error);
        }
    }

    const pesquisarComDatas = async () => {
        try {
            const primeiroDiaDoMes = moment(mesInicioSelecionado).startOf('month');
            const UltimoDiaDoMes = moment(mesFimSelecionado).endOf('month');

            const duration = moment.duration(UltimoDiaDoMes.diff(primeiroDiaDoMes));
            const resultado = duration.asMonths();
            const meses = parseFloat(resultado.toFixed(0));

            if (meses < 1) {
                setNumStatusResposta(ActionTypes.DATA_INICIO_MAIOR_QUE_DATA_FIM);
                showAlert();
                return;
            } else {
                setIsVisibleAlert(false);
            }

            let startDate = moment(primeiroDiaDoMes);
            let endDate = moment(UltimoDiaDoMes);
            let date = [];

            for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'month')) {
                date.push(m.format('YYYY-MM-DD'));
            }

            setLoading(true);

            let arrayPess = [];
            await new Promise(resolve => {
                resolve(
                    arrayMeses.map((item, index) => {
                        let statusP = false;
                        if (index > 0) {
                            const arrFilter = date.filter(x => x == item.dtMes);
                            statusP = arrFilter.length > 0 ? true : false;
                        }
                        const obj = {
                            dtMes: item.dtMes,
                            mes: item.mes,
                            status: statusP,
                        }
                        arrayPess.push(obj);
                    })
                )
            });

            const dtI = moment(primeiroDiaDoMes).format('YYYY-MM-DD');
            const dtF = moment(UltimoDiaDoMes).format('YYYY-MM-DD');
            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const json = {
                idUa: ua,
                idSafra: idSafra,
                dtInicio: dtI,
                dtFim: dtF,
                arrayMesDataFilter: arrayPess
            }

            const response = await Api.postRequest(`producao/relatorioAcompanhamentoProducao`, json);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setPessoaSelecionado('');
                const res = response.respostaRequisicao.data;
                const responseProducao = res.responseProducao;

                await carregaDadosSeringueiro(responseProducao.listRelatorioFazenda[0], false);
                setarrayProducaoCompleto(responseProducao);

                setLoading(false);
            } else {
                setLoading(false);
                return;
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe GraficoProducaoDeCoagulos metodo pesquisar', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const carregaDadosSeringueiro = async (arraySeringueirosComMeses, seringueiroSelecionadoP = false) => {

        const res = await CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO(arraySeringueirosComMeses, seringueiroSelecionadoP);

        setTotalRealizado(res.totalRealizado);
        setTotalPrevisto(res.totalPrevisto);
        setExtratoProducao(res.arrayProducaoAndGrafico);
        setTotalPercentual(res.totalPercentual);
        setObjetivoAlcancado(res.objetivoAlcancado);        // PORCENTAGEM REALIZADO
        setCoaguloPrevisto(res.coaguloPrevisto);
        setCoaguloRealizado(res.coaguloRealizado);
        setSaldoAcumulado(res.saldoAcumulado);

        setPorcentagemRealizada(res.porcentagemRealizado);
        setProducaoCoagulosPorArvorePrevista(res.producaoCoagulosPorArvorePrevista);
        setProducaoCoagulosPorArvoreRealizada(res.producaoCoagulosPorArvoreRealizada);

        await constroiArrasGrafico(res.arrayProducaoAndGrafico);
    }

    const constroiArrasGrafico = async (arr) => {
        try {
            if (arr.length > 0) {

                let arrqyRealizadas = [];
                let arrqyProvisto = [];
                let arrqyEficiencia = [];
                let arrqyDatas = [];
                await new Promise(resolve => {
                    resolve(
                        arr.map(function (item) {
                            let qtdRealizado = item.realizadoGrafico != '' ? item.realizadoGrafico : 0;
                            let qtdPrevisto = item.previstoGrafico != '' ? item.previstoGrafico : 0;
                            let qtdEficiencia = item.percentual != '' ? item.percentual : 0;

                            const graf = {
                                x: item.data,
                                y: Number(qtdEficiencia)
                            }

                            arrqyRealizadas.push(qtdRealizado);
                            arrqyProvisto.push(qtdPrevisto);
                            arrqyEficiencia.push(Number(qtdEficiencia));
                            arrqyDatas.push(graf);
                        })
                    )
                });

                let pp1 = null;
                let pp2 = null;
                let pp3 = null;

                let arr1 = arrqyRealizadas.map(x => x);
                let arr2 = arrqyProvisto.map(x => x);
                let arr3 = arrqyEficiencia.map(x => x);

                arr1.sort(function (a, b) { return b - a });
                arr2.sort(function (a, b) { return b - a });
                arr3.sort(function (a, b) { return b - a });

                if (arr1[0] == 0) {
                    pp1 = arrqyRealizadas.map(x => '0%');
                } else {
                    pp1 = arrqyRealizadas;
                }
                if (arr2[0] == 0) {
                    pp2 = arrqyProvisto.map(x => '0%');
                } else {
                    pp2 = arrqyProvisto;
                    const max = Number(arr2[0]) > valorMinimoGrafico ? Number(arr2[0]) : valorMinimoGrafico;
                    setValorMinimoGrafico(max);
                }
                if (arr3[0] == 0) {
                    pp3 = [];
                } else {
                    pp3 = arrqyEficiencia;
                }

                setListGraficoRealizado(pp1);
                setListGraficoPrevisto(pp2);
                setListGraficoEficiencia(pp3);
            }

        } catch (error) {
            console.log('Erro na classe GraficoProducaoDeCoagulos metodo constroiArrasGrafico', error);
        }
    }

    const changePessoa = async (e) => {
        try {
            const val = e.target.value;
            setPessoaSelecionado(val);

            if (val != '') {
                const arraySeringueiros = arrayProducaoCompleto.listRelatorioAcompanhamentoProducao;
                const arrFilter = arraySeringueiros.filter(x => x.idPessoa == val);
                const arraySeringueirosComMeses = arrFilter.length > 0 ? arrFilter[0] : ARRAY_MESES_SAFRA_PRODUCAO;
                await carregaDadosSeringueiro(arraySeringueirosComMeses, true);
            } else {
                const arrayFazenda = arrayProducaoCompleto.listRelatorioFazenda;
                const xxx = arrayFazenda.length > 0 ? arrayFazenda[0] : ARRAY_MESES_SAFRA_PRODUCAO;
                await carregaDadosSeringueiro(xxx, false);
            }
        } catch (error) {
            setExtratoProducao(ARRAY_MESES_SAFRA_PRODUCAO[0].listMeses);
            console.log('Erro na classe GraficoProducaoDeCoagulos metodo changePessoa', error);
        }
    };

    const series = [
        {
            name: 'Previsto',
            type: 'column',
            data: listGraficoPrevisto,
        },
        {
            name: 'Realizado',
            type: 'column',
            data: listGraficoRealizado,
        },
        {
            name: '% Realizado',
            type: 'line',
            data: listGraficoEficiencia,
        }
    ]

    const options = {
        chart: {
            height: 'auto',
            type: 'line',
            foreColor: 'black',
            dropShadow: {
                enabled: true,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.3,       // TIPO UMA SOMBRA NO GRAFICO
                color: "#4318FF",
            },
            toolbar: {              // ESSE CARA MOSTRA OU OCULTA OS BOTOES DE DOLWNLOAD, ZOOM, HOME...
                show: false,        // OCULTA OU MOSTRA TODOS AS OPCOES
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            zoom: {
                enabled: false, // Desativa o zoom via scroll do mouse
            },
        },
        colors: ["#00004d", "#0099ff", "#ff0000"],
        stroke: {
            width: [1, 1, 0],       // ESSE CARA MOSTRA A LINHA DO GRAFICO
            curve: 'smooth'         // ARREDONDA ALINHA DO GRAFICO, OPCOES 'smooth', 'straight', 'stepline'
        },
        title: {
            text: ''
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2],
            textAnchor: 'middle',       // start middle end
        },
        xaxis: {
            categories: ["Set", "Out", "Nov", "Dez", "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago"],
            labels: {
                style: {
                    colors: "#4e6097",
                    fontSize: "13px",
                    fontWeight: "500",
                },
            },
        },
        yaxis: [
            {
                show: true,
            },
            {
                show: false,
                seriesName: 'Previsto',
            },
            {
                show: false,
            },
        ],
        fill: {
            type: "gradient",
            colors: ["#00004d", "#0099ff", "#ff0000"],
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '15px',
            fontWeight: 100,
            offsetX: 0,
            offsetY: 10,
            height: 40,
        },
    }

    function renderMeses() {
        return (
            <Wrap spacing='10px' mt={'10px'} justify='flex-start'>
                <WrapItem>
                    <Box w='100%'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Início</Text>
                        <Select
                            value={mesInicioSelecionado}
                            errorBorderColor='crimson'
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => setMesInicioSelecionado(e.target.value)}
                            w={'195px'}
                            h={'35px'}
                        >
                            {arrayMesInicio.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' >
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Fim</Text>
                        <Select
                            value={mesFimSelecionado}
                            errorBorderColor='crimson'
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => setMesFimSelecionado(e.target.value)}
                            w={'195px'}
                            h={'35px'}
                        >
                            {arrayMesFim.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box h='100%'>
                        <Center h={'100%'} p={'5px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                            <TooltipIcon
                                label={
                                    <Box px={'0px'} py={'5px'} >
                                        <Text align={'center'}>{'Campos de Início e Fim'}</Text>
                                        <Text mt={'5px'} lineHeight='100%' align={'start'}>{'Início:'}</Text>
                                        <Text mt={'0px'} lineHeight='100%' align={'start'}>{'Recebe a primeiro dia do mês selecionado'}</Text>
                                        <Text mt={'5px'} lineHeight='100%' align={'start'}>{'Fim:'}</Text>
                                        <Text mt={'0px'} lineHeight='100%' align={'start'}>{'Recebe a último dia do mês selecionado'}</Text>
                                    </Box>
                                }
                                height={'23px'} />
                        </Center>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' h={'100%'}>
                        <Center alignItems={'flex-end'} h={'100%'}>
                            <Button variant="brand" onClick={pesquisarComDatas}>Pesquisar</Button>
                        </Center>
                    </Box>
                </WrapItem>
            </Wrap>
        )
    }

    function renderSelect() {
        return (
            <Box w={'100%'} py={'10px'}>
                <Flex flexDirection={'column'} justify='center' align='start' >
                    <Text ml={'10px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Seringueiros</Text>
                    <Select
                        value={pessoaSelecionado}
                        errorBorderColor='crimson'
                        placeholder="Todos"
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={changePessoa}
                        w={'400px'}
                        h={'35px'}
                    >
                        {listSeringueiro.map((item, index) => {
                            return (
                                <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Flex>
            </Box>
        )
    }

    function renderProducaoPrimeirosMeses() {
        return (
            <TableContainer>
                <Table size='sm' variant='striped' colorScheme='blackAlpha' >
                    <Thead px={'3px'} bg={'gray.700'} borderRadius={'10px'}>
                        <Tr h={'35px'}>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text align={'start'} color={textColorHeader} fontSize='14px' >Ano</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Árvores'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Sangradas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Distribuição'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Sazonal'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Produção'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Prevista (kg)'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Produção'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Realizado (kg)'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Porcentagem'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Realizada'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {extratoProducao.map((item, key) => {
                            if (item.mes == 'JANEIRO' || item.mes == 'FEVEREIRO' || item.mes == 'MARCO' || item.mes == 'ABRIL' || item.mes == 'MAIO' || item.mes == 'JUNHO' || item.mes == 'JULHO' || item.mes == 'AGOSTO') {
                                return (
                                    <Tr key={key + 1}>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700'>{item.mes}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{item.arvoresSangradas}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{`${item.distribuicao}%`}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{item.previsto}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{item.realizado}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{`${item.percentual}%`}</Text>
                                        </Td>
                                    </Tr>
                                )
                            }
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderProducaoUltimosMeses() {
        return (
            <TableContainer>
                <Table size='sm' variant='striped' colorScheme='blackAlpha' >
                    <Thead px={'3px'} bg={'gray.700'} borderRadius={'10px'}>
                        <Tr h={'35px'}>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Text align={'start'} color={textColorHeader} fontSize='14px' >Ano</Text>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'} >
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Árvores'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Sangradas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Distribuição'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Sazonal'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Produção'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Prevista (kg)'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Produção'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Realizado (kg)'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th>
                                <Box w='100%' textTransform={'none'}>
                                    <Center flexDirection={'column'}>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Porcentagem'}</Text>
                                        <Text color={textColorHeader} fontSize='14px' align={'center'} >{'Realizada'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {extratoProducao.map((item, key) => {
                            if (item.mes == 'SETEMBRO' || item.mes == 'OUTUBRO' || item.mes == 'NOVEMBRO' || item.mes == 'DEZEMBRO') {
                                return (
                                    <Tr key={key + 1}>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700'>{item.mes}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{item.arvoresSangradas}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{`${item.distribuicao}%`}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{item.previsto}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{item.realizado}</Text>
                                        </Td>
                                        <Td>
                                            <Text color={textColor} fontSize='14px' fontWeight='700' align={'center'}>{`${item.percentual}%`}</Text>
                                        </Td>
                                    </Tr>
                                )
                            }
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'20px'} py={'10px'} align='center' direction='column' w='100%'>
                {renderAlert()}
                <Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'} >Produção de Coágulos</Text>
                {renderMeses()}
                {renderSelect()}
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }} gap='20px' mb='20px' mt={'15px'}>
                    <MiniStatistics textColorValue={'orange.500'} name='Produção de Coágulos Prevista (kg)' value={totalPrevisto} />
                    <MiniStatistics textColorValue={'green.500'} name='Produção de Coágulos Realizada (kg)' value={totalRealizado} />
                    <MiniStatistics textColorValue={'red.600'} name='Saldo Acumulado (kg)' value={saldoAcumulado} />
                    <MiniStatistics textColorValue={'green.500'} name='Porcentagem Realizado' value={`${porcentagemRealizada}%`} />
                    <MiniStatistics textColorValue={'orange.500'} name='Produção de Coágulos Por Árvore Prevista (kg)' value={producaoCoagulosPorArvorePrevista} />
                    <MiniStatistics textColorValue={'green.500'} name='Produção de Coágulos Por Árvore Realizada (kg)' value={producaoCoagulosPorArvoreRealizada} />
                </SimpleGrid>
                <Box mx={'0px'} pt={'0px'} h='400' mt='5px' border={'1px'} borderRadius={'10px'} borderColor={'blackAlpha.400'}>
                    <ReactApexChart options={options} series={series} type="line" height={400} width={'100%'} />
                </Box>
                <Accordion allowMultiple mt={'20px'} borderColor={'transparent'} >
                    <AccordionItem bg={'blackAlpha.100'} borderWidth={'0px'} >
                        {({ isExpanded }) => (
                            <>
                                <h2>
                                    <AccordionButton display="flex" _focus={{ boxShadow: 'none' }}>
                                        <Box as="span" flex='1' textAlign='left'>
                                            {isExpanded ? (
                                                <Center justifyContent={'flex-start'}>
                                                    <Icon as={AiOutlineMinusSquare} color={textColor} w={5} h={5} mr={'1px'} /> &nbsp; {anoInit}</Center>
                                            ) : (
                                                <Center justifyContent={'flex-start'}>
                                                    <Icon as={AiFillPlusSquare} color={textColor} w={5} h={5} mr={'1px'} /> &nbsp; {anoInit}</Center>
                                            )}
                                        </Box>
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel justifyContent={'end'} alignItems={'start'} ml={'20px'}>
                                    {renderProducaoUltimosMeses()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                    <AccordionItem bg={'blackAlpha.100'} mt={'2'} borderWidth={'0px'} >
                        {({ isExpanded }) => (
                            <>
                                <h2>
                                    <AccordionButton _focus={{ boxShadow: 'none' }}>
                                        <Box as="span" flex='1' textAlign='left'>
                                            {isExpanded ? (
                                                <Center justifyContent={'flex-start'}>
                                                    <Icon as={AiOutlineMinusSquare} color={textColor} w={5} h={5} mr={'1px'} /> &nbsp; {anoFim}</Center>
                                            ) : (
                                                <Center justifyContent={'flex-start'}>
                                                    <Icon as={AiFillPlusSquare} color={textColor} w={5} h={5} mr={'1px'} /> &nbsp; {anoFim}</Center>
                                            )}
                                        </Box>
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel justifyContent={'end'} alignItems={'start'} ml={'20px'} borderWidth={'0px'} >
                                    {renderProducaoPrimeirosMeses()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
                <Loader isOpen={loading} />
            </Card>
        </Box>
    );
}
