import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    TableContainer,
    Select,
    Box,
    Image,
    FormLabel,
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import LoaderBlack from 'components/loading/LoaderBlack';
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { ARRAY_MESES_SAFRA_PRODUCAO } from "views/admin/default/variables/MockData";
import * as ActionTypes from '../../../../constants/ActionTypes';
import { CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO, RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA } from '../../../../util/CalculoRelatorioSangriaAndProducao';
import TooltipSeringueiro from 'components/tooltip/TooltipSeringueiro';
import iconPdf from "assets/img/layout/pdf_icon_download.png";
import iconExcel from "assets/img/layout/excel_icon_download.png";

export default function ProducaoSangria() {

    const pdfRef = useRef();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [arrayProducaoCompleto, setarrayProducaoCompleto] = useState([]);
    const [totalPrevisto, setTotalPrevisto] = useState(0);
    const [totalRealizado, setTotalRealizado] = useState(0);
    const [totalPercentual, setTotalPercentual] = useState(0);
    const [totalSangriasRealizada, setTotalSangriasRealizada] = useState(0);
    const [totalKilogramaDeCorte, setTotalKilogramaDeCorte] = useState(0);
    const [totalKilogramaDeCortePropriedade, setTotalKilogramaDeCortePropriedade] = useState(0);
    const [extratoProducao, setExtratoProducao] = useState([]);
    const [nomeSafra, setNomeSafra] = useState('');
    const [nomePessoa, setNomePessoa] = useState('Todos');

    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');
    const [nomeRelatorio, setNomeRelatorio] = useState('Producao-sangria');
    const [loading, setLoading] = useState(false);    
    const [arrayMesesAndData, setArrayMesesAndData] = useState([]);
    const [nomeUa, setNomeUa] = useState('');
    const [marginText, setMarginText] = useState('0px');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientSubTotal = 'linear-gradient(#ff8000, #ff9933, #ffcc99)';
    const gradientHeader22 = 'linear-gradient(orange.700, orange.700, orange.600)';
    const gradientHeader33 = 'linear-gradient(orange.600, orange.600, orange.500)';

    useEffect(() => {
        async function loadScreen() {
            try {
                window.scrollTo(0, 0);

                const nmUa = localStorage.getItem('@HE-nomeUa');
                setNomeUa(nmUa);
                const nmSafra = localStorage.getItem('@HE-nomeSafra');
                setNomeSafra(nmSafra);

                localStorage.removeItem('@HE-DadosParaTelaGeracaoPdfAcompanhamentoProducao');
                const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
                const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');

                const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
                const fim = localStorage.getItem('@HE-dtFimSafraAsync');
                const ARRAY_MES = await RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(ini, fim);
                setArrayMesesAndData(ARRAY_MES);

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const nmRelatorio = `Producao-sangria-${dia}-${nmMes}-${ano}`;
                setNomeRelatorio(nmRelatorio);

                setNomeMesCorrente(nmMes);
                setNomeMunicipio(nomeMunicipioP);
                setIdUf(idUfP);

                const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
                const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');

                const dtIn = new Date(dtInicioSafra);
                const dtFn = new Date(dtFimSafra);

                await pesquisar(dtIn, dtFn, ARRAY_MES);
                setTelaCarregada(true);

            } catch (error) {
                setTelaCarregada(true);
            }
        }
        loadScreen();
    }, []);

    const pesquisar = async (dtInicioP, dtFimP, ARRAY_MES) => {
        try {
            const dtI = moment(dtInicioP).format('YYYY-MM-DD');
            const dtF = moment(dtFimP).format('YYYY-MM-DD');
            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const json = {
                idUa: ua,
                idSafra: idSafra,
                dtInicio: dtI,
                dtFim: dtF,
                arrayMesDataFilter: ARRAY_MES
            }

            console.log('++++++++', json);
            const response = await Api.postRequest(`producao/relatorioAcompanhamentoProducao`, json);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;
                const listSeringueiros = res.listSeringueiros;
                const responseProducao = res.responseProducao;

                await carregaDadosSeringueiro(responseProducao.listRelatorioFazenda[0], false);
                setarrayProducaoCompleto(responseProducao);

                if (listSeringueiros.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            listSeringueiros.map(function (item) {
                                const obj = {
                                    label: item.nome,
                                    value: `${item.idPessoa}`,
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSeringueiro(arrayPess);
                }
            } else {

                return;
            }
        } catch (error) {
            console.log('Erro na classe ProducaoSangria metodo pesquisar', error);
        }
    }

    const carregaDadosSeringueiro = async (arraySeringueirosComMeses, seringueiroSelecionadoP = false) => {

        const res = await CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO(arraySeringueirosComMeses, seringueiroSelecionadoP);
        setTotalRealizado(res.totalRealizado);
        setTotalPrevisto(res.totalPrevisto);
        setExtratoProducao(res.arrayProducaoAndGrafico);
        setTotalPercentual(res.totalPercentual);
        setTotalKilogramaDeCortePropriedade(res.totalKilogramaDeCortePropriedade);
        setTotalSangriasRealizada(res.totalSangriasRealizada);
        setTotalKilogramaDeCorte(res.totalKilogramaDeCorte);
    }

    const changePessoa = async (e) => {
        try {
            const val = e.target.value;
            setPessoaSelecionado(val);
            let nomePess = 'Todos';

            if (val != '') {
                const arraySeringueiros = arrayProducaoCompleto.listRelatorioAcompanhamentoProducao;
                const arrFilter = arraySeringueiros.filter(x => x.idPessoa == val);
                const arraySeringueirosComMeses = arrFilter.length > 0 ? arrFilter[0] : ARRAY_MESES_SAFRA_PRODUCAO;

                nomePess = arraySeringueirosComMeses.nomePessoa;
                setNomePessoa(nomePess);
                await carregaDadosSeringueiro(arraySeringueirosComMeses, true);
            } else {
                const arrayFazenda = arrayProducaoCompleto.listRelatorioFazenda;
                const xxx = arrayFazenda.length > 0 ? arrayFazenda[0] : ARRAY_MESES_SAFRA_PRODUCAO;

                setNomePessoa('Todos');
                await carregaDadosSeringueiro(xxx, false);
            }
        } catch (error) {
            setExtratoProducao(ARRAY_MESES_SAFRA_PRODUCAO[0].listMeses);
            console.log('Erro na classe ProducaoSangria metodo changePessoa', error);
        }
    };

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('10px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1500);
    }

    const gerarPDF = async () => {
        const input = pdfRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeigh = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeigh = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeigh / imgHeigh);
            pdf.addImage(imgData, 'PNG', 5, 10, pdfWidth - 10, imgHeigh * ratio);
            pdf.save(`${nomeRelatorio}.pdf`);
        });
    }

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await criarRelatorioExcel();
            setLoading(false);
        }, 1500);
    }

    const criarRelatorioExcel = async () => {
        const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
        const fim = localStorage.getItem('@HE-dtFimSafraAsync');
        const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
        const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');
        const ua = localStorage.getItem('@HE-idUa');
        const idSafra = localStorage.getItem('@HE-idSafra');
        const nomeUserLogado = localStorage.getItem('@HE-primeiroNomeUsuarioLogado');

        const json = {
            idUa: ua,
            idSafra: idSafra,
            dtInicio: dtInicioSafra,
            dtFim: dtFimSafra,
            nomeUsuario: nomeUserLogado,
            arrayMesDataFilter: arrayMesesAndData,
            idPessoa : pessoaSelecionado != '' ? pessoaSelecionado : '0'
        }

        const url = `producao/downloadRelatorioExcelProducaoPorSangria`;
        await Api.postRequestDownloadExcel(url, json, nomeRelatorio);
    }

    function renderTableRelatorio() {
        const sizeF = '13px';
        const sizeSubTotal = '15px';
        const espacoW = 0;
        const espacoH = 2;
        let corLinha = false;

        let textColorP = textColor;
        let textColorWhite = 'white';
        if (pessoaSelecionado == '') {
            textColorP = 'transparent';
            textColorWhite = 'transparent';
        }
        return (
            <Box>
                <TableContainer>
                    <Table size='sm' variant='unstyled'>
                        <Thead>
                            <Tr bgGradient={gradientHeader22} h={'20px'} whiteSpace={'break-spaces'}>
                                <Th w={'70%'} colSpan={5} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{''}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'30%'} colSpan={2} sx={{ paddingX: espacoW }} bg={'blackAlpha.400'} whiteSpace={'break-spaces'} >
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'Kg / Corte Realizado'}</Text>
                                    </Flex>
                                </Th>
                            </Tr>
                            <Tr bgGradient={gradientHeader33} h={'30px'}>
                                <Th w={'10%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='start' align='center' textTransform={'none'}>
                                        <Text mb={marginText} pl={'10px'} color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'Mês'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'14%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'Previsto (kg)'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'14%'} sx={{ paddingX: espacoW }}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'Realizado (kg)'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'14%'} sx={{ paddingX: espacoW }}>
                                    <Flex flexDirection={'column'} justify='center' align='center' textTransform={'none'}>
                                        <Text color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'% Produção'}</Text>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'Realizada'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: espacoW }}>
                                    <Flex flexDirection={'column'} justify='center' align='center' textTransform={'none'}>
                                        <Text color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'Total de Sangrias'}</Text>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'Realizadas'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'12%'} sx={{ paddingX: espacoW }} bg={'blackAlpha.400'}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <TooltipIcon
                                            label={
                                                <Box px={'0px'} py={'5px'} >
                                                    <Text align={'center'}>{'Kg / Corte Realizado'}</Text>
                                                    <Text align={'start'} mt={'5px'} lineHeight={'100%'}>{'Coluna apenas para SERINGUEIRO'}</Text>
                                                    <Text align={'start'} lineHeight={'100%'}>{'Referente ao quilograma de corte'}</Text>
                                                    <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'Selecione um seringueiro para visualizar'}</Text>
                                                    <Text align={'start'} mt={'10px'} lineHeight={'100%'} color={'yellow.200'}>{'Cálculo para essa coluna'}</Text>
                                                    <Text align={'start'} ml={'10px'} lineHeight={'100%'}>{'Realizado (Kg) / Total de Sangrias Realizadas'}</Text>
                                                </Box>
                                            }
                                            height={'18px'} color={'white'} />
                                        <Text mb={marginText} ml={'5px'} color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'Seringueiro'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'18%'} sx={{ paddingX: espacoW }} bg={'blackAlpha.400'}>
                                    <Flex justify='center' align='center' textTransform={'none'}>
                                        <TooltipIcon
                                            label={
                                                <Box px={'0px'} py={'5px'} >
                                                    <Text align={'center'}>{'Kg / Corte Realizado'}</Text>
                                                    <Text align={'start'} mt={'5px'} lineHeight={'100%'}>{'Coluna apenas para PROPRIEDADE'}</Text>
                                                    <Text align={'start'} lineHeight={'100%'}>{'Referente ao quilograma de corte'}</Text>
                                                    <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'Independete da seleção essa coluna será preenchida com os dados da propriedade'}</Text>
                                                    <Text align={'start'} mt={'10px'} lineHeight={'100%'} color={'yellow.200'}>{'Cálculo para essa coluna'}</Text>
                                                    <Text align={'start'} ml={'10px'} lineHeight={'100%'}>{'Realizado (Kg) / Total de Sangrias Realizadas'}</Text>
                                                </Box>
                                            }
                                            height={'18px'} color={'white'} />
                                        <Text mb={marginText} ml={'5px'} color={textColorHeader} fontSize={sizeF} lineHeight={'100%'}>{'Propriedade (média)'}</Text>
                                    </Flex>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {extratoProducao.map((item, key) => {
                                corLinha = !corLinha;
                                const mbParcial = '-5px';
                                const mesdesc = item.mes.substring(0, 3);
                                const ttParcial = item.totalAteMesAnterior;
                                const colorBack = pessoaSelecionado == '' ? 'transparent' : 'transparent';

                                let checkColor = 'blackAlpha.400';
                                if (pessoaSelecionado != '') {
                                    const val1 = item.kilogramaDeCorte != null ? Number(item.kilogramaDeCorte) : 0;
                                    const val2 = item.kilogramaDeCortePropriedade != null ? Number(item.kilogramaDeCortePropriedade) : 0;
                                    checkColor = val1 < val2 ? '#F1A983' : '#B5E6A2';
                                }
                                return (
                                    <Tr key={key + 1} alignItems={'center'} bg={corLinha ? 'rgba(217, 217, 217, 0.2)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} >
                                        <Td sx={{ paddingX: espacoW, paddingY: espacoH }} bg={colorBack}>
                                            <Box position={'relative'} w={'100%'}>
                                                <Flex justify='start' align='center'>
                                                    <Text mb={marginText} pl={'10px'} color={textColor} fontSize={sizeF} fontWeight='700' lineHeight={'100%'} align={'center'}>{mesdesc}</Text>
                                                    {item.listSeringueiroDeletado.length > 0 &&
                                                        <Box position={'absolute'} top={'-5px'} left={'50px'}>
                                                            <TooltipSeringueiro
                                                                label={
                                                                    <Box py={'5px'} >
                                                                        {item.listSeringueiroDeletado.map((x, y) => {
                                                                            const dtDesativado = x.dtDeletado != null ? moment(x.dtDeletado).format('DD/MM/YYYY') : '';
                                                                            return (
                                                                                <Box key={y}>
                                                                                    <Text align={'center'}>{`Seringueiro ${x.nomeSeringueiroDeletado} desativado, data: ${dtDesativado}`}</Text>
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                }
                                                                height={'22px'} />
                                                        </Box>
                                                    }
                                                </Flex>
                                            </Box>
                                            {ttParcial != null &&
                                                <Flex mb={mbParcial} w={'100%'} h={'35px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{'Total Parcial'}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td sx={{ paddingX: espacoW, paddingY: espacoH }} bg={colorBack}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.previsto}</Text>

                                            {ttParcial != null &&
                                                <Flex mb={mbParcial} w={'100%'} h={'35px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{ttParcial.totalPrevistoAteMesAnterior}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td sx={{ paddingX: espacoW, paddingY: espacoH }} bg={colorBack}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.realizado}</Text>

                                            {ttParcial != null &&
                                                <Flex mb={mbParcial} w={'100%'} h={'35px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{ttParcial.totalRealizadoAteMesAnterior}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td sx={{ paddingX: espacoW, paddingY: espacoH }} bg={colorBack}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.percentual}%`}</Text>

                                            {ttParcial != null &&
                                                <Flex mb={mbParcial} w={'100%'} h={'35px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{`${ttParcial.totalPercentualAteMesAnterior}%`}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td sx={{ paddingX: espacoW, paddingY: espacoH }} bg={colorBack}>
                                            <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.sangriasRealizadas}</Text>

                                            {ttParcial != null &&
                                                <Flex mb={mbParcial} w={'100%'} h={'35px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{ttParcial.totalSangriasRealizadasMesAnterior}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td sx={{ paddingX: espacoW, paddingY: espacoH }} bg={checkColor} transition="background-color 1s ease">
                                            <Text mb={marginText} color={textColorP} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'} >{item.kilogramaDeCorte}</Text>

                                            {ttParcial != null &&
                                                <Flex mb={mbParcial} w={'100%'} h={'35px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorWhite} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{ttParcial.totalKilosPorCorteMesAnterior}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                        <Td sx={{ paddingX: espacoW, paddingY: espacoH }} bg={colorBack}>
                                            <Text mb={marginText} color={'black'} fontSize={sizeF} fontWeight='bold' align={'center'} lineHeight={'100%'}>{item.kilogramaDeCortePropriedade}</Text>

                                            {ttParcial != null &&
                                                <Flex mb={mbParcial} w={'100%'} h={'35px'} justify='center' align='center' bgGradient={gradientSubTotal}>
                                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' lineHeight={'100%'}>{ttParcial.totalKilosPorCortePropriedadeMesAnterior}</Text>
                                                </Flex>
                                            }
                                        </Td>
                                    </Tr>
                                )
                            })}
                            <Tr bgGradient={gradientHeader}>
                                <Td sx={{ paddingX: espacoW }}>
                                    <Text mb={marginText} ml={'10px'} color={textColorHeader} fontSize={sizeF} fontWeight='700'>{'Total ano'}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW }}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'}>{totalPrevisto}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW }}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'}>{totalRealizado}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW }}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'}>{`${totalPercentual}%`}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW }}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'}>{totalSangriasRealizada}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW }}>
                                    <Text mb={marginText} color={textColorWhite} fontSize={sizeF} fontWeight='700' align={'center'}>{totalKilogramaDeCorte}</Text>
                                </Td>
                                <Td sx={{ paddingX: espacoW }}>
                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'}>{totalKilogramaDeCortePropriedade}</Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderSelect() {
        return (
            <Flex px='5px' justify='space-between' mb='2px' align='center'>
                <Box w='100%' >
                    <Flex px='25px' justify='space-between' mb='2px' align='center'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%'>Seringueiros</Text>
                    </Flex>
                    <Select
                        value={pessoaSelecionado}
                        errorBorderColor='crimson'
                        placeholder="Todos"
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={changePessoa}
                        h={'35px'}
                        w={'400px'}
                        ml={'15px'}
                    >
                        {listSeringueiro.map((item, index) => {
                            return (
                                <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Box>
                <Box display={'flex'} w='100%' justifyContent={'flex-end'} alignItems={'center'} >
                    <Flex px='2px' justify='flex-end' mb='3px' align='center' >
                        <FormLabel htmlFor='balance' _hover={{ cursor: "pointer" }} mt={'-10px'} >
                            <Image boxSize='35px' objectFit='contain' src={iconExcel} alt='Dan Abramov' onClick={() => { gerarRelatorioExcel() }} />
                        </FormLabel>
                        <FormLabel htmlFor='balance' _hover={{ cursor: "pointer" }} mt={'-10px'} >
                            <Image boxSize='35px' objectFit='contain' src={iconPdf} alt='Dan Abramov' onClick={downloadPdf} />
                        </FormLabel>
                    </Flex>
                </Box>
            </Flex>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' justify='center' align='center' mt={'50'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' pt='10px' px='0px' borderRadius={'10px'}>
                {renderSelect()}
                <Box ref={pdfRef} mt={'10px'} w='100%'>
                    <Box position={'relative'}>
                        <Flex flexDirection={'column'} justify='center' align='center' mb={'0px'}>
                            <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'}>Produção por Sangria</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{nomeMesCorrente}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                        </Flex>
                        <Box position={'absolute'} top={'1px'} left={'10px'}>
                            <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                        </Box>
                    </Box>
                    <Flex px='20px' justify='flex-start' mb='3px' align='center' >
                        <Text color={textColor} fontSize={'15px'} fontWeight={'light'} lineHeight={'100%'}>Safra:</Text>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} ml={'10px'} lineHeight={'100%'}>{nomeSafra}</Text>
                    </Flex>
                    <Flex px='20px' justify='flex-start' mb='3px' align='center'>
                        <Text mb={marginText} color={textColor} fontSize={'15px'} fontWeight={'light'} lineHeight={'100%'}>Seringueiro:</Text>
                        <Box ml={'10px'}>
                            <Text mb={marginText} color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight={'100%'}>{nomePessoa}</Text>
                        </Box>
                    </Flex>
                    <Box w='100%' px={'2'} mt={'10px'}>
                        {renderTableRelatorio()}
                    </Box>
                </Box>
            </Card>
            <LoaderBlack isOpen={loading} />
        </Box>
    );
}
