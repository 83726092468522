import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    SimpleGrid,
    useColorModeValue,
    Select,
    Wrap,
    WrapItem,
    Text,
    Button,
    Center,
    Stat,
    StatLabel,
    StatNumber
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { ARRAY_MESES, ARRAY_MESES_SAFRA_PRODUCAO } from "model/MockData";
import { CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO, RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA } from '../../../../util/CalculoRelatorioSangriaAndProducao';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import MessageSreen from 'components/message/MessageSreen';

export default function IndicadorDeProducao() {

    const [telaCarregada, setTelaCarregada] = useState(false);

    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [pessoaSelecionado, setPessoaSelecionado] = useState('');
    const [arrayProducaoCompleto, setarrayProducaoCompleto] = useState([]);

    const [totalPrevisto, setTotalPrevisto] = useState(0);
    const [totalRealizado, setTotalRealizado] = useState(0);
    const [saldoAcumulado, setSaldoAcumulado] = useState(0);
    const [porcentagemRealizada, setPorcentagemRealizada] = useState(0);
    const [producaoCoagulosPorArvorePrevista, setProducaoCoagulosPorArvorePrevista] = useState(0);
    const [producaoCoagulosPorArvoreRealizada, setProducaoCoagulosPorArvoreRealizada] = useState(0);

    const [saldoAcumuladoGeral, setSaldoAcumuladoGeral] = useState(0);
    const [porcentagemRealizadaGeral, setPorcentagemRealizadaGeral] = useState(0);
    const [producaoCoagulosPorArvoreRealizadaGeral, setProducaoCoagulosPorArvoreRealizadaGeral] = useState(0);

    const [arrayMeses, setArrayMeses] = useState([]);
    const [arrayMesInicio, setArrayMesInicio] = useState([]);
    const [arrayMesFim, setArrayMesFim] = useState([]);
    const [mesInicioSelecionado, setMesInicioSelecionado] = useState('');
    const [mesFimSelecionado, setMesFimSelecionado] = useState('');

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [corSaldoAcumulado, setCorSaldoAcumulado] = useState('green.500');
    const [corSaldoAcumuladoGeral, setCorSaldoAcumuladoGeral] = useState('green.500');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            try {
                window.scrollTo(0, 0);

                setArrayMesInicio(ARRAY_MESES);
                setArrayMesFim(ARRAY_MESES);

                const ini = localStorage.getItem('@HE-dtInicioSafraAsync');
                const fim = localStorage.getItem('@HE-dtFimSafraAsync');

                const initMes = moment(ini).startOf('month').format('YYYY-MM-DD');
                const endDate = moment(fim).startOf('month').format('YYYY-MM-DD');      // NESSE CASO OS DOIS SAO startOf

                setMesInicioSelecionado(initMes);
                setMesFimSelecionado(endDate);

                const ARRAY_MES = await RETORNA_ARRAY_COM_DATA_NOME_STATUS_DA_SAFRA(ini, fim);
                setArrayMeses(ARRAY_MES);

                const dtInicioSafra = moment(ini).startOf('month').format('YYYY-MM-DD');
                const dtFimSafra = moment(fim).endOf('month').format('YYYY-MM-DD');

                await pesquisar(dtInicioSafra, dtFimSafra, ARRAY_MES);
                setTelaCarregada(true);

            } catch (error) {
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const pesquisar = async (dtInicioP, dtFimP, ARRAY_MES) => {
        try {
            const dtI = moment(dtInicioP).format('YYYY-MM-DD');
            const dtF = moment(dtFimP).format('YYYY-MM-DD');
            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const json = {
                idUa: ua,
                idSafra: idSafra,
                dtInicio: dtI,
                dtFim: dtF,
                arrayMesDataFilter: ARRAY_MES
            }

            const response = await Api.postRequest(`producao/relatorioAcompanhamentoProducao`, json);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;
                const listSeringueiros = res.listSeringueiros;
                const responseProducao = res.responseProducao;

                await carregaDadosSeringueiro(responseProducao.listRelatorioFazenda[0], false);

                setarrayProducaoCompleto(responseProducao);
                if (listSeringueiros.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            listSeringueiros.map(function (item) {
                                const obj = {
                                    label: item.nome,
                                    value: `${item.idPessoa}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSeringueiro(arrayPess);
                }
            } else {
                return;
            }
        } catch (error) {

            console.log('Erro na classe IndicadorDeProducao metodo pesquisar', error);
        }
    }

    const pesquisarComDatas = async () => {
        try {
            const primeiroDiaDoMes = moment(mesInicioSelecionado).startOf('month');
            const UltimoDiaDoMes = moment(mesFimSelecionado).endOf('month');

            const duration = moment.duration(UltimoDiaDoMes.diff(primeiroDiaDoMes));
            const resultado = duration.asMonths();
            const meses = parseFloat(resultado.toFixed(0));

            if (meses < 1) {
                setNumStatusResposta(ActionTypes.DATA_INICIO_MAIOR_QUE_DATA_FIM);
                showAlert();
                return;
            } else {
                setIsVisibleAlert(false);
            }

            let startDate = moment(primeiroDiaDoMes);
            let endDate = moment(UltimoDiaDoMes);
            let date = [];

            for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'month')) {
                date.push(m.format('YYYY-MM-DD'));
            }

            let arrayPess = [];
            await new Promise(resolve => {
                resolve(
                    arrayMeses.map((item, index) => {
                        let statusP = false;
                        if (index > 0) {
                            const arrFilter = date.filter(x => x == item.dtMes);
                            statusP = arrFilter.length > 0 ? true : false;
                        }
                        const obj = {
                            dtMes: item.dtMes,
                            mes: item.mes,
                            status: statusP,
                        }
                        arrayPess.push(obj);
                    })
                )
            });

            const dtI = moment(primeiroDiaDoMes).format('YYYY-MM-DD');
            const dtF = moment(UltimoDiaDoMes).format('YYYY-MM-DD');
            const ua = localStorage.getItem('@HE-idUa');
            const idSafra = localStorage.getItem('@HE-idSafra');

            const json = {
                idUa: ua,
                idSafra: idSafra,
                dtInicio: dtI,
                dtFim: dtF,
                arrayMesDataFilter: arrayPess
            }

            const response = await Api.postRequest(`producao/relatorioAcompanhamentoProducao`, json);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setPessoaSelecionado('');
                const res = response.respostaRequisicao.data;
                const responseProducao = res.responseProducao;

                await carregaDadosSeringueiro(responseProducao.listRelatorioFazenda[0], false);
                setarrayProducaoCompleto(responseProducao);
            }
        } catch (error) {
            console.log('Erro na classe GraficoProducaoDeCoagulos metodo pesquisar', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const carregaDadosSeringueiro = async (arraySeringueirosComMeses, seringueiroSelecionadoP = false) => {

        const res = await CALCULAR_VALORES_SERINGUEIRO_E_PRODUCAO(arraySeringueirosComMeses, seringueiroSelecionadoP);

        setTotalRealizado(res.totalRealizado);
        setTotalPrevisto(res.totalPrevisto);
        setSaldoAcumulado(res.saldoAcumulado);
        setPorcentagemRealizada(res.porcentagemRealizado);
        setProducaoCoagulosPorArvorePrevista(res.producaoCoagulosPorArvorePrevista);
        setProducaoCoagulosPorArvoreRealizada(res.producaoCoagulosPorArvoreRealizada);

        const cor = await selecionarCorSaldoAcumulado(res);
        setCorSaldoAcumulado(cor);

        if (!seringueiroSelecionadoP) {
            setSaldoAcumuladoGeral(res.saldoAcumulado);
            setPorcentagemRealizadaGeral(res.porcentagemRealizado);
            setProducaoCoagulosPorArvoreRealizadaGeral(res.producaoCoagulosPorArvoreRealizada);

            const corGeal = await selecionarCorSaldoAcumulado(res);
            setCorSaldoAcumuladoGeral(corGeal);
        }
    }

    const selecionarCorSaldoAcumulado = async (res) => {

        let result = 'green.500';

        const xx = `${res.totalRealizado}`;
        const realizado = xx.replace(/[^\d]+/g, '');
        const yy = `${res.totalPrevisto}`;
        const previsto = yy.replace(/[^\d]+/g, '');

        if(Number(realizado) > Number(previsto)){
            result = 'green.500';
        }else if(Number(realizado) < Number(previsto)){
            result = 'red.600';
        }else{
            result = 'orange.500';
        }
        return result;
    }

    const changePessoa = async (e) => {
        try {
            const val = e.target.value;
            setPessoaSelecionado(val);

            if (val != '') {
                const arraySeringueiros = arrayProducaoCompleto.listRelatorioAcompanhamentoProducao;
                const arrFilter = arraySeringueiros.filter(x => x.idPessoa == val);
                const arraySeringueirosComMeses = arrFilter.length > 0 ? arrFilter[0] : ARRAY_MESES_SAFRA_PRODUCAO;
                await carregaDadosSeringueiro(arraySeringueirosComMeses, true);
            } else {
                const arrayFazenda = arrayProducaoCompleto.listRelatorioFazenda;
                const xxx = arrayFazenda.length > 0 ? arrayFazenda[0] : ARRAY_MESES_SAFRA_PRODUCAO;
                await carregaDadosSeringueiro(xxx, false);
            }
        } catch (error) {
            console.log('Erro na classe GraficoProducaoDeCoagulos metodo changePessoa', error);
        }
    };

    function renderMeses() {
        return (
            <Wrap spacing='10px' mb={'15px'} justify='flex-start'>
                <WrapItem>
                    <Box w='100%'>
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Início</Text>
                        <Select
                            value={mesInicioSelecionado}
                            errorBorderColor='crimson'
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => setMesInicioSelecionado(e.target.value)}
                            w={'200px'}
                            h={'35px'}
                        >
                            {arrayMesInicio.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' >
                        <Text color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' ml={'10px'} align={'start'}>Fim</Text>
                        <Select
                            value={mesFimSelecionado}
                            errorBorderColor='crimson'
                            placeholder=""
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => setMesFimSelecionado(e.target.value)}
                            w={'200px'}
                            h={'35px'}
                        >
                            {arrayMesFim.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box h='100%'>
                        <Center h={'100%'} p={'5px'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                            <TooltipIcon
                                label={
                                    <Box px={'0px'} py={'5px'} >
                                        <Text align={'center'}>{'Campos de Início e Fim'}</Text>
                                        <Text mt={'5px'} lineHeight='100%' align={'start'}>{'Início:'}</Text>
                                        <Text mt={'0px'} lineHeight='100%' align={'start'}>{'Recebe a primeiro dia do mês selecionado'}</Text>
                                        <Text mt={'5px'} lineHeight='100%' align={'start'}>{'Fim:'}</Text>
                                        <Text mt={'0px'} lineHeight='100%' align={'start'}>{'Recebe a último dia do mês selecionado'}</Text>
                                    </Box>
                                }
                                height={'23px'} />
                        </Center>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box w='100%' h={'100%'}>
                        <Center alignItems={'flex-end'} h={'100%'}>
                            <Button variant="brand" onClick={pesquisarComDatas}>Pesquisar</Button>
                        </Center>
                    </Box>
                </WrapItem>
            </Wrap>
        )
    }

    function renderSelect() {
        return (
            <Box w={'100%'} py={'10px'}>
                <Flex flexDirection={'column'} justify='center' align='start' >
                    <Text ml={'10px'} color={'gray.500'} fontSize='15px' fontWeight='700' lineHeight='100%'>Seringueiros</Text>
                    <Select
                        value={pessoaSelecionado}
                        errorBorderColor='crimson'
                        placeholder="Todos"
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={changePessoa}
                        w={'400px'}
                        h={'35px'}
                    >
                        {listSeringueiro.map((item, index) => {
                            return (
                                <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Flex>
            </Box>
        )
    }

    function renderCardsQualidade() {
        const fontS = '13px';
        const isValid = pessoaSelecionado != '' ? true : false;
        const bgBackGroud = pessoaSelecionado != '' ? 'white' : 'blackAlpha.200';
        return (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }} gap='20px' mb='20px' mt={'15px'} px={'3'}>
                <Card bg={bgBackGroud} py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: fontS, }}>{'Produção de Coágulos Prevista (kg)'}</StatLabel>
                            <StatNumber color={'orange.500'} fontSize={{ base: "xl", }}>{totalPrevisto}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card bg={bgBackGroud} py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: fontS, }}>{'Produção de Coágulos Realizada (kg)'}</StatLabel>
                            <StatNumber color={'green.500'} fontSize={{ base: "xl", }}>{totalRealizado}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card bg={bgBackGroud} py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: fontS, }}>{'Saldo Acumulado (kg)'}</StatLabel>
                            <StatNumber color={corSaldoAcumulado} fontSize={{ base: "xl", }}>{saldoAcumulado}</StatNumber>
                            {isValid &&
                                <Box mx={'10px'} bg={'blackAlpha.200'} borderWidth={'1px'} borderRadius={'8px'} borderColor={'blackAlpha.500'}>
                                    <StatNumber color={corSaldoAcumuladoGeral} fontSize={{ base: "sm", }}>{saldoAcumuladoGeral}</StatNumber>
                                    <Text color={'black'} fontSize={'13px'} lineHeight={'100%'}>{'Valor da Propriedade'}</Text>
                                </Box>
                            }
                        </Stat>
                    </Flex>
                </Card>
                <Card bg={bgBackGroud} px='5px' py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: fontS, }}>{'Porcentagem Realizado'}</StatLabel>
                            <StatNumber color={'green.500'} fontSize={{ base: "xl", }}>{`${porcentagemRealizada}%`}</StatNumber>
                            {isValid &&
                                <Box mx={'10px'} bg={'blackAlpha.200'} borderWidth={'1px'} borderRadius={'8px'} borderColor={'blackAlpha.500'}>
                                    <StatNumber color={'green.500'} fontSize={{ base: "sm", }}>{`${porcentagemRealizadaGeral}%`}</StatNumber>
                                    <Text color={'black'} fontSize={'13px'} lineHeight={'100%'}>{'Valor da Propriedade'}</Text>
                                </Box>
                            }
                        </Stat>
                    </Flex>
                </Card>
                <Card bg={bgBackGroud} px='5px' py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: fontS, }}>{'Produção de Coágulos Por Árvore Prevista (kg)'}</StatLabel>
                            <StatNumber color={'orange.500'} fontSize={{ base: "xl", }}>{producaoCoagulosPorArvorePrevista}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card bg={bgBackGroud} px='5px' py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: fontS, }}>{'Produção de Coágulos Por Árvore Realizada (kg)'}</StatLabel>
                            <StatNumber color={'green.500'} fontSize={{ base: "xl", }}>{producaoCoagulosPorArvoreRealizada}</StatNumber>
                            {isValid &&
                                <Box mx={'10px'} bg={'blackAlpha.200'} borderWidth={'1px'} borderRadius={'8px'} borderColor={'blackAlpha.500'}>
                                    <StatNumber color={'green.500'} fontSize={{ base: "sm", }}>{producaoCoagulosPorArvoreRealizadaGeral}</StatNumber>
                                    <Text color={'black'} fontSize={'13px'} lineHeight={'100%'}>{'Valor da Propriedade'}</Text>
                                </Box>
                            }
                        </Stat>
                    </Flex>
                </Card>
            </SimpleGrid>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' justify='center' align='center' py={'1'} >
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Card py='0px' px='10px' borderRadius={'10px'}>
            {renderAlert()}
            {renderMeses()}
            {renderSelect()}
            {renderCardsQualidade()}
        </Card>
    );
}
