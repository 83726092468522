import React from "react";
import { Icon } from "@chakra-ui/react";
import {
    MdPerson,
    MdLock,
    MdOutlineShoppingCart,
    MdGroups,
    MdLocationOn,
    MdEco,
    MdOutbond,
    MdSpaceDashboard,
    MdOutlineBarChart,
    MdEvent,
    MdOutlineSecurity,
    MdListAlt,
    MdPostAdd,
    MdCamera,
    MdEditDocument,
    //MdHome,
    //MdDensitySmall,
    //MdHomeWork,
    MdQrCode,
    MdOutlineConstruction,
} from "react-icons/md";
import { FaCloudSunRain } from "react-icons/fa";
import { GiFarmer } from "react-icons/gi";
import MainDashboard from "views/admin/default/MainDashboard";
import Profile from "views/admin/profile";

import Produtores from "views/admin/produtores/list/ListaProdutores";
import ProdutoresForm from "views/admin/produtores/form/FormProdutor";

//import ListaGerentes from "views/admin/gerente/list/ListaGerentes";
import FormGerente from "views/admin/gerente/form/FormGerentes";

import FormPropriedades from "views/admin/propriedades/form/FormPropriedades";
import FormPermitirAcessoPropriedade from "views/admin/propriedades/permitirAcesso/FormPermitirAcessoPropriedade";
import SazonalidadeList from "views/admin/sazonalidade/SazonalidadeList";
import SazonalidadePropriedade from "views/admin/sazonalidade/SazonalidadePropriedade";

import SignIn from "views/auth/signIn/SignIn";

import Estimulacao from "views/admin/estimulacao";
import LancarEstimulacao from "views/admin/estimulacao/LancarEstimulacao";
import EstimulacaoPdf from "views/admin/estimulacao/EstimulacaoPdf";

import AssociarSeringueiroSafra from "views/admin/seringueiros/form/AssociarSeringueiroSafra";

import ListaQualidadeSangria from "views/admin/qualidadeSangria/list";
import RelatorioQualidadeSangria from "views/admin/qualidadeSangria/relatorio";
import QualidadeSangria from "views/admin/qualidadeSangria/lancarQualidadeSangria/QualidadeSangria";
import AvaliacaoModal from "views/admin/qualidadeSangria/lancarQualidadeSangria/AvaliacaoModal";
import GalleryImageAndVideo from "views/admin/qualidadeSangria/lancarQualidadeSangria/GalleryImageAndVideo";

import LancamentoProducao from "views/admin/producao/lancamentoProducao";
import LancarProducao from "views/admin/producao/lancamentoProducao/LancarProducao";
import GraficoProducaoDeCoagulos from "views/admin/producaoDeCoagulos/GraficoProducaoDeCoagulos";
import AcumuladoSafra from "views/admin/acumuladoSafra";
import GerarPdfAcumuladoSafra from "views/admin/acumuladoSafra/GerarPdfAcumuladoSafra";
import GerarPdfLancamentoProducao from "views/admin/producao/lancamentoProducao/GerarPdfLancamentoProducao";

import EscolherUa from "views/admin/escolherUa/EscolherUa";

import HistoricoSeringueiro from "views/admin/historicoSeringueiro/form/HistoricoSeringueiro";
import ListSeringueiro from "views/admin/historicoSeringueiro/list/ListSeringueiro";

import ListaTodosSeringueiros from "views/admin/seringueiros/cadastro/ListaTodosSeringueiros";
import FormSeringueiroCadastro from "views/admin/seringueiros/cadastro/FormSeringueiroCadastro";
import ListaSeringueirosDisponiveis from "views/admin/seringueiros/cadastro/ListaSeringueirosDisponiveis";

import PrevisaoTempo from "views/admin/tempo/PrevisaoTempo";
import FormPermissaoAcessosSistema from "views/admin/gerenciamentoDeAcessos/FormPermissaoAcessosSistema";

import Relatorios from "views/admin/relatorios";
import StatusDiario from "views/admin/relatorios/statusDiario/StatusDiario";

import IndicadorSangria from "views/admin/relatorios/indicadorSangria/IndicadorSangria";
import ProducaoSangria from "views/admin/relatorios/producaoPorSangria/ProducaoSangria";
import RelatorioEmitidoPersonalizadoAndEsporadido from "views/admin/relatorios/relatoriosEmitidos/RelatorioEmitidoPersonalizadoAndEsporadido";
import AtividadesDeSangria from "views/admin/relatorios/atividadesSangria/AtividadesDeSangria";

import RelatorioPersonalizado from "views/admin/relatorioPersonalizadoAndEsporadico/personalizado/RelatorioPersonalizado";
import AddAssuntoPersonalizado from "views/admin/relatorioPersonalizadoAndEsporadico/personalizado/AddAssuntoPersonalizado";
import GerarRelatorioPersonalizado from "views/admin/relatorioPersonalizadoAndEsporadico/personalizado/GerarRelatorioPersonalizado";
import EscolherTipoRelatorio from "views/admin/relatorioPersonalizadoAndEsporadico/EscolherTipoRelatorio";

import RelatorioEsporadico from "views/admin/relatorioPersonalizadoAndEsporadico/esporadico/RelatorioEsporadico";
import AddAssuntoEsporadico from "views/admin/relatorioPersonalizadoAndEsporadico/esporadico/AddAssuntoEsporadico";
import GerarRelatorioEsporadico from "views/admin/relatorioPersonalizadoAndEsporadico/esporadico/GerarRelatorioEsporadico";

import HomeProgramacao from "views/admin/programacao/HomeProgramacao";
import LogProgramacao from "views/admin/programacao/logsProgramacao/LogProgramacao";
import DetalharLog from "views/admin/programacao/logsProgramacao/DetalharLog";

import GerarProgramacao from "views/admin/programacao/GerarProgramacao";
import Reprogramacao from "views/admin/programacao/Reprogramacao";
import GerarProgramacaoIndividual from "views/admin/programacao/GerarProgramacaoIndividual";

import ListaSeringueirosParaLancamento from "views/admin/lancamentoTarefa/ListaSeringueirosParaLancamento";
import AcompanhamentoAtividadesSangria from "views/admin/lancamentoTarefa/AcompanhamentoAtividadesSangria";
import LancarTarefa from "views/admin/lancamentoTarefa/LancarTarefa";
import ListarTarefasSeringueiro from "views/admin/lancamentoTarefa/ListarTarefasSeringueiro";                   // EM PROCESSO
// import LancarTarefaAtividadeSangria from "views/admin/lancamentoTarefa/LancarTarefaAtividadeSangria";                   // EM PROCESSO

import PropriedadesPaginado from "views/admin/propriedades/listPaginado/PropriedadesPaginado";
import CadastrarSafra from "views/admin/propriedades/form/CadastrarSafra";

//import RemoverPessoaUaAndSafra from 'views/admin/documentacao/RemoverPessoaUaAndSafra';                           // EM PROCESSO
import DeleteForm from 'views/admin/delete/DeleteForm';
import QrCodeGenerate from 'views/admin/qrCode/QrCodeGenerate';
import QrCodeGenerateAdmin from 'views/admin/qrCode/QrCodeGenerateAdmin';

//import Documentacao from "views/admin/documentacao";
//import PaginaBasica from "views/admin/paginaBasica";
//import PaginacaoScreen from "views/admin/paginaBasica/PaginacaoScreen";
//import ScreenDefault from "views/admin/paginaBasica/ScreenDefault";

import * as ActionTypes from './constants/ActionTypes';

const colorIcon = '#FFFFFF';

/*
╔═══════════════════════════════════════════════════════════════╗
    IMPORTANTE, OS PERFIS DE ACESSO TEM UMA HIERARQUIA:

    ADMIN
        - ACESSA TUDO
    TECNICO
        - ACESSA TUDO MENOS ADMIN
    PRODUTOR
        - ACESSA TUDO MENOS ADMIN E TECNICO
    GERENTE
        - ACESSA TUDO MENOS ADMIN E TECNICO E PRODUTOR
    SERINGUEIRO
        - ACESSA TUDO MENOS ADMIN E TECNICO E PRODUTOR E GERENTE
╚═══════════════════════════════════════════════════════════════╝*/
const routes = [
    {
        name: "Painel Geral",
        layout: "/admin",
        path: "/default",
        icon: <Icon as={MdSpaceDashboard} width='20px' height='20px' color={colorIcon} />,
        component: MainDashboard,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_TODOS,
    },
    {
        name: "Atividade de Sangria",
        layout: "/admin",
        path: "/lista-seringueiros-para-lacamento",
        icon: <Icon as={MdEditDocument} width='20px' height='20px' color='inherit' />,
        component: ListaSeringueirosParaLancamento,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Trocar Propriedade",
        layout: "/admin",
        path: "/escolherUa",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: EscolherUa,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_TODOS,
    },

    // ===================== CADASTROS =====================
    {
        name: "Cadastros",
        layout: "/admin",
        path: "/lista-todos-seringueiros",
        icon: (<Icon as={GiFarmer} width='20px' height='20px' color={colorIcon} />),
        component: ListaTodosSeringueiros,
        subItens: [
            {
                name: "Cadastro Seringueiros",
                layout: "/admin",
                path: "/lista-todos-seringueiros",
                icon: (<Icon as={MdOutlineShoppingCart} width='20px' height='20px' color={colorIcon} />),
                component: ListaTodosSeringueiros,
                visible: true,
            },
            {
                name: "Cadastro Produtores",
                layout: "/admin",
                path: "/produtores",
                icon: (<Icon as={MdOutlineShoppingCart} width='20px' height='20px' color={colorIcon} />),
                component: Produtores,
                visible: true,
            },
            {
                name: "Cadastro Propriedades",
                layout: "/admin",
                path: "/propriedades-paginado",
                icon: <Icon as={MdEco} width='20px' height='20px' color={colorIcon} />,
                component: PropriedadesPaginado,
                visible: true,
            },
        ],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Cadastro Seringueiro",
        layout: "/admin",
        path: "/form-seringueiro-cadastro",
        icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
        component: FormSeringueiroCadastro,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== PRODUTOR =====================
    {
        name: "Produtores",
        layout: "/admin",
        path: "/produtores",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: Produtores,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_TECNICO,
    },
    {
        name: "Produtor",
        layout: "/admin",
        path: "/produtores-form",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: ProdutoresForm,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_TECNICO,
    },

    // ===================== PROPRIEDADES =====================
    {
        name: "Propriedade",
        layout: "/admin",
        path: "/propriedades-form",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: FormPropriedades,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    // {
    //     name: "Gerentes",
    //     layout: "/admin",
    //     path: "/list-gerentes",
    //     icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
    //     component: ListaGerentes,
    //     subItens: [],
    //     visible: true,
    //     perfilAcesso: ActionTypes.PERFIL_GERENTE,
    // },
    {
        name: "Gerente",
        layout: "/admin",
        path: "/form-gerente",
        icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
        component: FormGerente,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Seringueiro",
        layout: "/admin",
        path: "/associar-seringueiro-safra",
        icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
        component: AssociarSeringueiroSafra,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Seringueiros Disponíveis",
        layout: "/admin",
        path: "/lista-seringueiros-disponiveis",
        icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
        component: ListaSeringueirosDisponiveis,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Cadastro Propriedades",
        layout: "/admin",
        path: "/propriedades-paginado",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: PropriedadesPaginado,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Safra",
        layout: "/admin",
        path: "/cadastrar-safra",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: CadastrarSafra,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== PRODUCAO =====================
    {
        name: "Produção",
        layout: "/admin",
        icon: <Icon as={MdOutlineBarChart} width='20px' height='20px' color={colorIcon} />,
        path: "/producao-coagulos",
        component: GraficoProducaoDeCoagulos,
        subItens: [
            {
                name: "Lançamento de Produção",
                layout: "/admin",
                path: "/lancar-producao",
                icon: (<Icon as={MdOutbond} width='20px' height='20px' color={colorIcon} />),
                component: LancamentoProducao,
                visible: true,
            },
            {
                name: "Produção de Coágulos",
                layout: "/admin",
                icon: <Icon as={MdGroups} width='20px' height='20px' color={colorIcon} />,
                path: "/producao-coagulos",
                component: GraficoProducaoDeCoagulos,
                visible: true,
            },
            {
                name: "Acumulado Safra",
                layout: "/admin",
                path: "/acumulado-safra",
                icon: <Icon as={MdEco} width='20px' height='20px' color={colorIcon} />,
                component: AcumuladoSafra,
                visible: true,
            },
            {
                name: "Produção por Sangria",
                layout: "/admin",
                path: "/producao-por-sangria",
                icon: <Icon as={MdEco} width='20px' height='20px' color={colorIcon} />,
                component: ProducaoSangria,
                visible: true,
            },
        ],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Lançamento de Produção",
        layout: "/admin",
        path: "/lancar-producao",
        icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
        component: LancamentoProducao,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Lançamento de Produção",
        layout: "/admin",
        path: "/lancar-valores-producao",
        icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
        component: LancarProducao,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Acumulado Safra",
        layout: "/admin",
        path: "/acumulado-safra",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: AcumuladoSafra,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Exportar PDF",
        layout: "/admin",
        path: "/gerarpdf-lancamento-producao",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: GerarPdfLancamentoProducao,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Exportar PDF",
        layout: "/admin",
        path: "/gerarpdf-acumulado-safra",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: GerarPdfAcumuladoSafra,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Produção por Sangria",
        layout: "/admin",
        path: "/producao-por-sangria",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: ProducaoSangria,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== QUALIDADE SANGRIA =====================
    {
        name: "Qualidade Sangria",
        layout: "/admin",
        path: "/lista-qualidade-sangria",
        icon: <Icon as={MdEco} width='20px' height='20px' color={colorIcon} />,
        component: ListaQualidadeSangria,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Relatório Qualidade Sangria",
        layout: "/admin",
        path: "/relatorioQualidade-sangria",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: RelatorioQualidadeSangria,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== CALENDARIO ESTIMULACAO =====================
    {
        name: "Calendário Estimulação",
        layout: "/admin",
        path: "/estimulacao",
        icon: <Icon as={MdEvent} width='20px' height='20px' color={colorIcon} />,
        component: Estimulacao,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Lançar Estimulação",
        layout: "/admin",
        path: "/lancar-estimulacao",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: LancarEstimulacao,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Relatório Estimulação",
        layout: "/admin",
        path: "/estimulacao-pdf",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: EstimulacaoPdf,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== SERINGUEIROS =====================
    {
        name: "Seringueiros",
        layout: "/admin",
        path: "/list-seringueiros",
        icon: (<Icon as={GiFarmer} width='20px' height='20px' color={colorIcon} />),
        component: ListSeringueiro,
        subItens: [
            {
                name: "Histórico Seringueiro",
                layout: "/admin",
                path: "/list-seringueiros",
                icon: (<Icon as={MdOutlineShoppingCart} width='20px' height='20px' color={colorIcon} />),
                component: ListSeringueiro,
                visible: true,
            },
        ],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_TODOS,
    },
    {
        name: "Histórico Seringueiro",
        layout: "/admin",
        path: "/historico-seringueiro",
        icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
        component: HistoricoSeringueiro,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== PREVISAO DO TEMPO =====================
    {
        name: "Previsão do Tempo",
        layout: "/admin",
        icon: <Icon as={FaCloudSunRain} width='20px' height='20px' color={colorIcon} />,
        path: "/previsao-tempo",
        component: PrevisaoTempo,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== PERFIL =====================
    {
        name: "Perfil",
        layout: "/admin",
        path: "/profile",
        icon: <Icon as={MdPerson} width='20px' height='20px' color={colorIcon} />,
        component: Profile,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_TODOS,
    },

    // ===================== PERMISSAO DE ACESSO =====================

    {
        name: "Permissões e Acesso",
        layout: "/admin",
        path: "/gerenciamento-de-acessos",
        icon: <Icon as={MdOutlineSecurity} width='20px' height='20px' color={colorIcon} />,
        component: FormPermissaoAcessosSistema,
        subItens: [
            {
                name: "Acesso ao Sistema",
                layout: "/admin",
                path: "/gerenciamento-de-acessos",
                icon: (<Icon as={MdOutlineShoppingCart} width='20px' height='20px' color={colorIcon}/>),
                component: FormPermissaoAcessosSistema,
                visible: true,
            },
            {
                name: "Acesso a Propriedade",
                layout: "/admin",
                path: "/form-permitir-acesso-propriedade",
                icon: (<Icon as={MdOutlineShoppingCart} width='20px' height='20px' color={colorIcon} />),
                component: FormPermitirAcessoPropriedade,
                visible: true,
            },
            {
                name: "Sazonalidade",
                layout: "/admin",
                path: "/sazonalidade-list",
                icon: (<Icon as={MdOutlineShoppingCart} width='20px' height='20px' color={colorIcon} />),
                component: SazonalidadeList,
                visible: true,
            },
        ],

        visible: true,
        perfilAcesso: ActionTypes.PERFIL_ADMIN,
    },
    {
        name: "Acessos No Sistema",
        layout: "/admin",
        path: "/form-permissao-acessos-sistema",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: FormPermissaoAcessosSistema,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_TODOS,
    },
    {
        name: "Acesso a Propriedade",
        layout: "/admin",
        path: "/form-permitir-acesso-propriedade",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: FormPermitirAcessoPropriedade,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Sazonalidade",
        layout: "/admin",
        path: "/sazonalidade-list",
        icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
        component: SazonalidadeList,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Sazonalidade",
        layout: "/admin",
        path: "/sazonalidade-propriedade",
        icon: (<Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />),
        component: SazonalidadePropriedade,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== RELATORIOS =====================
    {
        name: "Relatórios",
        layout: "/admin",
        path: "/relatorios",
        icon: <Icon as={MdListAlt} width='20px' height='20px' color={colorIcon} />,
        component: Relatorios,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Status Diário",
        layout: "/admin",
        path: "/status-diario",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: StatusDiario,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Indicador de Sangria",
        layout: "/admin",
        path: "/indicador-sangria",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: IndicadorSangria,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Relatórios Emitidos",
        layout: "/admin",
        path: "/relatorio-emitido-personalizado-esporadido",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: RelatorioEmitidoPersonalizadoAndEsporadido,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Atividades de Sangria",
        layout: "/admin",
        path: "/relatorio-atividades-sangria",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: AtividadesDeSangria,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== ESCOLHER TIPO DE RELATORIO PERSONALIZADO OU ESPORADICO =====================
    {
        name: "Relatórios de Visitas",
        layout: "/admin",
        path: "/escolher-tipo-relatorio",
        icon: <Icon as={MdPostAdd} width='20px' height='20px' color={colorIcon} />,
        component: EscolherTipoRelatorio,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Relatórios Personalizado",
        layout: "/admin",
        path: "/relatorios-personalizado",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: RelatorioPersonalizado,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Assunto",
        layout: "/admin",
        path: "/assunto",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: AddAssuntoPersonalizado,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Gerar Relatório",
        layout: "/admin",
        path: "/gerar-relatorio-personalizado",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: GerarRelatorioPersonalizado,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Relatórios Esporadico",
        layout: "/admin",
        path: "/relatorios-esporadico",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: RelatorioEsporadico,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Assunto",
        layout: "/admin",
        path: "/assunto-esporadico",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: AddAssuntoEsporadico,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Gerar Relatório",
        layout: "/admin",
        path: "/gerar-relatorio-esporadico",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: GerarRelatorioEsporadico,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },

    // ===================== PROGRAMACAO =====================
    {
        name: "Programação",
        layout: "/admin",
        path: "/home-programacao",
        icon: <Icon as={MdCamera} width='20px' height='20px' color={colorIcon} />,
        component: HomeProgramacao,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_TECNICO,
    },
    {
        name: "Programação",
        layout: "/admin",
        path: "/gerar-programacao",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: GerarProgramacao,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_TECNICO,
    },
    {
        name: "Re-programação",
        layout: "/admin",
        path: "/reprogramacao",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: Reprogramacao,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_TECNICO,
    },
    {
        name: "Reprogramação Individual",
        layout: "/admin",
        path: "/gerar-programacao-individual",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: GerarProgramacaoIndividual,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_TECNICO,
    },
    {
        name: "Logs da Programação",
        layout: "/admin",
        path: "/log-programacao",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: LogProgramacao,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_TECNICO,
    },
    {
        name: "Detalhar Programação",
        layout: "/admin",
        path: "/detalhar-log",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: DetalharLog,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Lancar Tarefa",
        layout: "/admin",
        path: "/lancar-tarefa",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: LancarTarefa,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Qualidade Sangria",
        layout: "/admin",
        path: "/qualidade-sangria",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: QualidadeSangria,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Pontuação",
        layout: "/admin",
        path: "/avaliacao-modal",
        icon: <Icon as={MdEco} width='20px' height='20px' color='inherit' />,
        component: AvaliacaoModal,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Galeria",
        layout: "/admin",
        path: "/galeria-imagem-video",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: GalleryImageAndVideo,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Acompanhamento Atividade Sangria",
        layout: "/admin",
        path: "/acompanhamento-atividade-sangria",
        icon: <Icon as={MdLocationOn} width='20px' height='20px' color='inherit' />,
        component: AcompanhamentoAtividadesSangria,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    {
        name: "Tarefas para lançamento",                     // EM PROCESSO
        layout: "/admin",
        path: "/lista-tarefas-seringueiro",
        icon: <Icon as={MdEco} width='20px' height='20px' color='inherit' />,
        component: ListarTarefasSeringueiro,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_GERENTE,
    },
    // {
    //     name: "Acompanhamento Atividade Sangria",           // EM PROCESSO
    //     layout: "/admin",
    //     path: "/lancar-tarefa-atividade-sangria",
    //     icon: <Icon as={MdEco} width='20px' height='20px' color='inherit' />,
    //     component: LancarTarefaAtividadeSangria,
    //     subItens: [],
    //     visible: false,
    //     perfilAcesso: ActionTypes.PERFIL_GERENTE,
    // },

    // ===================== COCUMENTACAO =====================    
    // {
    //     name: "Documentação",
    //     layout: "/admin",
    //     path: "/documentacao",
    //     icon: <Icon as={MdLibraryBooks} width='20px' height='20px' color='inherit' />,
    //     component: Documentacao,
    //     subItens: [],
    //     visible: true,
    //     perfilAcesso: ActionTypes.PERFIL_ADMIN,
    // },
    {
        name: "QR Code",
        layout: "/admin",
        path: "/qr-code-generate",
        icon: <Icon as={MdQrCode} width='20px' height='20px' color='inherit' />,
        component: QrCodeGenerate,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_ADMIN,
    },
    {
        name: "QR Code",
        layout: "/admin",
        path: "/qr-code-generate-admin",
        icon: <Icon as={MdQrCode} width='20px' height='20px' color='inherit' />,
        component: QrCodeGenerateAdmin,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_ADMIN,
    },
    {
        name: "Gestão de Propriedade",
        layout: "/admin",
        path: "/remover-pessoa-ua-safra",
        icon: <Icon as={MdOutlineConstruction} width='20px' height='20px' color='inherit' />,
        component: DeleteForm,
        subItens: [],
        visible: true,
        perfilAcesso: ActionTypes.PERFIL_ADMIN,
    },
    // {
    //     name: "Paginação",                     // EM PROCESSO
    //     layout: "/admin",
    //     path: "/paginacao-screen",
    //     icon: <Icon as={MdLibraryBooks} width='20px' height='20px' color='inherit' />,
    //     component: PaginacaoScreen,
    //     subItens: [],
    //     visible: true,
    //     perfilAcesso: ActionTypes.PERFIL_ADMIN,
    // },
    // {
    //     name: "Pagina Default",                     // EM PROCESSO
    //     layout: "/admin",
    //     path: "/screen-default",
    //     icon: <Icon as={MdLibraryBooks} width='20px' height='20px' color='inherit' />,
    //     component: ScreenDefault,
    //     subItens: [],
    //     visible: false,
    //     perfilAcesso: ActionTypes.PERFIL_ADMIN,
    // },



    // ===================== PAGINA BASICA =====================
    // {
    //     name: "Pagina Básica",
    //     layout: "/admin",
    //     path: "/pagina-basica",
    //     icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    //     component: PaginaBasica,
    //     subItens: [],
    //     visible: true,
    //     perfilAcesso: ActionTypes.PERFIL_TODOS,
    // },

    // ===================== LOGIN =====================
    {
        name: "Login",
        layout: "/auth",
        path: "/sign-in",
        icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
        component: SignIn,
        subItens: [],
        visible: false,
        perfilAcesso: ActionTypes.PERFIL_TODOS,
    },
];

export default routes;
