import React, { useState } from 'react';
import {
    Flex,
    Text,
    Box,
    Input,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Icon,
    useColorModeValue,
} from "@chakra-ui/react";
import { MdExpandMore } from "react-icons/md";

const SelectFilter = ({ items, onSelect, placeholder = 'Selecione uma opção', titulo = '' }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const filteredItems = items.filter(item =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelectItem = (item) => {
        setSelectedItem(item);
        onSelect(item);
    };

    return (
        <Box px={'5px'} my={'0px'}>
            {titulo != '' && <Text ml={'15px'} mb={'3px'} color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' >{titulo}</Text>}
            <Menu>
                <MenuButton>
                    <Flex px={'15px'} py={'6px'} w={'400px'} justify={'space-between'} align={'center'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.700'}>
                        <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' >{selectedItem ? selectedItem.label : placeholder}</Text>
                        <Icon as={MdExpandMore} h='20px' w='20px' />
                    </Flex>
                </MenuButton>
                <MenuList w={'400px'} maxH={'400px'} overflowY={'auto'}>
                    <Box p={2}>
                        <Input
                            value={searchTerm}
                            placeholder="Filtro..."
                            variant="filled"
                            borderRadius="10px"
                            borderColor={'gray.400'}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </Box>
                    {filteredItems.length > 0 ? (
                        filteredItems.map((item, index) => {
                            return (
                                <MenuItem key={index} onClick={() => handleSelectItem(item)}>
                                    <Text color={item.colorBg} fontSize='13px' fontWeight={'bold'} lineHeight={'100%'}>{item.label}</Text>
                                </MenuItem>
                            )
                        })
                    ) : (
                        <MenuItem isDisabled>
                            <Text color={textColor} fontSize='14px' fontWeight={'bold'}>{'Nenhum item encontrado'}</Text>
                        </MenuItem>
                    )}
                </MenuList>
            </Menu>
        </Box>
    );
}

export default SelectFilter;