import React, { useState, useEffect } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Flex,
    Button,
    Icon,
    Input,
    Wrap,
    Select,
    WrapItem,
    Checkbox,
    Center,
    Stack,
    RadioGroup,
    Radio,
    SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import MiniStatistics from "components/card/MiniStatistics";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../constants/ActionTypes';
import TooltipIcon from 'components/tooltip/TooltipIcon';
import { FREQUENCIA, ARRAY_TAREFA } from '../../../model/MockData';
import { MdCheckCircle, MdCircle } from "react-icons/md";
import MessageSreen from 'components/message/MessageSreen';
import Loader from 'components/loading/Loader';
import ModalDialog from 'components/modal/ModalDialog';

export default function Reprogramacao() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idUa, setIdUa] = useState('');
    const [idSafra, setIdSafra] = useState('');
    const [idUsuario, setIdUsuario] = useState(null);

    const [MONDAY, setMONDAY] = useState(true);
    const [TUESDAY, setTUESDAY] = useState(true);
    const [WEDNESDAY, setWEDNESDAY] = useState(true);
    const [THURSDAY, setTHURSDAY] = useState(true);
    const [FRIDAY, setFRIDAY] = useState(true);
    const [SATURDAY, setSATURDAY] = useState(true);
    const [SUNDAY, setSUNDAY] = useState(true);
    const [isValidDiasSemana, setIsValidDiasSemana] = useState(false);
    const [maxDate, setMaxDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [dataInicioReprogramacao, setDataInicioReprogramacao] = useState('');
    const [dtInicioSangria, setDtInicioSangria] = useState('');
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [nomeSafra, setNomeSafra] = useState('');
    const [alternanciaSelecionado, setAlternanciaSelecionado] = useState('3');
    const [listaPessoa, setListaPessoa] = useState([]);
    const [isAbilitarGeracao, setIsAbilitarGeracao] = useState(true);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [listaPessoaCompleto, setListaPessoaCompleto] = useState([]);
    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [seringueiroSelecionado, setSeringueiroSelecionado] = useState(null);
    const [isExisteProgramacao, setIsExisteProgramacao] = useState(null);
    const [messagemOpenDialog, setMessagemOpenDialog] = useState('');

    const [tarefaSelecionado, setTarefaSelecionado] = useState('1');
    const [isValidTarefa, setIsValidTarefa] = useState(false);
    const [possiveisRealizadas, setPossiveisRealizadas] = useState(0);
    const [indiceEficiencia, setIndiceEficiencia] = useState(0);
    const [corIndiceDeEficiencia, setCorIndiceDeEficiencia] = useState('green');
    const [tarefasAtrasadas, setTarefasAtrasadas] = useState(0);
    const [apagarHistorico, setApagarHistorico] = useState('NAO');
    const [desabilitaTodos, setDesabilitaTodos] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const shadowBlack = ('0px 5px 10px 2px rgba(0, 0, 0, 0.30)');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const dados = localStorage.getItem('@HE-datosParaProgramacaoIndividualAndReprogracao');
            const param = JSON.parse(dados);

            const idUsuarioP = param.idUsuario;
            const idSafraP = param.idSafra;
            const idUaP = param.idUa;
            const nomeFazendaP = param.nomeFazenda;
            const dtInicioP = param.dtInicio;
            const dtFimP = param.dtFim;
            const dtInicioSangriaP = param.dtInicioSangria;
            const nomeSafraP = param.nomeSafra;
            const dtFimSangriaP = param.dtFimSangria;
            const qtdPessoaCadastradaP = param.qtdPessoaCadastrada;
            const vigenteP = param.vigente;
            const programacaoEmAndamentoP = param.programacaoEmAndamento;

            setIdUsuario(idUsuarioP);
            setNomeFazenda(nomeFazendaP);
            setNomeSafra(nomeSafraP);
            setIdUa(idUaP);
            setIdSafra(idSafraP);
            setDtInicioSangria(dtInicioSangriaP);

            const dataAtual = moment().subtract(1, 'day').format('YYYY-MM-DD');
            setDataInicioReprogramacao(dataAtual);

            const url = `api/pessoa/listaSeringueirosParaReprogramacao?idUa=${idUaP}&idSafra=${idSafraP}&dtInicio=${dtInicioP}&dtFim=${dtFimP}&deletado=false`;
            const response = await Api.getRequest(url);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res1 = await response.respostaRequisicao.data;

                const validacao = res1.validarSafraDto;

                if (!validacao.sangriaAtiva
                    || !validacao.vigente
                    || validacao.dtInicioSafraMaiorQueDataAtual
                    || validacao.dtFimSafraMenorQueDataAtual
                    || validacao.dtInicioSangriaMaiorQueDataAtual
                    || validacao.dtFimSangriaMenorQueDataAtual
                    || validacao.dtInicioSangriaEstaNull
                    || validacao.qtdPessoaCadastrada == 0
                ) {
                    setIsAbilitarGeracao(false);
                }

                const arr = res1.listReprogramacaoItem;
                setListaPessoaCompleto(arr);

                if (arr.length > 0) {
                    let arrayPess = [];
                    await new Promise(resolve => {
                        resolve(
                            arr.map(function (item) {
                                const obj = {
                                    label: item.nome,
                                    value: `${item.idPessoa}`
                                }
                                arrayPess.push(obj);
                            })
                        )
                    });
                    setListSeringueiro(arrayPess);
                    const idPessoaX = arrayPess[0].value;
                    changePessoa(idPessoaX, arr);
                }
                setMinDate(dtInicioSangriaP);
                setMaxDate(dataAtual);
                setTelaCarregada(true);
            } else {

            }
        }
        loaderScreen();
    }, []);

    const selecionarDiasSemanaPrimeiroAcesso = async (val) => {
        switch (val) {
            case 'MONDAY':
                setMONDAY(true);
                break;
            case 'TUESDAY':
                setTUESDAY(true);
                break;
            case 'WEDNESDAY':
                setWEDNESDAY(true);
                break;
            case 'THURSDAY':
                setTHURSDAY(true);
                break;
            case 'FRIDAY':
                setFRIDAY(true);
                break;
            case 'SATURDAY':
                setSATURDAY(true);
                break;
            case 'SUNDAY':
                setSUNDAY(true);
                break;
            default:
                break;
        }
    }

    const selecionarDiasSemana = async (e) => {
        const val = e.target.value;
        switch (val) {
            case 'MONDAY':
                setMONDAY(!MONDAY);
                break;
            case 'TUESDAY':
                setTUESDAY(!TUESDAY);
                break;
            case 'WEDNESDAY':
                setWEDNESDAY(!WEDNESDAY);
                break;
            case 'THURSDAY':
                setTHURSDAY(!THURSDAY);
                break;
            case 'FRIDAY':
                setFRIDAY(!FRIDAY);
                break;
            case 'SATURDAY':
                setSATURDAY(!SATURDAY);
                break;
            case 'SUNDAY':
                setSUNDAY(!SUNDAY);
                break;
            default:
                break;
        }
    }

    const showOpenDialog = () => {
        if (apagarHistorico == 'SIM') {
            setMessagemOpenDialog('Reprogramação do zero excluindo todo histórico das tarefas anteriores! Deseja continuar?');
        } else {
            setMessagemOpenDialog('Reprogramação mantendo histórico! Deseja continuar?');
        }
        setOpenModalDialog(true);
    }

    const validarAndGerarProgramacao = async () => {
        try {
            setOpenModalDialog(false);

            let valid = false;
            const arrayDiasSemanaP = [
                { descDiaSemana: "", dayOfWeek: "MONDAY", status: MONDAY },
                { descDiaSemana: "", dayOfWeek: "TUESDAY", status: TUESDAY },
                { descDiaSemana: "", dayOfWeek: "WEDNESDAY", status: WEDNESDAY },
                { descDiaSemana: "", dayOfWeek: "THURSDAY", status: THURSDAY },
                { descDiaSemana: "", dayOfWeek: "FRIDAY", status: FRIDAY },
                { descDiaSemana: "", dayOfWeek: "SATURDAY", status: SATURDAY },
                { descDiaSemana: "", dayOfWeek: "SUNDAY", status: SUNDAY }
            ]

            const filtro = arrayDiasSemanaP.filter(x => x.status == true);
            const qtdProgramaca = filtro.length;
            const al = Number(alternanciaSelecionado);
            const ta = Number(tarefaSelecionado);
            if (al < ta) {
                setIsValidTarefa(true);
                valid = true;
            } else {
                setIsValidTarefa(false);
            }
            if (qtdProgramaca < 4) {
                setIsValidDiasSemana(true);
                valid = true;
            } else {
                setIsValidDiasSemana(false);
            }

            if (valid) return;

            const dataAtual = moment();
            const dataAtualFormatada = moment(dataAtual).format('YYYY-MM-DD');
            const dtAtualMais06Dias = moment(dataAtual).add(6, 'days').format('YYYY-MM-DD');     // YYYY-MM-DD HH:mm:ss

            let dtIniSangria = `${dataAtualFormatada} 00:00:00`;
            if (dtInicioSangria != '') {
                dtIniSangria = `${dtInicioSangria} 00:00:00`
            }

            const idTipoReprogramacaoP = apagarHistorico == 'SIM' ? ActionTypes.ID_TIPO_REPROGRAMACAO_APAGAR_HISTORICO : ActionTypes.ID_TIPO_REPROGRAMACAO_MANTER_HISTORICO;

            const jsonPessoa = {
                idUsuario: idUsuario,
                idPessoa: seringueiroSelecionado,
                idTipoPessoa: ActionTypes.ID_TIPO_PESSOA_SANGRADOR,
                idUa: idUa,
                idSafra: idSafra,
                qtdTarefas: alternanciaSelecionado,
                qtdProgramacaoSemana: qtdProgramaca,
                dtInicio: `${dataAtualFormatada} 00:00:00`,
                dtInicioSangria: dtIniSangria,
                dtFim: `${dtAtualMais06Dias} 00:00:00`,
                arrayDiasSemana: arrayDiasSemanaP,
                gerarComDadosIndividualDoSeringueiro: true,
                sangriaAtiva: true,
                programacaoEmAndamento: true,
                dtReprogramacao: dataInicioReprogramacao,
                idTipoReprogramacao: idTipoReprogramacaoP,
                permitirRodarProgramacao: true,
                idTarefaInicial: tarefaSelecionado,
            }
            setLoading(true);
            const response = await Api.postRequest(`api/programacao/gerarReprogramacaoParaUmSeringueiro`, jsonPessoa);
            setLoading(false);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setLoading(false);
                const res = await response.respostaRequisicao.data;
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            setLoading(false);
            console.log('Error na classe Reprogramacao metodo validarAndGerarProgramacao', error);
        }
    }

    const showAlert = () => {
        window.scrollTo(0, 0);

        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const changeItemAlternacia = async (item) => {
        const val = item.value;
        setAlternanciaSelecionado(val);
        const al = Number(val);
        const ta = Number(tarefaSelecionado);
        if (al < ta) {
            setIsValidTarefa(true);
        } else {
            setIsValidTarefa(false);
        }
    }

    const changeTarefa = async (val2) => {
        const val = val2.value;
        setTarefaSelecionado(val);
        const al = Number(alternanciaSelecionado);
        const ta = Number(val);
        if (al < ta) {
            setIsValidTarefa(true);
        } else {
            setIsValidTarefa(false);
        }
    }

    const changePessoa = async (val, listaPessoaCompletoParam) => {
        try {
            setSeringueiroSelecionado(val);
            setMONDAY(false);
            setTUESDAY(false);
            setWEDNESDAY(false);
            setTHURSDAY(false);
            setFRIDAY(false);
            setSATURDAY(false);
            setSUNDAY(false);

            if (val != null) {
                const arFilter = listaPessoaCompletoParam.filter(x => x.idPessoa == Number(val));
                const obj = arFilter[0];
                obj.listaDiasUteis.map(x => {
                    const desc = x != null && x != '' ? x : '';
                    selecionarDiasSemanaPrimeiroAcesso(`${desc}`);
                })

                const atrasadasP = obj.atrasadas != null && obj.atrasadas != '' ? obj.atrasadas : '0';
                const percentualIndiceEficienciaP = obj.percentualIndiceEficiencia != null && obj.percentualIndiceEficiencia != '' ? `${obj.percentualIndiceEficiencia}` : '0';
                const percentualSangriasPossiveisRealizadasP = obj.percentualSangriasPossiveisRealizadas != null && obj.percentualSangriasPossiveisRealizadas != '' ? `${obj.percentualSangriasPossiveisRealizadas}` : '0';

                setIndiceEficiencia(percentualIndiceEficienciaP);
                setTarefasAtrasadas(atrasadasP);
                setPossiveisRealizadas(percentualSangriasPossiveisRealizadasP);
                let indEficiencia = Number(percentualIndiceEficienciaP);

                const constSoParaCalculo = parseFloat(indEficiencia.toFixed(0));
                let corN = 'black';
                if (constSoParaCalculo >= 95) {
                    corN = 'green';
                } else if (constSoParaCalculo <= 94 && constSoParaCalculo >= 90) {
                    corN = 'orange';
                } else {
                    corN = 'red';
                }
                setCorIndiceDeEficiencia(corN);
                const ss = obj.idQuantidadeTarefas != null && obj.idQuantidadeTarefas != '' ? `${obj.idQuantidadeTarefas}` : '3';
                setAlternanciaSelecionado(ss);
                setIsExisteProgramacao(obj.isExisteProgramacao != true ? false : true);
                setListaPessoa(arFilter);
            } else {
                setListaPessoa([]);
            }
        } catch (error) {
            console.log('Error na classe Reprogramacao metodo changePessoa', error);
        }
    }

    function renderTitulo() {
        return (
            <Box>
                <Flex flexDirection={'column'} w={'100%'} justify={'start'} align="start">
                    <Flex justify={'start'} align="center">
                        <Text color={'gray.700'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Propriedade:'}</Text>
                        <Text color={'gray.700'} fontSize='15px' fontWeight={'light'} lineHeight='100%' ml={'10px'}>{nomeFazenda}</Text>
                    </Flex>
                    <Flex justify={'start'} align="center">
                        <Text color={'gray.700'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Safra:'}</Text>
                        <Text color={'gray.700'} fontSize='15px' fontWeight={'light'} lineHeight='100%' ml={'10px'}>{nomeSafra}</Text>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderSeringueirosCombo() {
        return (
            <Box mt={'10px'}>
                <Flex flexDirection={'column'} w={'100%'} justify={'center'} align="start">
                    <Box ml={'10px'}>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Seringueiros</Text>
                    </Box>
                    <Select
                        value={seringueiroSelecionado}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={(e) => changePessoa(e.target.value, listaPessoaCompleto)}
                        w={'350px'}
                        h={'35px'}
                    >
                        {listSeringueiro.map((item, index) => {
                            return (
                                <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Flex>
            </Box>
        )
    }

    function renderStatusAtual() {
        return (
            <Box position={'relative'} px={'10px'} py={'5px'} mt={'10px'} borderWidth={'2px'} borderColor={'blackAlpha.400'} borderRadius={'20px'} boxShadow={shadowBlack}>
                <Text color={textColor} fontSize='18px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Status Atual'}</Text>
                <SimpleGrid columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }} gap='15px' mb='10px' mt={'10px'}>
                    <MiniStatistics textColorValue={'red.500'} name='Tarefas Atrasadas' value={tarefasAtrasadas} />
                    <MiniStatistics name='Possíveis Realizadas' value={`${possiveisRealizadas}%`} />
                    <MiniStatistics textColorValue={corIndiceDeEficiencia} name='Índice de Eficiência' value={`${indiceEficiencia}%`} />
                </SimpleGrid>
                <Box position={'absolute'} top={'1px'} right={'10px'}>
                    {seringueiroSelecionado != null ?
                        <Box>
                            {isExisteProgramacao ?
                                <Flex px={'10px'} py={'5px'} justify='end' align='center' bg={'green.400'} borderRadius={'10px'} borderWidth={'1px'}>
                                    <Text color={'white'} fontSize='13px' fontWeight='bold' lineHeight='100%'>{'Com Programação'}</Text>
                                </Flex>
                                :
                                <Flex px={'10px'} py={'5px'} justify='end' align='center' bg={'#ffff66'} borderRadius={'10px'} borderWidth={'1px'}>
                                    <Text color={'black'} fontSize='13px' fontWeight='bold' lineHeight='100%'>{'Sem Programação'}</Text>
                                </Flex>
                            }
                        </Box>
                        :
                        <Flex px={'10px'} py={'5px'} justify='center' align='center' bg={'green.400'} borderRadius={'10px'} borderWidth={'1px'}>
                            <Text color={'white'} fontSize='13px' fontWeight='bold' lineHeight='100%'>{''}</Text>
                        </Flex>
                    }
                </Box>
            </Box>
        )
    }

    function renderListaSeringueiros() {
        let corLinha = false;
        return (
            <Box mt={'20px'} px={'10px'} borderWidth={'2PX'} borderRadius={'10px'} borderColor={'blackAlpha.700'}>
                <Flex w={'100%'} justify='space-between' align='center'>
                    <Box w={'42%'}>
                        <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{''}</Text>
                    </Box>
                    <Box w={'50px'}>
                        <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{''}</Text>
                    </Box>
                    <Box w={'52%'}>
                        <Flex justify='space-between' align='center'>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' align={'center'}>{'S'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' align={'center'}>{'T'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' align={'center'}>{'Q'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' align={'center'}>{'Q'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' align={'center'}>{'S'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' align={'center'}>{'S'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' align={'center'}>{'D'}</Text>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
                {listaPessoa.map((item, index) => {
                    const bgName = item.isExisteProgramacao != true ? '#ffff4d' : 'transparent';
                    corLinha = !corLinha;
                    return (
                        <Flex key={index} w={'100%'} justify='space-between' align='center' borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} bg={'blackAlpha.50'}>
                            <Box py={'5px'} w={'42%'} bg={bgName} borderRadius={'5px'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'start'}>{item.nome}</Text>
                            </Box>
                            <Box my={'3px'} w={'50px'}>
                                <Box py={'5px'} w={'35px'} bg={'orange.600'} borderRadius={'5px'}>
                                    <Text color={'white'} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'center'}>{`D${item.idQuantidadeTarefas}`}</Text>
                                </Box>
                            </Box>
                            <Box w={'52%'}>
                                <Flex justify='space-between' align='center'>
                                    {item.listaDiasUteis != null && item.listaDiasUteis.length > 0 ?
                                        item.listaDiasUteis.map((x, y) => {
                                            let corIcon = 'green';
                                            let isIcone = false;

                                            if (x == 'MONDAY') {
                                                isIcone = true;
                                            } else if (x == 'TUESDAY') {
                                                isIcone = true;
                                            } else if (x == 'WEDNESDAY') {
                                                isIcone = true;
                                            } else if (x == 'THURSDAY') {
                                                isIcone = true;
                                            } else if (x == 'FRIDAY') {
                                                isIcone = true;
                                            } else if (x == 'SATURDAY') {
                                                isIcone = true;
                                            } else if (x == 'SUNDAY') {
                                                isIcone = true;
                                            } else {
                                                isIcone = false;
                                                corIcon = 'gray.300';
                                            }
                                            return (
                                                <Flex key={y} w={'100%'} justify='center' align='center'>
                                                    <Icon as={isIcone ? MdCheckCircle : MdCircle} width='20px' height='20px' color={corIcon} />
                                                </Flex>
                                            )
                                        })
                                        :
                                        <Text color={textColor} fontSize='14px' fontWeight='bold' >{'S'}</Text>
                                    }
                                </Flex>
                            </Box>
                        </Flex>
                    )
                })}
            </Box>
        )
    }

    function renderFrequenciaAndTarefaAndData() {
        return (
            <Box mt={'30px'}>
                <Wrap mt={'10px'} spacing='30px' justify={'start'} align={'center'}>
                    <WrapItem>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'} align={'left'}>Ínício da Reprogramação</Text>
                            <Flex justify={'start'} align="center">
                                <Input
                                    value={dataInicioReprogramacao}
                                    variant='filled'
                                    onChange={e => setDataInicioReprogramacao(e.target.value)}
                                    errorBorderColor='crimson'
                                    placeholder=""
                                    borderColor={textColor}
                                    borderRadius="10px"
                                    size="md"
                                    type="date"
                                    min={minDate}
                                    max={maxDate}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    w={'200px'}
                                />
                                <Center py={'0px'} h={'100%'} ml={'5px'} justifyContent={'center'} alignItems={'center'}>
                                    <TooltipIcon
                                        label={
                                            <Box px={'0px'} py={'5px'} >
                                                <Text align={'center'}>{'Ínício da Reprogramação'}</Text>
                                                <Text align={'start'} mt={'5px'}>{'Início de processamento da programação'}</Text>
                                            </Box>
                                        }
                                        height={'23px'} />
                                </Center>
                            </Flex>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Text color={textColor} opacity={apagarHistorico == 'SIM' ? '1' : '0.5'} fontSize='13px' fontWeight={'bold'} ml={'10px'} align={'left'}>Tarefa de início</Text>
                            <Flex justify={'start'} align="center">
                                <Select
                                    value={tarefaSelecionado}
                                    errorBorderColor='crimson'
                                    disabled={apagarHistorico == 'SIM' ? false : true}
                                    placeholder="selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    w={'200px'}
                                    onChange={(e) => changeTarefa(e.target.value)}
                                >
                                    {ARRAY_TAREFA.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Flex>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box mt={{ base: 4, md: 0 }}>
                            <Text color={textColor} opacity={apagarHistorico == 'SIM' ? '1' : '0.5'} fontSize='13px' fontWeight={'bold'} ml={'10px'} align={'left'}>Frequência</Text>
                            <Flex justify={'start'} align="center">
                                <Select
                                    value={alternanciaSelecionado}
                                    errorBorderColor='crimson'
                                    disabled={apagarHistorico == 'SIM' ? false : true}
                                    placeholder="selecione"
                                    variant="filled"
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                    w={'200px'}
                                    onChange={(e) => changeItemAlternacia(e.target.value)}
                                >
                                    {FREQUENCIA.map((item, index) => {
                                        return (
                                            <option key={index} style={{ background: 'white' }} value={item.value} >{item.label}</option>
                                        )
                                    })}
                                </Select>
                            </Flex>
                        </Box>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderRadio() {
        return (
            <Box mt={'10px'} my={'30px'} p={'10px'} borderWidth={'2px'} borderColor={'blackAlpha.400'} borderRadius={'20px'} boxShadow={shadowBlack}>
                <Flex flexDirection={'column'} justify={'start'} align={'start'}>
                    <Flex mb={'20px'} w={'100%'} justify={'center'} align={'center'}>
                        <Text color={textColor} fontSize='18px' fontWeight='bold' lineHeight='100%'>{'Selecione o tipo da reprogramação'}</Text>
                    </Flex>
                    <RadioGroup w={'100%'} onChange={setApagarHistorico} value={apagarHistorico} defaultValue='2'>
                        <Stack spacing={5} direction='column'>
                            <Radio size='lg' name='1' colorScheme='blue' value='NAO' borderWidth={'2px'} borderColor={'blue.500'} justifyContent={'start'} alignItems={'start'}>
                                <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Reprogramar mantendo o histórico, detalhes dessa opção: '}</Text>
                                <Box ml={'15px'}>
                                    <Flex mt={'10px'} justify={'start'} align={'center'}>
                                        <Icon as={MdCircle} width='10px' height='10px' color={'gray.700'} />
                                        <Text ml={'5px'} color={'gray.500'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Terá impacto somente na tela de lançamento de tarefas'}</Text>
                                    </Flex>
                                    <Text mt={'3px'} ml={'15px'} color={'gray.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%'>{'- As iformações na tela de lançamento de tarefas, serão carregadas a partir da data de início da progarmação '}</Text>
                                </Box>
                            </Radio>
                            <Box w={'100%'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} />
                            <Radio size='lg' name='1' colorScheme='blue' value='SIM' borderWidth={'2px'} borderColor={'blue.500'} justifyContent={'start'} alignItems={'start'}>
                                <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Reprogramar do zero excluindo todo histórico, detalhes dessa opção:'}</Text>
                                <Box ml={'15px'}>
                                    <Flex mt={'10px'} justify={'start'} align={'center'}>
                                        <Icon as={MdCircle} width='10px' height='10px' color={'red.600'} />
                                        <Text ml={'5px'} color={'red.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Apaga todas as tarefas anteriores:'}</Text>
                                    </Flex>
                                    <Text mt={'3px'} ml={'15px'} color={'red.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%'>{'- Pendentes'}</Text>
                                    <Text mt={'3px'} ml={'15px'} color={'red.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%'>{'- Realizadas'}</Text>
                                    <Text mt={'3px'} ml={'15px'} color={'red.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%'>{'- Atrasadas'}</Text>
                                    <Text mt={'3px'} ml={'15px'} color={'red.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%'>{'- Realizadas fora do prazo'}</Text>
                                    <Text mt={'3px'} ml={'15px'} color={'red.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%'>{'- Não Realizada'}</Text>
                                    <Flex mt={'5px'} justify={'start'} align={'center'}>
                                        <Icon as={MdCircle} width='10px' height='10px' color={'red.600'} />
                                        <Text ml={'5px'} color={'red.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Qualidade de sangria'}</Text>
                                    </Flex>
                                    <Flex mt={'5px'} justify={'start'} align={'center'}>
                                        <Icon as={MdCircle} width='10px' height='10px' color={'red.600'} />
                                        <Text ml={'5px'} color={'red.600'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Estimulação'}</Text>
                                    </Flex>
                                </Box>
                            </Radio>
                        </Stack>
                    </RadioGroup>
                </Flex>
            </Box>
        )
    }

    function renderCheckbox() {
        return (
            <Box>
                <Wrap spacing='2px' mx={'15px'}>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={MONDAY}
                                    value={'MONDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Segunda - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={TUESDAY}
                                    value={'TUESDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Terça - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={WEDNESDAY}
                                    value={'WEDNESDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Quarta - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={THURSDAY}
                                    value={'THURSDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Quinta - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={FRIDAY}
                                    value={'FRIDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Sexta - feira</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={SATURDAY}
                                    value={'SATURDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Sábado</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                    <WrapItem>
                        <Box w='180px' h='50px'>
                            <Center w='100%' h='50px' alignItems="center" justifyContent="start" >
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    isChecked={SUNDAY}
                                    value={'SUNDAY'}
                                    onChange={selecionarDiasSemana}
                                    disabled={desabilitaTodos}
                                >
                                    <Text color={textColor} fontSize='13px' fontWeight='bold' lineHeight='100%'>Domingo</Text>
                                </Checkbox>
                            </Center>
                        </Box>
                    </WrapItem>
                </Wrap>
                {isValidDiasSemana &&
                    <Box p={2} display={{ md: 'flex' }} mx={'2px'} alignItems="start" justifyContent="start">
                        <Box flexDirection={'column'} >
                            <Text color={'red'} fontSize='18px' lineHeight='100%' p={'5px'}>Selecione pelo menos quatro dias</Text>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    function renderButton() {
        return (
            <Box mt={'20px'}>
                <Flex px='10px' justify='space-between' align='center'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                    <Button isDisabled={!isAbilitarGeracao} variant="brand" onClick={() => { showOpenDialog() }}>Gerar Re-Programação</Button>
                </Flex>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px={'10px'} py={'10px'}>
                {isVisibleAlert && renderAlert()}
                {renderTitulo()}
                {renderSeringueirosCombo()}
                {renderStatusAtual()}
                {renderListaSeringueiros()}
                {renderFrequenciaAndTarefaAndData()}
                {renderRadio()}
                {renderCheckbox()}
                {renderButton()}
            </Card>
            {loading && <Loader isOpen={loading} />}
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { validarAndGerarProgramacao() }} onPressCancel={() => { setOpenModalDialog(false) }} descricao={messagemOpenDialog} titulo={'Reprogramação'} />}
        </Box>
    );
}
