import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
    useColorModeValue,
    Text,
    Flex,
    Button,
    Icon,
    Center,
    Textarea,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Input,
    Wrap,
    Image,
    CloseButton,
    Divider,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import { IoTrashSharp, IoPencil, IoAddCircle, IoCloudUpload } from "react-icons/io5";
import { CloseIcon } from '@chakra-ui/icons';
import * as ActionTypes from '../../../../constants/ActionTypes';

const arrAn = [
    { idRelatorioPersonalizado: 1, tipoAssunto: '1', idAssunto: 1, nomeAssunto: 'Controle de Insetos, Pulgas e Carrapatos Esporádico', sugestoesTecnicas: 'Sugestão Técnica 01 Sugestão Técnica 01 Sugestão Técnica 01 Sugestão Técnica 01 Sugestão Técnica 01 Sugestão Técnica 01', local: 'Talhão 01', prazo: 'Anual', responsavel: 'Renato Santos 01' },
    { idRelatorioPersonalizado: 2, tipoAssunto: '1', idAssunto: 2, nomeAssunto: 'Desinfecção de Ambientes Esporádico', sugestoesTecnicas: 'Sugestão Técnica 02', local: 'Talhão 02', prazo: 'Mensal', responsavel: 'Renato Santos 02' },
    { idRelatorioPersonalizado: 3, tipoAssunto: '2', idAssunto: null, nomeAssunto: 'Desratizaçao 33 Esporádico', sugestoesTecnicas: 'Sugestão Técnica 03 Sugestão Técnica 03 Sugestão Técnica 03', local: 'Talhão 03', prazo: 'Semanal', responsavel: 'Renato Santos 03' },
]

const jsonPers = {
    opcaoEsporadico1: true,
    opcaoEsporadico2: true,
    opcaoEsporadico3: true,
}

const arrIndicadoresMensais = [
    'Neste sentido.',
    'Neste sentido, o desafiador cenário globalizado ainda não demonstrou convincentemente que vai participar na mudança das diretrizes de desenvolvimento para o futuro.',
    'No entanto, não podemos esquecer que o consenso sobre a necessidade de qualificação faz parte de um processo de gerenciamento de alternativas às soluções ortodoxas.',
    'Podemos já vislumbrar o modo pelo qual a revolução dos costumes não pode mais se dissociar.'
]

export default function RelatorioEsporadico() {

    const history = useHistory();
    const [arrayAnunssios, setArrayAnunssios] = useState([]);
    const [comentarioUm, setComentarioUm] = useState('');
    const [comentarioIndicador, setComentarioIndicador] = useState('');
    const [listComentarios, setListComentarios] = useState([]);
    const [isValidSugestoesTecnicas, setIsValidSugestoesTecnicas] = useState(false);
    const [openProducao, setOpenProducao] = useState(false);
    const [objPermissoes, setObjPermissoes] = useState({});

    const [openAssunto, setOpenAssunto] = useState(false);
    const [openObservacoesDiversas, setOpenObservacoesDiversas] = useState(false);
    const [isExpandedAssunto, setIsExpandedAssunto] = useState(false);
    const [isExpandedObservacoesDiversas, setIsExpandedObservacoesDiversas] = useState(false);
    const [isExpandedProd, setIsExpandedProd] = useState(false);

    const [arrayFilesProducao, setArrayFilesProducao] = useState([]);
    const [messageFileProducao, setMessageFileProducao] = useState('');
    const [isCheckProducao, setIsCheckProducao] = useState(false);
    const [objOpcoesPersonalizado, setObjOpcoesPersonalizado] = useState(jsonPers);

    const [isValidPendenciasAcoesDeCampo, setIsValidPendenciasAcoesDeCampo] = useState(false);
    const [isValidObservacoesDiversasComentario, setIsValidObservacoesDiversasComentario] = useState(false);
    const [isValidIndicadoresMensais, setIsValidIndicadoresMensais] = useState(false);
    const [isShowCamposObrigatorios, setShowCamposObrigatorios] = useState(false);

    const boxBg = useColorModeValue("white", "whiteAlpha.100");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorInfo = useColorModeValue("gray.400", "white");
    const sizeButtonCard = '25px';
    const gradientColorBlack = "linear-gradient(0.07deg, gray.400 -10.41%, gray.100 28.04%, gray.100 71.85%, gray.200 90.49%, gray.400 112.49%)";

    useEffect(() => {
        async function loaderScreen() {
            try {
                if (!ActionTypes.PRODUCAO) {
                    localStorage.setItem('@HE-array-assunto-esporadico', JSON.stringify(arrAn));
                    localStorage.setItem('@HE-array_IndicadoresMensais', JSON.stringify(arrIndicadoresMensais));
                    setComentarioUm('É claro que a consolidação das estruturas facilita a criação das condições inegavelmente apropriadas.');
                }

                const objPer = localStorage.getItem('@HE-opcoes-relatorio-esporadico');
                const objOpcoesPersonalizadoP = JSON.parse(objPer);
                setObjOpcoesPersonalizado(objOpcoesPersonalizadoP);

                const val = localStorage.getItem('@HE-obj-com-permissoes-acesso');
                const obj = JSON.parse(val);
                setObjPermissoes(obj);

                const arr = localStorage.getItem('@HE-array-assunto-esporadico');
                if (arr != null) {
                    const xx = JSON.parse(arr);
                    xx.sort(function (a, b) { return a.idAssunto - b.idAssunto });
                    setArrayAnunssios(xx);
                }

                const com = localStorage.getItem('@HE-comentario-tela-observacoes-esporadico');
                if (com != null) {
                    setComentarioUm(com);
                }

                const list = localStorage.getItem('@HE-array_IndicadoresMensais');
                if (list != null) {
                    const ll = JSON.parse(list);
                    setListComentarios(ll);
                }

            } catch (error) {
                console.log('Erro na classe RelatorioEsporadico metodo useEffect', error);
            }
        }
        loaderScreen();
    }, [])

    const addComentario = (val) => {
        if (val == null) return;
        const arr = [...listComentarios, val];
        setListComentarios(arr);
        localStorage.setItem('@HE-array_IndicadoresMensais', JSON.stringify(arr));
        setComentarioIndicador('');
    }

    const deleteComentario = (deleteIndex) => {
        const arr = listComentarios.filter((value, index) => index != deleteIndex);
        setListComentarios(arr);
        localStorage.setItem('@HE-array_IndicadoresMensais', JSON.stringify(arr));
    }

    const adicionarAssunto = () => {
        const json = {
            idRelatorioPersonalizado: 0,
            idAssunto: 0,
            sugestoesTecnicas: '',
            nomeAssunto: '',
            local: '',
            prazo: '',
            responsavel: '',
            tipoAssunto: ''
        }
        localStorage.setItem('@HE-dados-tela-assunto-esporadico', JSON.stringify(json));
        history.push("/admin/assunto-esporadico");
    }

    const deletarAssunto = async (item) => {
        const fil = arrayAnunssios.filter(x => x.idAssunto != item.idAssunto);
        setArrayAnunssios(fil);
        localStorage.setItem('@HE-array-assunto-esporadico', JSON.stringify(fil));
    }

    const editarAssunto = (item) => {
        localStorage.setItem('@HE-dados-tela-assunto-esporadico', JSON.stringify(item));
        history.push("/admin/assunto-esporadico");
    }

    const handleExpandAssunto = () => {
        setOpenAssunto(true);
        setIsExpandedAssunto(true);
    };
    const handleCollapseAssunto = () => {
        setOpenAssunto(false);
        setIsExpandedAssunto(false);
    };
    const handleExpandObservacoesDiversas = () => {
        setOpenObservacoesDiversas(true);
        setIsExpandedObservacoesDiversas(true);
    };
    const handleCollapseObservacoesDiversas = () => {
        setOpenObservacoesDiversas(false);
        setIsExpandedObservacoesDiversas(false);
    };
    const handleExpandProd = () => {
        setOpenProducao(true);
        setIsExpandedProd(true);
    };
    const handleCollapseProd = () => {
        setOpenProducao(false);
        setIsExpandedProd(false);
    };

    const gerarRelatorio = () => {
        setShowCamposObrigatorios(false);
        let valid = false;

        if (comentarioUm != '') {
            localStorage.setItem('@HE-comentario-tela-observacoes-esporadico', comentarioUm);
        }

        if (objOpcoesPersonalizado.opcaoEsporadico1) {
            const arr = localStorage.getItem('@HE-array-assunto-esporadico');
            const is = arr != null ? false : true;
            setIsValidPendenciasAcoesDeCampo(is);
            if(is) valid = true;
        }
        if (objOpcoesPersonalizado.opcaoEsporadico2) {
            const arr = localStorage.getItem('@HE-comentario-tela-observacoes-esporadico');
            const is = arr != null ? false : true;
            setIsValidObservacoesDiversasComentario(is);
            if(is) valid = true;
        }
        if (objOpcoesPersonalizado.opcaoEsporadico3) {
            const arr = localStorage.getItem('@HE-array_IndicadoresMensais');
            const is = arr != null ? false : true;
            setIsValidIndicadoresMensais(is);
            if(is) valid = true;
        }
        if (valid) {
            setShowCamposObrigatorios(true);
            window.scrollTo(0, 0);
            return;
        }else{
            history.push("/admin/gerar-relatorio-esporadico");
        }
    }

    // INCICADORES MENSAIS
    const anexarImageProducao = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const nomeFile = file.name;
            const verifyExist = arrayFilesProducao.filter(x => x.name == file.name);     // CHECK IF THEN FILE EXISTS
            if (verifyExist.length > 0) {
                setMessageFileProducao('O arquivo já foi adicionado');
                setIsCheckProducao(true);
                return;
            }
            const readerProduca = new FileReader();
            readerProduca.onloadend = () => {
                const base64String = readerProduca.result;
                const objFile = {
                    nomeFile: nomeFile,
                    fileBase64: base64String
                }
                if (arrayFilesProducao.length > 0) {
                    const newArray = [objFile];
                    const arr = [...arrayFilesProducao, ...newArray];
                    setArrayFilesProducao(arr);
                    localStorage.setItem('@HE-array-imagens-indicadores-mensais', JSON.stringify(arr));
                } else {
                    const newArray = [objFile];
                    setArrayFilesProducao(newArray);
                    localStorage.setItem('@HE-array-imagens-indicadores-mensais', JSON.stringify(newArray));
                }
            };
            readerProduca.readAsDataURL(file);
        }
    };
    const deleteImagemProducao = (item) => {
        const array = arrayFilesProducao.filter(x => x.name != item.name);
        setArrayFilesProducao(array);
        localStorage.setItem('@HE-array-imagens-indicadores-mensais', JSON.stringify(array));
    }

    function renderCardAssunto() {
        const sizeTitle = '15px';
        return (
            <Card align='center' direction='column' w='100%'>
                <Flex px={'5'} mt={'10px'} align={'center'} justify={'space-between'}>
                    <Text color={textColor} fontSize='18px' fontWeight='700' lineHeight='100%'>Assunto</Text>
                    <Button h="35px" w={'120px'} bg="transparent" borderWidth={'3px'} borderColor={'blackAlpha.600'} onClick={adicionarAssunto}>
                        <Flex justify={'center'} alignItems={'center'} px={'10px'} py={'5px'}>
                            <Text color={textColor} fontSize='14px' fontWeight={'bold'} mr={'5px'}>Adicionar</Text>
                            <Icon as={IoAddCircle} width={sizeButtonCard} height={sizeButtonCard} color='green.500' />
                        </Flex>
                    </Button>
                </Flex>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'}>
                    {arrayAnunssios.length > 0 ?
                        arrayAnunssios.map((item, index) => {
                            return (
                                <Card key={index} py='10px' align='center' justify={'start'}  bgGradient={gradientColorBlack}>
                                    <Box w={'100%'} py={'5'}>

                                        <Box w={'100%'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Assunto</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.nomeAssunto}</Text>
                                        </Box>
                                        <Divider w={'100%'} h={'1px'} bg={textColor} orientation='horizontal' mt={'1'}/>
                                        <Box w={'100%'} mt={'10px'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Sugestões Técnicas</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.sugestoesTecnicas}</Text>
                                        </Box>
                                        <Divider w={'100%'} h={'1px'} bg={textColor} orientation='horizontal' mt={'1'}/>
                                        <Box w={'100%'} mt={'10px'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Local</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.local}</Text>
                                        </Box>
                                        <Divider w={'100%'} h={'1px'} bg={textColor} orientation='horizontal' mt={'1'}/>
                                        <Box w={'100%'} mt={'10px'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Responsável</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.responsavel}</Text>
                                        </Box>
                                        <Divider w={'100%'} h={'1px'} bg={textColor} orientation='horizontal' mt={'1'}/>
                                        <Box w={'100%'} mt={'10px'}>
                                            <Text color={textColor} fontSize={sizeTitle} fontWeight={'bold'} lineHeight='100%' align={'start'}>Prazo</Text>
                                            <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} align={'start'}>{item.prazo}</Text>
                                        </Box>
                                    </Box>
                                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                                        <Box w={'100%'}>
                                            <Flex justify={'space-between'}>
                                                <Button
                                                    rightIcon={<IoTrashSharp size={'15px'} />}
                                                    color={'white'}
                                                    bg={'blue.900'}
                                                    h={'30px'} 
                                                    w={'100px'}
                                                    onClick={() => { deletarAssunto(item) }}
                                                >
                                                    Excluir
                                                </Button>
                                                <Button
                                                    rightIcon={<IoPencil size={'15px'} />}
                                                    color={'white'}
                                                    bg={'blue.900'}
                                                    h={'30px'} 
                                                    w={'100px'}
                                                    onClick={() => { editarAssunto(item) }}
                                                >
                                                    Editar
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Card>
                            )
                        })
                        :
                        <Text color={textColorInfo} fontSize='14px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Adicione um novo assunto'}</Text>
                    }
                </SimpleGrid>
            </Card>
        )
    }

    function renderObservacoesDiversasComentarios() {
        return (
            <Box px={'20px'} mb={'5'}>
                <Center justifyContent={'center'}>
                    <Textarea
                        value={comentarioUm}
                        onChange={(e) => setComentarioUm(e.target.value)}
                        isInvalid={isValidSugestoesTecnicas}
                        placeholder='Digite o assunto'
                        size='sm'
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                    />
                </Center>
            </Box>
        )
    }

    function renderIndicadoresMensais() {
        return (
            <Box>
                {openProducao &&
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} >
                        <Box w={'100%'}>
                            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} px={'20px'} mb={'3'}>
                                <Button
                                    rightIcon={<IoAddCircle size={'25px'} />}
                                    border={'1px'}
                                    borderRadius={'10px'}
                                    color={'green.500'}
                                    onClick={() => { addComentario(comentarioIndicador) }}
                                    isDisabled={comentarioIndicador.trim().length > 4 ? false : true}
                                >
                                    <Text color={textColor} fontSize='15px' fontWeight={'bold'}>Add Comentário</Text>
                                </Button>
                            </Box>
                            <Box px={'20px'}>
                                <Center justifyContent={'center'}>
                                    <Textarea
                                        value={comentarioIndicador}
                                        onChange={(e) => setComentarioIndicador(e.target.value)}
                                        placeholder='Mínimo de 5 caracteres'
                                        size='sm'
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                    />
                                </Center>
                            </Box>
                            <Box mt={'3'}>
                                {listComentarios.map((item, index) => {
                                    return (
                                        <Box key={index} px={'5'} py={'1'}>
                                            <Flex mt={'2'} minH={'10'} align={'flex-end'} justify={'space-between'} borderBottom={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.500'}>
                                                <Box w={'100%'} px={'15px'}>
                                                    <Text color={textColor} fontSize='15px' fontWeight={'medium'}>{item}</Text>
                                                </Box>
                                                <CloseButton variant={'brand'} mx={'2'} mb={'1'} size="sm" color={'white'} bg={'red.600'} _hover={{ opacity: '0.6' }} onClick={() => { deleteComentario(index) }} />
                                            </Flex>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Box w={'100%'} px={'20px'} my={'20px'}>
                            {isCheckProducao &&
                                <Text color={'red.500'} fontSize='14px' fontWeight='700' ml={'10px'} mb={'10px'} >{messageFileProducao}</Text>
                            }
                            <Flex px='0px' justify='space-between' mb='20px' align='center' >
                                <label htmlFor="fileUpP">
                                    <Flex _hover={{ bg: 'rgba(0, 0, 0, 0.1)' }} justify={'center'} alignItems={'center'} px={'10px'} py={'5px'} cursor={'pointer'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.400'}>
                                        <Text color={textColor} fontSize='15px' fontWeight={'bold'} mr={'10px'}>Inserir imagem</Text>
                                        <Icon as={IoCloudUpload} width={sizeButtonCard} height={sizeButtonCard} color={'blue.600'} />
                                    </Flex>
                                </label>
                            </Flex>
                            <Input
                                id="fileUpP"
                                type="file"
                                onChange={anexarImageProducao}
                                display={'none'}
                                accept=",image/*"
                            />
                            <Box ml={'20px'} >
                                {arrayFilesProducao.length > 0 &&
                                    arrayFilesProducao.map((item, index) => {
                                        return (
                                            <Box key={index}>
                                                <Flex justify='start' mb={'3'} py={'1'} align='end' borderBottom={'1px'} borderColor={'blackAlpha.400'}>
                                                    <Image boxSize='40px' ml={'0'} objectFit='contain' src={item.fileBase64} alt={'alt'} />
                                                    <Text color={textColor} fontSize='15px' fontWeight='100' mx={'4'}>{item.nomeFile}</Text>
                                                    <Button
                                                        rightIcon={<CloseIcon fontSize={'10px'} fontWeight={'bold'} mr={'8px'} />}
                                                        bg={'orange.600'}
                                                        color={'white'}
                                                        size='xs'
                                                        w={'25px'}
                                                        onClick={() => { deleteImagemProducao(item) }}
                                                        variant='outline'>
                                                    </Button>
                                                </Flex>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </SimpleGrid>
                }
            </Box>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>

            <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%' align={'center'} mb={'5px'} >Relatório Esporádico</Text>

            {isShowCamposObrigatorios &&
                <Box px={'5'}>
                    <Box px={'5'} py={'2'} bg={'orange.50'} border={'1px'} borderRadius={'10px'}>
                        <Text ml={'5px'} color={'red.500'} fontSize={'18px'} fontWeight={'bold'} align={'center'}>Verifique alguns campos obrigatórios</Text>
                        {isValidPendenciasAcoesDeCampo && <Text color={'red.500'} fontSize='15px' fontWeight={'bold'}>Assunto</Text>}
                        {isValidObservacoesDiversasComentario && <Text color={'red.500'} fontSize='15px' fontWeight={'bold'}>Comentário</Text>}
                        {isValidIndicadoresMensais && <Text color={'red.500'} fontSize='15px' fontWeight={'bold'}>Indicadores Mensais</Text>}
                    </Box>
                </Box>
            }

            <Accordion allowMultiple mt={'15px'} borderColor={'transparent'}>
                {objOpcoesPersonalizado.opcaoEsporadico1 &&
                    <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapseAssunto : handleExpandAssunto}  >
                                    <Box as="span" flex='1' textAlign='left'>
                                        <Text ml={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'}>1 - Pendências e Ações de Campo</Text>
                                    </Box>
                                    <AccordionIcon boxSize={'10'} />
                                </AccordionButton>
                                <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                    {renderCardAssunto()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                }
                {objOpcoesPersonalizado.opcaoEsporadico2 &&
                    <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapseObservacoesDiversas : handleExpandObservacoesDiversas}  >
                                    <Box as="span" flex='1' textAlign='left'>
                                        <Text ml={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'}>2 - Observações diversas e Comentários</Text>
                                    </Box>
                                    <AccordionIcon boxSize={'10'} />
                                </AccordionButton>
                                <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                    {renderObservacoesDiversasComentarios()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                }
                {objOpcoesPersonalizado.opcaoEsporadico3 &&
                    <AccordionItem bg={boxBg} mt={'5'} borderWidth={'0px'} boxShadow={'xl'} borderRadius={'10px'} isDisabled={!objPermissoes.gerente}>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton _focus={{ boxShadow: 'none' }} onClick={isExpanded ? handleCollapseProd : handleExpandProd}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        <Text ml={'5px'} color={textColor} fontSize={'18px'} fontWeight={'bold'}>3 - Indicadores Mensais</Text>
                                    </Box>
                                    <AccordionIcon boxSize={'10'} />
                                </AccordionButton>
                                <AccordionPanel justifyContent={'center'} alignItems={'center'} p={'0px'}>
                                    {renderIndicadoresMensais()}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                }
            </Accordion>

            <Wrap spacing='30px' mx={'30px'} my={'30px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button variant="brand" onClick={gerarRelatorio}>Próximo</Button>
            </Wrap>

        </Box>
    );
}
