import moment from 'moment';

export function mascaraCpf (cpf) {
    var cpfComMascara = '';        
    try { 
        cpfComMascara = cpf.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    } catch (error) {
        console.log("Error na classe Masks / mascaraCpf");
    }
    return cpfComMascara;                
}


export function mascaraCep(cep) {
    var cepComMascara = '';
    try {
        cepComMascara = cep.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero            
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1') // captura 3 numeros seguidos de um traço e não deixa ser digitado mais nada

    } catch (error) {
        console.log("Error na classe Masks / mascaraCep");
    }
    return cepComMascara;
}


export function retiraMascaraMaterNumeros(string) {
    var somenteNumeros = '';
    try {
        somenteNumeros = string.replace(/[^\d]+/g, '');
    } catch (error) {
        console.log("Error na classe Masks / retiraMascaraMaterNumeros");
    }
    return somenteNumeros;
}


export function mascaraTelefone(fone) {
    var foneComMascara = '';
    try {
        var x = fone.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        foneComMascara = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    } catch (error) {
        console.log("Error na classe Masks / mascaraTelefone");
    }
    return foneComMascara;
}

// result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
export async function mascaraValor(val) {
    var result = '';
    try {
        result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        // result = val.replace(/\D/g, ""); // Remove todos os nao digitos
        // result = result.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
        // result = result.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada tres digitos

    } catch (error) {
        console.log("Error na classe Masks metodo mascaraValor");
    }
    return result;
}


export async function primeiroNomeUsuarioLogadoAsyncStorage(nomeUsuario) {
    const xxx = `${nomeUsuario} `;
    let result = '';
    try {
        const quantidadeCaracteresNomeUsuario = 12;
        let verificaEspacoNome = '';
        var nomeLoop = '';
        var resultadoNomeUsuario = '';

        for (let i = 0; i <= xxx.length; i++) {

            verificaEspacoNome = xxx.substring(i, i + 1);

            if (i < quantidadeCaracteresNomeUsuario) {
                if (verificaEspacoNome === ' ') {
                    nomeLoop = xxx.substring(0, i);
                    break;
                }
            } else {
                nomeLoop = xxx.substring(0, i) + '...';
                break;
            }
        }
        let primeiraLetra = nomeLoop.substring(0, 1);
        primeiraLetra = primeiraLetra.toUpperCase();
        const aposPrimeiraLetra = nomeLoop.substring(1, nomeLoop.length);

        resultadoNomeUsuario = primeiraLetra + aposPrimeiraLetra.toLowerCase();
        result = resultadoNomeUsuario;
        localStorage.setItem('@HE-primeiroNomeUsuarioLogado', resultadoNomeUsuario);                
    } catch (error) {
        console.log("Error na classe Masks metodo primeiroNomeUsuarioLogadoAsyncStorage", error);
    }
    return result;
}


export async function retornaNomeSemanaAndMes(dataFormatoString) {
    let result = '';
    let dt = '';
    try {
        dt = moment(dataFormatoString).format('DD/MM/YYYY');
        const mes = moment(dataFormatoString).format('YYYY-MM-DD');
        const mesCorrente = moment(mes).format('ddd')
        switch (mesCorrente) {
            case 'Sun':
                result = 'Domingo';
                break;
            case 'Mon':
                result = 'Segunda';
                break;
            case 'Tue':
                result = 'Terca';
                break;
            case 'Wed':
                result = 'Quarta';
                break;
            case 'Thu':
                result = 'Quinta';
                break;
            case 'Fri':
                result = 'Sexta';
                break;
            case 'Sat':
                result = 'Sabado';
                break;
            default:
                break;
        }
    } catch (error) {
        console.log('Erro na classe Masks metodo retornaNomeSemanaAbreviado', error);
    }
    result = `${result} - ${dt}`;
    return result;
}


export async function retornaNomeDoMes(dataCorrente) {
    let result = '';
    try {
        const mes = moment(dataCorrente).format('YYYY-MM-DD');
        const mesCorrente = moment(mes).format('MMMM')
        switch (mesCorrente) {
            case 'January':
                result = 'Janeiro';
                break;
            case 'February':
                result = 'Fevereiro';
                break;
            case 'March':
                result = 'Março';
                break;
            case 'April':
                result = 'Abril';
                break;
            case 'May':
                result = 'Maio';
                break;
            case 'June':
                result = 'Junho';
                break;
            case 'July':
                result = 'Julho';
                break;
            case 'August':
                result = 'Agosto';
                break;
            case 'September':
                result = 'Setembro';
                break;
            case 'October':
                result = 'Outubro';
                break;
            case 'November':
                result = 'Novembro';
                break;
            case 'December':
                result = 'Dezembro';
                break;
            default:
                break;
        }

    } catch (error) {
        console.log('Erro na classe FuncoesUteis metodo retornaNomeDoMes', error);
    }
    return result;
}

export async function RETORNA_NOME_SEMANA(dataFormatoString) {
    let result = '';
    try {
        const mes = moment(dataFormatoString).format('YYYY-MM-DD');
        const mesCorrente = moment(mes).format('ddd')
        switch (mesCorrente) {
            case 'Sun':
                result = 'DOM';
                break;
            case 'Mon':
                result = 'SEG';
                break;
            case 'Tue':
                result = 'TER';
                break;
            case 'Wed':
                result = 'QUA';
                break;
            case 'Thu':
                result = 'QUI';
                break;
            case 'Fri':
                result = 'SEX';
                break;
            case 'Sat':
                result = 'SAB';
                break;
            default:
                break;
        }
    } catch (error) {
        console.log('Erro na classe Masks metodo retornaNomeSemanaAbreviado', error);
    }
    return result;
}
