import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    useColorModeValue,
    Button,
    Box,
    Select,
    useDisclosure,
    Collapse,
    SimpleGrid,
    Center,
    TableContainer,
    Table,
    Tbody,
    Thead,
    Tr,
    Th,
    Td,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../constants/ActionTypes';
import ModalDialogDeleteUa from 'components/modal/ModalDialogDeleteUa';
import Loader from 'components/loading/Loader';
import MessageSreen from 'components/message/MessageSreen';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import TooltipIcon from 'components/tooltip/TooltipIcon';
import moment from 'moment';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';

export default function DeleteForm() {

    const { isOpen, onToggle } = useDisclosure()
    const [loading, setLoading] = useState(false);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [openModalDialogDeletarUa, setOpenModalDialogDeletarUa] = useState(false);
    const [idTipoUsuarioLogado, setIdTipoUsuarioLogado] = useState(null);
    const [listUa, setListUa] = useState([]);
    const [listUaCompleto, setListUaCompleto] = useState([]);
    const [uaSelecionado, setUaSelecionado] = useState('');
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [safraSelecionado, setSafraSelecionado] = useState('');
    const [listaSafraCombo, setListaSafraCombo] = useState([]);

    const [dtInicioSafra, setDtInicioSafra] = useState('');
    const [dtFimSafra, setDtFimSafra] = useState('');
    const [dtInicioSangria, setDtInicioSangria] = useState('');
    const [dtFimSangria, setDtFimSangria] = useState('');
    const [nomeSafraP, setnomeSafraP] = useState('');
    const [safraVigenteP, setSafraVigenteP] = useState(null);
    const [listaPessoaBasic, setListaPessoaBasic] = useState([]);
    const [listaUsuarioGerenteBasic, setListaUsuarioGerenteBasic] = useState([]);
    const [sangriaAtivaP, setSangriaAtivaP] = useState(null);
    const [nomeUaSelecionada, setNomeUaSelecionada] = useState('--');
    const [parametrosParaDelete, setParametrosParaDelete] = useState(null);
    const [nomeProdutor, setNomeProdutor] = useState('');
    const [tipoDelete, setTipoDelete] = useState(ActionTypes.TIPO_DELETE_UM_SERINGUEIRO);
    const [disabledComponent, setdisabledComponent] = useState(false);
    const [idUaAtivaNoSistema, setIdUaAtivaNoSistema] = useState(null);
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [habilitaBotton, setHabilitaBotton] = useState(false);
    const [programacaoEmAndamento, setProgramacaoEmAndamento] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const cardShadow = useColorModeValue('0px 0px 5px rgba(0, 0, 0, 0.5)', 'unset');
    const textColorHeader = useColorModeValue("white", "white");
    const colorFooterAndHeader = useColorModeValue("rgba(204, 90, 0, 0.9)", "black");
    const shadowBlack = ('0px 2px 5px 2px rgba(0, 0, 0, 0.30)');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            const idUaAtivaNoSistemaX = localStorage.getItem('@HE-idUa');
            setIdUaAtivaNoSistema(idUaAtivaNoSistemaX);
            await pesquisarPropriedades();
        }
        loaderScreen();
    }, [])

    const pesquisarPropriedades = async () => {

        setUaSelecionado('');
        const idTipoUserioLogado = localStorage.getItem('@HE-idTipoUsuarioLogado');
        setIdTipoUsuarioLogado(idTipoUserioLogado);

        const idUserLogado = localStorage.getItem('@HE-idUsuarioLogado');
        if(idUserLogado == 15 || idUserLogado == 19) {
            setHabilitaBotton(false);
        }else{
            setHabilitaBotton(true);
        }

        const response = await Api.getRequest(`api/ua/retornaUaAndSafras`);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = await response.respostaRequisicao.data;
            setListUaCompleto(res);

            if (res.length > 0) {
                let arrayCombo = null;
                await new Promise(resolve => {
                    resolve(
                        arrayCombo = res.map(function (item) {
                            return {
                                value: `${item.idUa}`,
                                label: item.nomeFazenda
                            }
                        })
                    )
                });
                setListUa(arrayCombo);
            }
            setTelaCarregada(true);
        } else {
            setTelaCarregada(true);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const changePropriedade = async (e) => {
        const idUa = e;

        await limpaCampos();
        setUaSelecionado(idUa);
        setListaSafraCombo([]);

        if (idUa != '') {
            const xx = listUa.filter(x => x.value == idUa);
            const nomeUa = xx.length > 0 ? xx[0].label : '';
            setNomeUaSelecionada(nomeUa);
            const arrUa = listUaCompleto.filter(x => x.idUa == idUa);
            const listaSafraItem = arrUa[0].listaSafraItem;
            const nomeUser = arrUa[0].nomeUsuario;
            setNomeProdutor(nomeUser);

            if (listaSafraItem.length > 0) {
                let arrayCombo = null;
                await new Promise(resolve => {
                    resolve(
                        arrayCombo = listaSafraItem.map(function (item) {
                            return {
                                value: `${item.idSafra}`,
                                label: item.nomeSafra
                            }
                        })
                    )
                });
                setListaSafraCombo(arrayCombo);
            }
        }
    }

    const changeSafra = async (e) => {
        try {
            const idSafra = e;
            setSafraSelecionado(idSafra);

            if (idSafra != '') {
                const url = `safra/retornaSafraAndSeringueirosAndGerentes?idTipoUsuario=${idTipoUsuarioLogado}&idUa=${uaSelecionado}&idSafra=${idSafra}&deletado=false`;

                const response = await Api.getRequest(url);
                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const objSafra = await response.respostaRequisicao.data;

                    const nomeSafr = objSafra.nomeSafra;
                    const vigenteX = objSafra.vigente != null ? objSafra.vigente : false;
                    const dtInicioSafraX = objSafra.dtInicio !== null && objSafra.dtInicio !== '' ? moment(objSafra.dtInicio).format('DD/MM/YYYY') : '';
                    const dtFimSafraX = objSafra.dtFim !== null && objSafra.dtFim !== '' ? moment(objSafra.dtFim).format('DD/MM/YYYY') : '';
                    const dtInicioSangriaX = objSafra.dtInicioSangria !== null && objSafra.dtInicioSangria !== '' ? moment(objSafra.dtInicioSangria).format('DD/MM/YYYY') : '';
                    const dtFimSangriaX = objSafra.dtFimSangria !== null && objSafra.dtFimSangria !== '' ? moment(objSafra.dtFimSangria).format('DD/MM/YYYY') : '';
                    const sangriaAtiv = objSafra.sangriaAtiva != null ? objSafra.sangriaAtiva : false;
                    const programacaoEmAndamentoX = objSafra.programacaoEmAndamento != null ? objSafra.programacaoEmAndamento : false;

                    if (vigenteX == false || sangriaAtiv == false) {
                        setdisabledComponent(true);
                    } else {
                        setdisabledComponent(false);
                    }
                    setnomeSafraP(nomeSafr);
                    setSafraVigenteP(vigenteX);
                    setDtInicioSafra(dtInicioSafraX);
                    setDtFimSafra(dtFimSafraX);
                    setDtInicioSangria(dtInicioSangriaX);
                    setDtFimSangria(dtFimSangriaX);
                    setSangriaAtivaP(sangriaAtiv);
                    setListaPessoaBasic(objSafra.listaPessoaBasic);
                    setListaUsuarioGerenteBasic(objSafra.listaUsuarioGerenteBasic);
                    setProgramacaoEmAndamento(programacaoEmAndamentoX);
                }
            } else {
                limpaCampos();
            }

        } catch (error) {
            console.log('Erro na classe DeleteForm metodo changeSafra', error);
        }
    }

    const limpaCampos = async () => {
        setListaPessoaBasic([]);
        setListaSafraCombo([]);
        setUaSelecionado('');
        setNomeUaSelecionada('');
        setnomeSafraP('');
        setSafraVigenteP(null);
        setDtInicioSafra('');
        setDtFimSafra('');
        setDtInicioSangria('');
        setDtFimSangria('');
        setSangriaAtivaP(null);
        setSafraSelecionado('');
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    async function deletarPropriedade() {
        const isVal = await uaSelecionadaNaoPodeSerMesmaCarregadaNoSistema();
        if(!isVal) return;

        const jsonDelete = {
            idUa: uaSelecionado,
            idSafra: null,
            listPessoaSimple: [],
            tipoDelete: ActionTypes.TIPO_DELETE_UMA_PROPRIEDADE,
        }
        setParametrosParaDelete(jsonDelete);
        setTipoDelete(ActionTypes.TIPO_DELETE_UMA_PROPRIEDADE);
        setOpenModalDialogDeletarUa(true);
    }

    async function deletarSafra() {
        const isVal = await uaSelecionadaNaoPodeSerMesmaCarregadaNoSistema();
        if(!isVal) return;

        const jsonDelete = {
            idUa: uaSelecionado,
            idSafra: safraSelecionado,
            listPessoaSimple: [],
            tipoDelete: ActionTypes.TIPO_DELETE_UMA_SAFRA,
        }
        setParametrosParaDelete(jsonDelete);
        setTipoDelete(ActionTypes.TIPO_DELETE_UMA_SAFRA);
        setOpenModalDialogDeletarUa(true);
    }

    async function deletarSeringueiro(item) {
        const isVal = await uaSelecionadaNaoPodeSerMesmaCarregadaNoSistema();
        if(!isVal) return;

        const arrayJson = [{
            idUa: item.idUa,
            idSafra: item.idSafra,
            idPessoa: item.idPessoa,
            idSafraPessoa: item.idSafraPessoa,
        }]
        const jsonDelete = {
            idUa: uaSelecionado,
            idSafra: safraSelecionado,
            listPessoaSimple: arrayJson,
            tipoDelete: ActionTypes.TIPO_DELETE_UM_SERINGUEIRO,
        }
        setParametrosParaDelete(jsonDelete);
        setTipoDelete(ActionTypes.TIPO_DELETE_UM_SERINGUEIRO);
        setOpenModalDialogDeletarUa(true);
    }

    async function submitDeletar() {
        try {
            setOpenModalDialogDeletarUa(false);
            setLoading(true);

            const response = await Api.deleteRequest('delete/deletarRegistros', parametrosParaDelete);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();

                await limpaCampos();
                await pesquisarPropriedades();

                // if (tipoDelete == ActionTypes.TIPO_DELETE_UM_SERINGUEIRO) {
                //     changeSafra(safraSelecionado);
                // } else {
                //     pesquisarPropriedades();
                // }




            } else {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setLoading(false);
            console.log('Erro na classe DeleteForm metodo submitDeletar', error);
        }
    }

    const uaSelecionadaNaoPodeSerMesmaCarregadaNoSistema = async () => {
        const result = idUaAtivaNoSistema == uaSelecionado ? false : true;
        if(!result){
            setOpenModalConfimacao(true);
        }
        return result;
    }

    function renderDetalhes() {
        const fontP = '15px';
        return (
            <Flex mx={'10px'} mt={'10px'} flexDirection={'column'} borderWidth={'2px'} borderColor={'blackAlpha.600'} borderRadius={'10px'}>
                <Flex px={'10px'} justify='space-between' align='center' bg={'blackAlpha.100'}>
                    <Text color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Detalhes dos itens que serão apagados'}</Text>
                    <Button
                        size="sm"
                        color={'blue.800'}
                        variant="solid"
                        h={'30px'}
                        bg={'transparent'}
                        leftIcon={isOpen ? <FaChevronUp /> : <FaChevronDown />}
                        onClick={onToggle} />
                </Flex>
                <Collapse in={isOpen} animateOpacity>
                    <Flex px={'10px'} mt={'10px'} flexDirection={'column'} justify='center' align='start'>
                        <Text color={'orange.600'} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%'>{'Deletar Seringueiros'}</Text>
                        <Flex px={'20px'} mt={'10px'} flexDirection={'column'} justify='center' align='start'>
                            <Text color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Programação:'}</Text>
                            <Flex justify='center' align='center'>
                                <Text ml={'20px'} color={textColor} fontSize={fontP} fontWeight={'light'} lineHeight='100%'>{'Tarefas Atrasadas, tarefas fora do prazo, não realizadas'}</Text>
                            </Flex>
                            <Text mt={'10px'} color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Cadastro na safra:'}</Text>
                            <Flex justify='center' align='center'>
                                <Text ml={'20px'} color={textColor} fontSize={fontP} fontWeight={'light'} lineHeight='100%'>{'Frequência, Tamanho Lote, Produção Lote, Clone, Tipo de Sangria, Idade Sangria, Produção Esperada, Quantidade Árvores, dias uteis da programação'}</Text>
                            </Flex>
                            <Text mt={'10px'} color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Qualidade Sangria:'}</Text>
                            <Flex justify='center' align='center'>
                                <Text ml={'20px'} color={textColor} fontSize={fontP} fontWeight={'light'} lineHeight='100%'>{'Profundidade baixo , profundidade médio , profundidade alto , ferimento desviado , ferimento não desviado , ataque geratrizes , declividade corte , conformidade equipamentos , impurezas coagulo , faca não amolada , arvore sangrada , controle fitossanitário , látex perda produção , arvore checada , valor ferimento desviado , valor ferimento não desviado , casca , sentido , frequência , Assunto , imagens e vídeos'}</Text>
                            </Flex>
                            <Text mt={'10px'} color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Lançamentos em produção:'}</Text>
                            <Flex justify='center' align='center'>
                                <Text ml={'20px'} color={textColor} fontSize={fontP} fontWeight={'light'} lineHeight='100%'>{'Por frequência ou valor total'}</Text>
                            </Flex>
                            <Text mt={'10px'} color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Histórico seringueiro'}</Text>
                            <Flex justify='center' align='center'>
                                <Text ml={'20px'} color={textColor} fontSize={fontP} fontWeight={'light'} lineHeight='100%'>{'Valores do histórico, arquivos anexados '}</Text>
                            </Flex>
                            <Text mt={'10px'} color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Calendário estimulação'}</Text>
                            <Text mt={'10px'} color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Caso tenha acessos a propriedades serão removidas'}</Text>
                        </Flex>
                        <Text color={'orange.600'} mt={'20px'} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%'>{'Deletar Propriedade e Todos os Seringueiros'}</Text>
                        <Text ml={'20px'} color={textColor} fontSize={fontP} fontWeight={'light'} lineHeight='100%'>{'Executa todos os itens e os demais itens abaixo'}</Text>
                        <Flex px={'20px'} py={'10px'} flexDirection={'column'} justify='center' align='start'>
                            <Text color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Sazonalidade'}</Text>
                            <Text color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Safra'}</Text>
                            <Text color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'Propriedade'}</Text>
                        </Flex>
                        <Text my={'10px'} color={'green.500'} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{'O seringueiro continuará cadastrado e disponível no sistema'}</Text>
                    </Flex>
                </Collapse>
            </Flex>
        )
    }

    function renderRarioGrupo() {
        const isDisabledSangria = listaSafraCombo.length > 0 ? false : true;
        const opacityText = isDisabledSangria ? '0.5' : '1';
        return (
            <Box
                mx={'10px'}
                px={'20px'}
                py={'10px'}
                borderRadius={'20px'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.900'}
                bg={'white'}
                boxShadow={cardShadow}>
                <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Exclusão de Dados</Text>
                <Text color={'gray.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%' align={'center'}>{'Selecione uma propriedade e uma safra para carregar os dados'}</Text>
                <Text color={'gray.500'} fontSize='15px' fontWeight={'light'} lineHeight='100%' align={'center'}>{'Antes de excluir algum registro cetifique quan não tem outro usuário utilizando'}</Text>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }} gap='10px' mt={'10px'}>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box  >
                            <Text ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Propriedades</Text>
                            <Select
                                value={uaSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                onChange={(e) => changePropriedade(e.target.value)}
                                h={'35px'}
                                w={'350px'}
                            >
                                {listUa.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                    <Flex px='5px' mb='2px' flexDirection={'column'} justify='center' align='center'>
                        <Box>
                            <Text opacity={opacityText} ml={'10px'} color={textColor} fontSize='15px' fontWeight='700' lineHeight='100%' align={'start'}>Safras</Text>
                            <Select
                                value={safraSelecionado}
                                errorBorderColor='crimson'
                                placeholder="Selecione"
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                isDisabled={isDisabledSangria}
                                onChange={(e) => changeSafra(e.target.value)}
                                h={'35px'}
                                w={'350px'}
                            >
                                {listaSafraCombo.map((item, index) => {
                                    return (
                                        <option key={index} style={{ background: 'white', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                    </Flex>
                </SimpleGrid>
            </Box>
        )
    }

    function renderPropriedade() {
        const fontP = '13px';
        const colorTextHeader = 'white';
        const espacoW = 2;
        return (
            <Box
                mt={'0px'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.600'}
                borderRadius={'20px'}
                boxShadow={shadowBlack}>
                <Card px='10px' py='10px'>
                    <Flex justify={'center'} align={'center'}>
                        <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Propriedade</Text>
                        <Center py={'0px'} justifyContent={'center'} alignItems={'center'} ml={'5px'}>
                            <TooltipIcon
                                label={
                                    <Box py={'5px'} pb={'10px'}>
                                        <Text align={'center'} lineHeight={'100%'}>{'Propriedades'}</Text>
                                        <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'Esta ação exclui todos as safras e seringueiros da propriedade.'}</Text>
                                        <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'As atividades dos seringueiros em outras propriedades não serão afetadas.'}</Text>
                                    </Box>
                                }
                                height={'18px'} />
                        </Center>
                    </Flex>
                    <Flex direction="column" align="center" mt={'10px'}>
                        <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'}>
                            <Table variant="striped" colorScheme='blackAlpha' size="sm" >
                                <Thead>
                                    <Tr h={'35px'} bgGradient={gradientHeader}>
                                        <Th w={'33%'} pl={'20px'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='start' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{'Nome'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'33%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Nome Produtor'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'33%'} pr={'20px'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='start' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{''}</Text>
                                            </Flex>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {uaSelecionado != '' &&
                                        <Tr>
                                            <Td pl={'20px'} sx={{ paddingX: espacoW }}>
                                                <Flex justify='start' align='center'>
                                                    {/* <Text color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{`${nomeUaSelecionada} - ${uaSelecionado}`}</Text> */}
                                                    <Text color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{nomeUaSelecionada}</Text>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex justify='center' align='center'>
                                                    <Text color={textColor} fontSize={fontP} fontWeight={'bold'} lineHeight='100%'>{nomeProdutor}</Text>
                                                </Flex>
                                            </Td>
                                            <Td pr={'20px'} sx={{ paddingX: espacoW }}>
                                                <Flex justify='end' align='center'>
                                                    <Button
                                                        //isDisabled={uaSelecionado != '' ? false : true}
                                                        isDisabled={habilitaBotton}
                                                        variant="redBrand"
                                                        onClick={deletarPropriedade}>Excluir</Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                </Card>
            </Box>
        )
    }

    function renderListaSafra() {
        const colorTextHeader = 'white';
        const espacoW = 2;
        const SHADOW_GREEN = '1px 1px #00b300';
        const SHADOW_RED = '1px 1px #ff0000';
        const SHADOW_YELLOW = '1px 1px #ffff00';

        let descStatus = '';
        let descProgramacao = '';
        let shadowColorProgramacao = '';
        let shadowStatus = '';

        if (sangriaAtivaP == true) {
            if (safraVigenteP == true) {
                descStatus = 'Vigente';
                shadowStatus = SHADOW_GREEN;
            } else {
                descStatus = 'Finalizado';
                shadowStatus = SHADOW_RED;
            }
            if (programacaoEmAndamento == true) {
                descProgramacao = 'Com Programação';
                shadowColorProgramacao = SHADOW_GREEN;
            } else {
                descProgramacao = 'Sem Programação';
                shadowColorProgramacao = SHADOW_YELLOW;
            }
        } else {
            descStatus = 'Finalizado';
            shadowStatus = SHADOW_RED;
            descProgramacao = 'Sem Programação';
            shadowColorProgramacao = SHADOW_YELLOW;
        }
        return (
            <Box borderWidth={'2px'} borderColor={'blackAlpha.600'} borderRadius={'20px'} boxShadow={shadowBlack} mt={'20px'}>
                <Card px='10px' py='10px'>
                    <Flex justify={'center'} align={'center'}>
                        <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Safra</Text>
                        <Center py={'0px'} justifyContent={'center'} alignItems={'center'} ml={'5px'}>
                            <TooltipIcon
                                label={
                                    <Box py={'5px'} pb={'10px'}>
                                        <Text align={'center'} lineHeight={'100%'}>{'Safras'}</Text>
                                        <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'Com a exclusão da safra as atividades de todos os seringueiros também serão excluídas.'}</Text>
                                        <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'As atividades de outras safras não serão afetadas.'}</Text>
                                    </Box>
                                }
                                height={'18px'} />
                        </Center>
                    </Flex>
                    <Flex direction="column" align="center" mt={'10px'}>
                        <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'}>
                            <Table variant="striped" colorScheme='blackAlpha' size="sm" >
                                <Thead>
                                    <Tr h={'35px'} bgGradient={gradientHeader}>
                                        <Th w={'16%'} pl={'20px'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='start' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{'Nome'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Datas Safra'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Datas Sangria'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Status'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center' textTransform={'none'}>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{'Programação'}</Text>
                                            </Flex>
                                        </Th>
                                        <Th w={'16%'} sx={{ paddingX: espacoW }}>
                                            <Flex justify='center' align='center'>
                                                <Text color={colorTextHeader} fontSize='14px' fontWeight={'bold'} lineHeight='100%'>{''}</Text>
                                            </Flex>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {safraSelecionado != '' &&
                                        <Tr>
                                            <Td pl={'20px'} sx={{ paddingX: espacoW }}>
                                                <Flex justify='start' align='center'>
                                                    {/* <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`${nomeSafraP} - ${safraSelecionado}`}</Text> */}
                                                    <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{nomeSafraP}</Text>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex flexDirection={'column'} justify='center' align='center'>
                                                    <Flex justify='start' align='center'>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Início:`}</Text>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtInicioSafra}</Text>
                                                    </Flex>
                                                    <Flex mt={'5px'} justify='center' align='center'>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Fim:`}</Text>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtFimSafra}</Text>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex flexDirection={'column'} justify='center' align='center'>
                                                    <Flex justify='start' align='center'>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Início:`}</Text>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtInicioSangria}</Text>
                                                    </Flex>
                                                    <Flex mt={'5px'} justify='start' align='center'>
                                                        <Text color={'black'} fontSize={'13px'} fontWeight={'bold'} lineHeight='100%'>{`Fim:`}</Text>
                                                        <Text color={textColor} fontSize={'12px'} fontWeight={'bold'} lineHeight='100%' ml={'10px'}>{dtFimSangria}</Text>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex justify='center' align='center'>
                                                    <Text textShadow={shadowStatus} color={'blackAlpha.600'} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{descStatus}</Text>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex justify='center' align='center'>
                                                    <Text textShadow={shadowColorProgramacao} color={'blackAlpha.600'} fontSize={'14px'} fontWeight={'bold'} lineHeight='100%'>{descProgramacao}</Text>
                                                </Flex>
                                            </Td>
                                            <Td sx={{ paddingX: espacoW }}>
                                                <Flex pr={'10px'} justify='end' align='center'>
                                                    <Button isDisabled={habilitaBotton} variant="redBrand" onClick={deletarSafra}>Excluir</Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                </Card>
            </Box>
        )
    }

    function renderTableSeringueiros() {
        const sizeF = '15px';
        let corLinha = false;
        return (
            <Box
                mt={'20px'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.600'}
                borderRadius={'20px'}
                boxShadow={shadowBlack}>
                <Card px='10px' py='10px'>
                    <Flex justify={'center'} align={'center'}>
                        <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Seringueiros</Text>
                        <Center py={'0px'} justifyContent={'center'} alignItems={'center'} ml={'5px'}>
                            <TooltipIcon
                                label={
                                    <Box py={'5px'} pb={'10px'}>
                                        <Text align={'center'} lineHeight={'100%'}>{'Seringueiros'}</Text>
                                        <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'As atividades do seringueiro serão excluídos para essa safra.'}</Text>
                                        <Text align={'start'} mt={'10px'} lineHeight={'100%'}>{'Após esse processo o seringueiro estará disponível no sistema podendo ser incluídos em nova safra.'}</Text>
                                    </Box>
                                }
                                height={'18px'} />
                        </Center>
                    </Flex>
                    <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'} mt={'10px'}>
                        <Table size='sm' variant='unstyled'>
                            <Thead bg={colorFooterAndHeader}>
                                <Tr bgGradient={gradientHeader} h={'35px'}>
                                    <Th w={'80%'}>
                                        <Flex justify={'start'} align={'center'}>
                                            <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} align={'center'} textTransform={'none'}>{'Nome'}</Text>
                                        </Flex>
                                    </Th>
                                    <Th w={'20%'}>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} textTransform={'none'}>{''}</Text>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {listaPessoaBasic.map((item, key) => {
                                    corLinha = !corLinha;
                                    const deletado = item.deletado == true ? true : false;
                                    let colorIndicator = 'transparent';
                                    if (deletado) {
                                        colorIndicator = 'red';
                                    } else {
                                        colorIndicator = item.isExisteProgramacao == true ? 'green' : 'yellow';
                                    }
                                    return (
                                        <Tr key={key + 1} alignItems={'center'} bg={corLinha ? 'rgba(217, 217, 217, 0.5)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} >
                                            <Td>
                                                <Flex justify='start' align='center'>
                                                    <Box w={'14px'} h={'14px'} borderRadius={'6px'} bg={colorIndicator} />
                                                    {/* <Text ml={'5px'} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{`${item.nomePessoa} - ${item.idPessoa}`}</Text> */}
                                                    <Text ml={'5px'} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{item.nomePessoa}</Text>
                                                </Flex>
                                            </Td>
                                            <Td justifyContent={'center'} alignItems={'center'}>
                                                <Flex justify='end' align='center'>
                                                    <Button isDisabled={habilitaBotton} variant="redBrand" onClick={() => { deletarSeringueiro(item) }}>Excluir</Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Card>
            </Box>
        )
    }

    // NAO APAGARRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR
    function renderTableGerentes() {
        const sizeF = '15px';
        let corLinha = false;
        return (
            <Box
                mt={'20px'}
                borderWidth={'2px'}
                borderColor={'blackAlpha.600'}
                borderRadius={'20px'}
                boxShadow={shadowBlack}>
                <Card px='10px' py='10px'>
                    <Text color={'black'} fontSize='20px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Gerentes</Text>
                    <TableContainer w={'100%'} borderRadius={'10px'} borderWidth={'1px'} borderColor={'blackAlpha.700'} mt={'10px'}>
                        <Table size='sm' variant='unstyled'>
                            <Thead bg={colorFooterAndHeader}>
                                <Tr bgGradient={gradientHeader} h={'35px'}>
                                    <Th w={'80%'}>
                                        <Flex justify={'start'} align={'center'}>
                                            <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} align={'start'} textTransform={'none'}>{'Gerentes Cadastrados'}</Text>
                                        </Flex>
                                    </Th>
                                    <Th w={'20%'}>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} textTransform={'none'}>{''}</Text>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {listaUsuarioGerenteBasic.map((item, key) => {
                                    corLinha = !corLinha;
                                    return (
                                        <Tr key={key + 1} alignItems={'center'} bg={corLinha ? 'rgba(217, 217, 217, 0.5)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} >
                                            <Td>
                                                <Flex justify='start' align='center'>
                                                    <Text ml={'5px'} color={textColor} fontSize={sizeF} fontWeight={'bold'} align={'start'} lineHeight={'100%'}>{item.nomeUsuario}</Text>
                                                </Flex>
                                            </Td>
                                            <Td justifyContent={'center'} alignItems={'center'}>
                                                <Flex justify='end' align='center'>
                                                    <Button isDisabled={habilitaBotton} variant="redBrand" onClick={() => { deletarSeringueiro(item) }}>Excluir</Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Card>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Box mt={'20px'}>
                <Text color={textColor} fontSize='15px' fontWeight={'bold'} lineHeight='100%' align={'center'}>{'Em desenvolvimento'}</Text>
            </Box>
        )
    }

    return (
        <Card px='0px' py='0px' bg={'transparent'}>
            <Box pt={{ base: "80px", md: "80px", xl: "60px" }}>
                {isVisibleAlert && renderAlert()}
                {renderRarioGrupo()}
                <Box borderWidth={'0px'} borderColor={'blackAlpha.200'} borderRadius={'20px'} mt={'20px'} px={'10px'} py={'10px'}>
                    {/* {renderDetalhes()} */}
                    {renderPropriedade()}
                    {renderListaSafra()}
                    {renderTableSeringueiros()}
                </Box>
            </Box>
            {openModalDialogDeletarUa && <ModalDialogDeleteUa isOpen={openModalDialogDeletarUa} onPressOk={() => { submitDeletar() }} onPressCancel={() => { setOpenModalDialogDeletarUa(false) }} tipoDelete={tipoDelete} />}
            <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { setOpenModalConfimacao(false) }} descricao={`A propriedade ${nomeUaSelecionada} não pode se alterada por que é a mesma carregada no sistema, troque a propeiedade no sistema retorne.`}/>
            {loading && <Loader isOpen={loading} />}
        </Card>
    );
};

// 