import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Flex,
    Button,
    Text,
} from "@chakra-ui/react";

function ModalConfirmacao({ isOpen, onPress, titulo = 'Ok!', descricao = 'Operação realizada com Sucesso!' }) {

    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE}>
                <ModalHeader>
                    <Text color={'black'} fontSize={'25px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{titulo}</Text>
                </ModalHeader>
                <ModalBody>
                    <Flex flexDirection={'column'} justify='space-around' align='center'>
                        <Text color={'blackAlpha.700'} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{descricao}</Text>
                    </Flex>
                </ModalBody>
                <ModalFooter mt={'20px'} borderTopWidth={'1px'} borderColor={'blackAlpha.700'} justifyContent={'space-around'}>
                    <Button w={'80px'} variant="miniBrand" onClick={onPress}>Ok</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalConfirmacao;
