import React, { useState, useEffect } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Flex,
    Button,
    Icon,
    Select,
    Input,
    SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../constants/ActionTypes';
import { MdCheckCircle, MdCircle } from "react-icons/md";
import CardValidacaoSafra from 'components/card/CardValidacaoSafra';
import MessageSreen from 'components/message/MessageSreen';
import Loader from 'components/loading/Loader';
import ModalDialog from 'components/modal/ModalDialog';

export default function GerarProgramacaoIndividual() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idUa, setIdUa] = useState('');
    const [idSafra, setIdSafra] = useState('');
    const [idUsuario, setIdUsuario] = useState(null);
    const [MONDAY, setMONDAY] = useState(true);
    const [TUESDAY, setTUESDAY] = useState(true);
    const [WEDNESDAY, setWEDNESDAY] = useState(true);
    const [THURSDAY, setTHURSDAY] = useState(true);
    const [FRIDAY, setFRIDAY] = useState(true);
    const [SATURDAY, setSATURDAY] = useState(true);
    const [SUNDAY, setSUNDAY] = useState(true);
    const [isValidDiasSemana, setIsValidDiasSemana] = useState(false);

    const [listaPessoaCompleto, setListaPessoaCompleto] = useState([]);
    const [listSeringueiro, setListSeringueiro] = useState([]);
    const [seringueiroSelecionado, setSeringueiroSelecionado] = useState(null);
    const [isExisteProgramacao, setIsExisteProgramacao] = useState(null);
    const [nomeFazenda, setNomeFazenda] = useState('');
    const [nomeSafra, setNomeSafra] = useState('');

    const [dtInicio, setDtInicio] = useState('');
    const [dtFim, setDtFim] = useState('');
    const [alternanciaSelecionado, setAlternanciaSelecionado] = useState('3');
    const [listaPessoa, setListaPessoa] = useState([]);
    const [validarSafraDto, setValidarSafraDto] = useState({});
    const [isAbilitarGeracao, setIsAbilitarGeracao] = useState(true);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [programacaoEmAndamento, setProgramacaoEmAndamento] = useState(false);

    const [dtInicioProgramacao, setDtInicioProgramacao] = useState('');
    const [mimInicioSafra, setMimInicioSafra] = useState('');

    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const inputBg = useColorModeValue("white", "navy.700");
    const inputText = useColorModeValue("gray.700", "gray.100");


    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const dados = localStorage.getItem('@HE-datosParaProgramacaoIndividualAndReprogracao');
            const param = JSON.parse(dados);

            const idUsuarioP = param.idUsuario;
            const idSafraP = param.idSafra;
            const idUaP = param.idUa;
            const nomeFazendaP = param.nomeFazenda;
            const dtInicioP = param.dtInicio;
            const dtFimP = param.dtFim;
            const nomeSafraP = param.nomeSafra;
            const programacaoEmAndamentoP = param.programacaoEmAndamento;

            setIdUsuario(idUsuarioP);
            setProgramacaoEmAndamento(programacaoEmAndamentoP);
            setNomeFazenda(nomeFazendaP);
            setNomeSafra(nomeSafraP);
            setIdUa(idUaP);
            setIdSafra(idSafraP);
            setDtInicio(dtInicioP);
            setDtFim(dtFimP);

            await carregarInformacoesTela(idUaP, idSafraP, dtInicioP, dtFimP);
        }
        loaderScreen();
    }, []);

    const carregarInformacoesTela = async (idUaP, idSafraP, dtInicioP, dtFimP) => {

        const url = `api/pessoa/listaSeringueirosParaReprogramacao?idUa=${idUaP}&idSafra=${idSafraP}&dtInicio=${dtInicioP}&dtFim=${dtFimP}&deletado=false`;
        const response = await Api.getRequest(url);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res1 = await response.respostaRequisicao.data;

            const validacao = res1.validarSafraDto;
            setValidarSafraDto(validacao);

            const dtAtual = moment().format('YYYY-MM-DD');
            const dtInitSan = validacao.dtInicioSangria != null ? moment(validacao.dtInicioSangria).format('YYYY-MM-DD') : '';
            setDtInicioProgramacao(dtInitSan);
            setMimInicioSafra(dtInitSan);

            //setNomeMunicipio(validacao.nomeMunicipio);

            if (!validacao.sangriaAtiva
                || !validacao.vigente
                || validacao.dtInicioSafraMaiorQueDataAtual
                || validacao.dtFimSafraMenorQueDataAtual
                || validacao.dtInicioSangriaMaiorQueDataAtual
                || validacao.dtFimSangriaMenorQueDataAtual
                || validacao.dtInicioSangriaEstaNull
                || validacao.qtdPessoaCadastrada == 0
            ) {
                setIsAbilitarGeracao(false);
            }

            const arr = res1.listReprogramacaoItem;
            setListaPessoaCompleto(arr);
            if (arr.length > 0) {
                let arrayPess = [];
                await new Promise(resolve => {
                    resolve(
                        arr.map(function (item) {
                            const optionColor = item.isExisteProgramacao == true ? 'white' : 'yellow';
                            const obj = {
                                label: item.nome,
                                value: `${item.idPessoa}`,
                                colorOp: optionColor
                            }
                            arrayPess.push(obj);
                        })
                    )
                });
                setListSeringueiro(arrayPess);

                const idPessoaX = arrayPess[0].value;
                changePessoa(idPessoaX, arr);
            }
            setTelaCarregada(true);
        } else {
            setTelaCarregada(true);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const selecionarDiasSemanaPrimeiroAcesso = async (val) => {
        switch (val) {
            case 'MONDAY':
                setMONDAY(true);
                break;
            case 'TUESDAY':
                setTUESDAY(true);
                break;
            case 'WEDNESDAY':
                setWEDNESDAY(true);
                break;
            case 'THURSDAY':
                setTHURSDAY(true);
                break;
            case 'FRIDAY':
                setFRIDAY(true);
                break;
            case 'SATURDAY':
                setSATURDAY(true);
                break;
            case 'SUNDAY':
                setSUNDAY(true);
                break;
            default:
                break;
        }
    }

    const validarAndGerarProgramacao = async () => {
        try {
            setOpenModalDialog(false);
            let valid = false;

            const arrayDiasSemanaP = [
                { descDiaSemana: "", dayOfWeek: "MONDAY", status: MONDAY },
                { descDiaSemana: "", dayOfWeek: "TUESDAY", status: TUESDAY },
                { descDiaSemana: "", dayOfWeek: "WEDNESDAY", status: WEDNESDAY },
                { descDiaSemana: "", dayOfWeek: "THURSDAY", status: THURSDAY },
                { descDiaSemana: "", dayOfWeek: "FRIDAY", status: FRIDAY },
                { descDiaSemana: "", dayOfWeek: "SATURDAY", status: SATURDAY },
                { descDiaSemana: "", dayOfWeek: "SUNDAY", status: SUNDAY }
            ]

            const filtro = arrayDiasSemanaP.filter(x => x.status == true);
            const qtdProgramaca = filtro.length;

            if (qtdProgramaca < 4) {
                setIsValidDiasSemana(true);
                valid = true;
            } else {
                setIsValidDiasSemana(false);
            }
            if (valid) return;

            const dataAtual = moment();
            const dataAtualFormatada = moment(dtInicioProgramacao).format('YYYY-MM-DD');
            const dtAtualMais06Dias = moment(dataAtual).add(6, 'days').format('YYYY-MM-DD');     // YYYY-MM-DD HH:mm:ss

            const jsonPessoa = {
                idUsuario: idUsuario,
                idPessoa: seringueiroSelecionado,
                idTipoPessoa: ActionTypes.ID_TIPO_PESSOA_SANGRADOR,
                idUa: idUa,
                idSafra: idSafra,
                qtdTarefas: alternanciaSelecionado,
                qtdProgramacaoSemana: qtdProgramaca,
                dtInicio: `${dataAtualFormatada} 00:00:00`,
                dtInicioSangria: `${dataAtualFormatada} 00:00:00`,
                dtFim: `${dtAtualMais06Dias} 00:00:00`,
                arrayDiasSemana: arrayDiasSemanaP,
                gerarComDadosIndividualDoSeringueiro: true,
                sangriaAtiva: true,
                programacaoEmAndamento: true,
                permitirRodarProgramacao: true,
            }

            setLoading(true);
            const response = await Api.postRequest(`api/programacao/gerarProgramacaoParaUmSeringueiro`, jsonPessoa);

            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                await carregarInformacoesTela(idUa, idSafra, dtInicio, dtFim);
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            } else {
                setLoading(false);
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            setLoading(false);
            console.log('Error na classe validarAndGerarProgramacao metodo changePessoa', error);
        }
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const programacaoIndividual = async () => {
        if (isExisteProgramacao) {
            setOpenModalDialog(true);
        } else {
            validarAndGerarProgramacao();
        }
    }

    const changePessoa = async (val, listaPessoaCompletoParam) => {
        try {
            setOpenModalDialog(false);

            setSeringueiroSelecionado(val);
            setMONDAY(false);
            setTUESDAY(false);
            setWEDNESDAY(false);
            setTHURSDAY(false);
            setFRIDAY(false);
            setSATURDAY(false);
            setSUNDAY(false);

            if (val != '') {
                const arFilter = listaPessoaCompletoParam.filter(x => x.idPessoa == Number(val));
                const obj = arFilter[0];

                obj.listaDiasUteis.map(x => {
                    const desc = x != null && x != '' ? x : '';
                    selecionarDiasSemanaPrimeiroAcesso(`${desc}`);
                })

                const ss = obj.idQuantidadeTarefas != null && obj.idQuantidadeTarefas != '' ? `${obj.idQuantidadeTarefas}` : '3';
                setAlternanciaSelecionado(ss);
                setIsExisteProgramacao(obj.isExisteProgramacao != true ? false : true);
                setListaPessoa(arFilter);
            } else {
                setListaPessoa([]);
            }
        } catch (error) {
            console.log('Error na classe GerarProgramacaoIndividual metodo changePessoa', error);
        }
    }

    function renderTitulo() {
        return (
            <Box>
                <Text color={textColor} fontSize='18px' fontWeight='bold' align={'center'} lineHeight='100%'>{'Programação Individual'}</Text>
                <Flex flexDirection={'column'} w={'100%'} justify={'start'} align="start">
                    <Flex justify={'start'} align="center">
                        <Text color={'gray.700'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Propriedade:'}</Text>
                        <Text color={'gray.700'} fontSize='15px' fontWeight={'light'} lineHeight='100%' ml={'10px'}>{nomeFazenda}</Text>
                    </Flex>
                    <Flex justify={'start'} align="center">
                        <Text color={'gray.700'} fontSize='15px' fontWeight='bold' lineHeight='100%'>{'Safra:'}</Text>
                        <Text color={'gray.700'} fontSize='15px' fontWeight={'light'} lineHeight='100%' ml={'10px'}>{nomeSafra}</Text>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderMensagemDaSafra() {
        return (
            <Flex w={'100%'} justify={'center'} align="center" mt={'20px'} mb={'20px'}>
                <CardValidacaoSafra validarSafra={validarSafraDto} isAbilitarGeracao={isAbilitarGeracao} />
            </Flex>
        )
    }

    function renderSeringueirosCombo() {
        return (
            <Box mt={'10px'}>
                <Flex w={'100%'} justify={'center'} align="start">

                </Flex>
                <Flex flexDirection={'column'} w={'100%'} justify={'center'} align="start">
                    <Box ml={'10px'}>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Seringueiros</Text>
                    </Box>
                    <Select
                        value={seringueiroSelecionado}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={textColor}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        onChange={(e) => changePessoa(e.target.value, listaPessoaCompleto)}
                        w={'350px'}
                        h={'35px'}
                    >
                        {listSeringueiro.map((item, index) => {
                            return (
                                <option key={index} style={{ background: item.colorOp, color: 'black', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                            )
                        })}
                    </Select>
                </Flex>
            </Box>
        )
    }

    function renderDataInicioProgramacao() {
        const dtAtual = moment().format('YYYY-MM-DD');
        return (
            <Box mt={'10px'}>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>

                    <Flex flexDirection={'column'} justify='center' align='start'>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Seringueiros</Text>
                        <Select
                            value={seringueiroSelecionado}
                            errorBorderColor='crimson'
                            variant="filled"
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            onChange={(e) => changePessoa(e.target.value, listaPessoaCompleto)}
                            w={'350px'}
                            h={'35px'}
                        >
                            {listSeringueiro.map((item, index) => {
                                return (
                                    <option key={index} style={{ background: item.colorOp, color: 'black', fontWeight: 'bold' }} value={item.value} >{item.label}</option>
                                )
                            })}
                        </Select>
                    </Flex>
                    <Flex flexDirection={'column'} justify='center' align='start'>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} lineHeight='100%' p={'5px'} align={'start'}>Início Programação</Text>
                        <Input
                            value={dtInicioProgramacao}
                            variant='filled'
                            onChange={(e) => { setDtInicioProgramacao(e.target.value) }}
                            errorBorderColor='crimson'
                            placeholder=""
                            bg={inputBg}
                            color={inputText}
                            borderColor={textColor}
                            borderRadius="10px"
                            size="md"
                            type="date"
                            min={mimInicioSafra}
                            max={dtAtual}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            w={'350px'}
                            h={'35px'}
                        />
                    </Flex>
                </SimpleGrid>
            </Box>
        )
    }

    function renderTemProgramacao() {
        return (
            <Flex mt={'10px'} justify='end' align='center'>
                {seringueiroSelecionado != null ?
                    <Box>
                        {isExisteProgramacao ?
                            <Flex px={'10px'} py={'5px'} justify='end' align='center' bg={'green.400'} borderRadius={'10px'} borderWidth={'1px'}>
                                <Text color={'white'} fontSize='13px' fontWeight='bold' lineHeight='100%'>{'Com Programação'}</Text>
                            </Flex>
                            :
                            <Flex px={'10px'} py={'5px'} justify='end' align='center' bg={'#ffff66'} borderRadius={'10px'} borderWidth={'1px'}>
                                <Text color={'black'} fontSize='13px' fontWeight='bold' lineHeight='100%'>{'Sem Programação'}</Text>
                            </Flex>
                        }
                    </Box>
                    :
                    <Flex px={'10px'} py={'5px'} justify='center' align='center' bg={'green.400'} borderRadius={'10px'} borderWidth={'1px'}>
                        <Text color={'white'} fontSize='13px' fontWeight='bold' lineHeight='100%'>{''}</Text>
                    </Flex>
                }
            </Flex>
        )
    }

    function renderListaSeringueiros() {
        let corLinha = false;
        return (
            <Box mt={'10px'} px={'10px'} borderWidth={'2PX'} borderRadius={'10px'} borderColor={'blackAlpha.700'}>
                <Flex w={'100%'} justify='space-between' align='center'>
                    <Box w={'42%'}>
                        <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{''}</Text>
                    </Box>
                    <Box w={'50px'}>
                        <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{''}</Text>
                    </Box>
                    <Box w={'52%'}>
                        <Flex justify='space-between' align='center'>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'S'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'T'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'Q'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'Q'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'S'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'S'}</Text>
                            </Box>
                            <Box w={'100%'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold'>{'D'}</Text>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
                {listaPessoa.map((item, index) => {
                    const bgName = item.isExisteProgramacao != true ? '#ffff4d' : 'transparent';
                    corLinha = !corLinha;
                    return (
                        <Flex key={index} w={'100%'} justify='space-between' align='center' borderBottomWidth={'1px'} borderColor={'blackAlpha.400'} bg={'blackAlpha.50'}>
                            <Box py={'5px'} w={'42%'} bg={bgName} borderRadius={'5px'}>
                                <Text color={textColor} fontSize='14px' fontWeight='bold' lineHeight={'100%'} align={'start'}>{item.nome}</Text>
                            </Box>
                            <Box my={'3px'} w={'50px'}>
                                <Box py={'5px'} w={'35px'} bg={'orange.600'} borderRadius={'5px'}>
                                    <Text color={'white'} fontSize='14px' fontWeight='bold' lineHeight={'100%'}>{`D${item.idQuantidadeTarefas}`}</Text>
                                </Box>
                            </Box>
                            <Box w={'52%'}>
                                <Flex justify='space-between' align='center'>
                                    {item.listaDiasUteis != null && item.listaDiasUteis.length > 0 ?
                                        item.listaDiasUteis.map((x, y) => {
                                            let corIcon = 'green';
                                            let isIcone = false;

                                            if (x == 'MONDAY') {
                                                isIcone = true;
                                            } else if (x == 'TUESDAY') {
                                                isIcone = true;
                                            } else if (x == 'WEDNESDAY') {
                                                isIcone = true;
                                            } else if (x == 'THURSDAY') {
                                                isIcone = true;
                                            } else if (x == 'FRIDAY') {
                                                isIcone = true;
                                            } else if (x == 'SATURDAY') {
                                                isIcone = true;
                                            } else if (x == 'SUNDAY') {
                                                isIcone = true;
                                            } else {
                                                isIcone = false;
                                                corIcon = 'gray.300';
                                            }
                                            return (
                                                <Flex key={y} w={'100%'} justify='center' align='center'>
                                                    <Icon as={isIcone ? MdCheckCircle : MdCircle} width='20px' height='20px' color={corIcon} />
                                                </Flex>
                                            )
                                        })
                                        :
                                        <Text color={textColor} fontSize='14px' fontWeight='bold' >{'S'}</Text>
                                    }
                                </Flex>
                            </Box>
                        </Flex>
                    )
                })}
            </Box>
        )
    }

    function renderButton() {
        return (
            <Box mt={'20px'}>
                <Flex px='10px' justify='space-between' align='center'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                    <Button variant="brand" onClick={() => { programacaoIndividual() }}>Gerar Programação</Button>
                </Flex>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'50px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%'>
                {renderAlert()}
                {renderTitulo()}
                {!isAbilitarGeracao && renderMensagemDaSafra()}

                {/* {renderSeringueirosCombo()} */}

                {renderDataInicioProgramacao()}

                {renderTemProgramacao()}
                {renderListaSeringueiros()}
                {renderButton()}
            </Card>
            {loading && <Loader isOpen={loading} />}
            {openModalDialog && <ModalDialog isOpen={openModalDialog} onPressOk={() => { validarAndGerarProgramacao() }} onPressCancel={() => { setOpenModalDialog(false) }} descricao={'Este seringueiro já tem programação, se continuar será excluida gerada uma nova, Deseja continuar?'} />}
        </Box>
    );
}
